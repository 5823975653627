import React, { Component, PureComponent } from 'react';
import { CartesianGrid, Cell, LabelList, Legend, Pie, PieChart, Tooltip } from 'recharts';
import { ChartColors } from '../../store/Util';

class CustomizedAxisTick extends PureComponent<any> {
	render() {
		const { x, y, stroke, payload } = this.props;

		// .toString().substring(0, 13)
		return (
			<g transform={`translate(${x},${y})`}>
				<text x={0} y={0} dy={16} textAnchor="middle" className="small" fill="#666" transform="rotate(0)">
					{payload.value}
				</text>
			</g>
		);
	}
}

export class RCPieChart extends Component<{ chartInfo?: any }> {
	//tickFormatter = (tickItem: any, index: any): any => {
	//	console.log("tickItem:" + tickItem);
	//	return tickItem; // Limit number of characters .toString().substring(0, 13)
	//}
	//onClick = (payload: any) => {
	//	let x = this.props;
	//}
	//onBarClick = (payload: any, barIndex: number, barInfo: any) => {
	//	let key = this.props.chartInfo.key; // Solicitor, Meeting Type, ...
	//	//let barInfo = this.props.chartInfo.barInfo[stackIndexInBar];
	//	let fieldName = barInfo.fieldName; // proxyCount19, proxyCount20, ...
	//	//let name = barInfo.name; // 2019, 2020, ...
	//	let data = this.props.chartInfo.data[barIndex]; // Data of Computershare, Broadridge, ... or Annual, Special
	//	let value = data[fieldName]; // Number of Proxies of Solicitor Computershare in 2019, ...
	//	//alert(`${key} ${data[key]} ${name}: ${value}`); // solicitor Computershare 2019: 257

	//	if (this.props.chartInfo.onBarClick && typeof this.props.chartInfo.onBarClick === 'function') {
	//		this.props.chartInfo.onBarClick.call(this, barInfo, data, value, key, this);
	//	}

	//}
	onPieClick = (payload: any, e: any, lineInfo: any) => {
		let key = this.props.chartInfo.key; // Solicitor, Meeting Type, ...
		//let lineInfo = this.props.chartInfo.lineInfo[lineIndex];
		let fieldName = lineInfo.fieldName; // proxyCount19, proxyCount20, ...
		//let name = lineInfo.name; // 2019, 2020, ...
		let data = e; // Data of Computershare, Broadridge, ... or Annual, Special
		let value = data[fieldName]; // Number of Proxies of Solicitor Computershare in 2019, ...
		//alert(`${key} ${data[key]} ${name}: ${value}`); // solicitor Computershare 2019: 257

		if (this.props.chartInfo.onPieClick && typeof this.props.chartInfo.onPieClick === 'function') {
			this.props.chartInfo.onPieClick.call(this, lineInfo, data, value, key, this);
		}
	}

	CustomTooltip = (payload: any, data: any) => {
		if (!payload?.active)
			return <></>;
		if (this.props.chartInfo.tooltip && typeof this.props.chartInfo.tooltip === 'function') {
			return this.props.chartInfo.tooltip.call(this, payload);
		}
	};

	public render() {
		let chartInfo = {
			chartColors: ChartColors,
			pieInfo: [],
			data: [],
			key: "",
			width: 500,
			height: 300,
			outerRadius: 100, // Pie Outer Radius
			pieSpacing: 15, // Space between Pies
			margin: {
				top: 20,
				right: 30,
				left: 20,
				bottom: 5
			},
			...this.props?.chartInfo
		};
		return (
			<React.Fragment>
				<div>
					{this.showTitle()}
					{/*<ResponsiveContainer width="100%" height="300" minHeight="300px" minWidth="500px">*/}
					<PieChart
						width={chartInfo.width}
						height={chartInfo.height}
						//data={chartInfo.data}
						margin={chartInfo.margin}
					>
						{/*Chart Title*/}
						{/*<text x={chartInfo.width / 2} y={15} fill="black" textAnchor="middle" dominantBaseline="central">*/}
						{/*	<tspan fontSize="14">{chartInfo.title}</tspan>*/}
						{/*</text>*/}
						<CartesianGrid strokeDasharray="3 3" />
						<Tooltip content={chartInfo.tooltip ? <this.CustomTooltip /> : undefined} />
						<Legend />
						{chartInfo.pieInfo.map((object: any, i: number) =>
							<text x={chartInfo.outerRadius + (chartInfo.outerRadius * i * 2) + ((chartInfo.pieSpacing - 10) * (i + 1))} y={20} fill="black" textAnchor="middle" dominantBaseline="central">
								<tspan fontSize="14">{object.title}</tspan>
							</text>
						)}
						{chartInfo.pieInfo.map((object: any, i: number) =>
							<Pie data={chartInfo.data} dataKey={object.fieldName} name={object.name} label={!object.hideTickLabel}
								fill={object.fill || chartInfo.chartColors[i]}
								strokeWidth={1}
								cx={chartInfo.outerRadius + (chartInfo.outerRadius * i * 2) + (chartInfo.pieSpacing * (i + 1))} outerRadius={chartInfo.outerRadius}
								//activeShape={{ onClick: (payload: any) => { this.onPieClick(payload, null, object); return null; } }}
								legendType={object.legendType || 'none'}
							>
								{!object.hideInsideLabel ? <LabelList content={chartInfo.customLabel}  position="inside" fill="#EBE8E2" stroke="none" dataKey={object.label||chartInfo.label} style={{ pointerEvents: 'none' }} /> : <></>}
								{chartInfo.data.map((entry: any, index: number) => (
									<Cell key={`cell-${index}`} fill={chartInfo.chartColors[index % chartInfo.chartColors.length]} name={entry[chartInfo.key]}
										onClick={(e) => { this.onPieClick(e, entry, object) }} style={{ cursor: 'pointer' }}
									/>
								))}
							</Pie>
						)}
					</PieChart>
					{/*</ResponsiveContainer>*/}
				</div>
			</React.Fragment>
		);
	}

	private showTitle() {
		return (this.props?.chartInfo && this.props?.chartInfo.title ? <div className="d-block text-center">{this.props?.chartInfo.title}</div> : <></>)
	}
};
