import * as React from 'react';

export const AppContext = React.createContext({
	// Get Text Input from User
	showAlert: (message: string, messageHeader: string) => { },
	// Get Confirmation from User
	showConfirm: (message: string, messageHeader: string, callback: any, data?: any) => { },
	// Does User have a role
	hasUserRole: (role: string) => <boolean>{},
	// Page Header Title
	changeTitle: (title?: string) => { },
	patchState: (state: any) => { },
	// Proxies Page Filter
	setProxyFilter: (cik: string, family: string, year: string, solicitor: string, meetingType: string, meetingDate: string, routine?: string, openOrClosedCIK?: string, classId?: string, seriesId?: string) => { },
	// Proxies Page Filters
	getProxyFilterClassId: () => <string>{},
	getProxyFilterSeriesId: () => <string>{},
	getProxyFilterCik: () => <string>{},
	getProxyFilterFamily: () => <string>{},
	getProxyFilterYear: () => <string>{},
	getProxyFilterSolicitor: () => <string>{},
	getProxyFilterMeetingType: () => <string>{},
	getProxyFilterMeetingDate: () => <string>{},
	getProxyFilterRoutine: () => <string>{},
	getProxyFilterOpenOrClosedCIK: () => <string>{},
	// Add New Fund Family with CIKs
	addFamily: (name: string, ciks: string) => { },
	// Get Fund Family Names
	getFamilies: () => <any[]>{},
	getSolicitorFilter: () => <string>{},
	setSolicitorFilter: (filter: string) => { },
	setShowTables: (showTables: boolean) => { },
	getShowTables: () => <boolean>{},
	getSolicitorColors: (sData: any[], pieData: any[]) => <string[]>{},
	setOwnershipFilter: (cik: string, year: string) => { },
	getOwnershipFilterCik: () => <string>{},
	getOwnershipFilterYear: () => <string>{},
});
