import _ from 'lodash';
import * as React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { ApplicationState } from '../store';
import * as ReportStore from '../store/Report';
import { DateToJSON, dateToMDString, dateToString, formatNumber, ProxyFilterParameters, renderCalendarChart, renderPieChart, renderProxyCountsChart, solicitorUrl } from '../store/Util';
import { NivoCalendarChart } from './charts/nivo-calendar';
import { RCPieChart } from './charts/rc-pie-chart';
import { RCStackedChart } from './charts/rc-stacked-bar-chart';
import { format, formatDistance, formatRelative, parseISO, subDays } from 'date-fns';
import { TooltipWrapper } from '@nivo/tooltip';
import { SolicitorMarketSharePieChart } from './SolicitorMarketSharePieChart';

// At runtime, Redux will merge together...
type ReportProps =
	ReportStore.ReportState // ... state we've requested from the Redux store
	& typeof ReportStore.actionCreators // ... plus action creators we've requested
	& RouteComponentProps<{ name: string }>;

class Report extends React.PureComponent<ReportProps> {
	static contextType = AppContext;
	context!: React.ContextType<typeof AppContext>;
	reportFilter: ReportStore.ReportFilter = {};
	componentMounted: boolean = false;

	// This method is called when the component is first added to the document
	constructor(props: any) {
		super(props);
	}

	public componentDidMount() {
		this.props.resetState();
		this.ensureDataFetched();
		this.context.changeTitle('Proxy Report'); // Proxy Filing Report
		this.componentMounted = true;
	}

	// This method is called when the route parameters change
	public componentDidUpdate() {
		this.ensureDataFetched();
	}

	MyExportCSV = (props: any) => {
		const handleClick = () => {
			props.onExport(props.data);
		};
		return (
			<img src={"microsoft-excel.svg"} height="30px" onClick={handleClick} className="float-right pl-2" role="button" title="Export Proxy Solicitors to Excel (.csv)" />
		);
	}

	public render() {
		return (
			<React.Fragment>
				<div className="col-12 overflow-auto">
					<div className="position-absolute">
						{this.renderReport()}
					</div>
				</div>
			</React.Fragment>
		);
	}

	private renderReport() {
		if (!this.props.initialized || !this.componentMounted)
			return; // Not ready yet
		switch (this.reportFilter.name) {
			case "solicitors": // Solicitors
				this.context.changeTitle('Proxy Solicitors');
				return <>
					{this.renderSolicitorProxyCountsTable()}
					{this.renderSolicitorCharts()}
				</>;
			case "meetingdates": // All Meetings
				this.context.changeTitle('Proxy Meetings');
				//return this.renderAllMeetingDateProxyCountsTable(0);
				return <React.Fragment><div className="row">
					<div>
						{this.renderAllMeetingDateProxyCountsTable(0)}
					</div>
					<div className="ml-3">
						{this.renderMeetingDatesChart({ width: 125, chartType: 'Calendar' }, true)}
					</div>
				</div></React.Fragment>;
			case "pastmeetings": // Past Meetings
				this.context.changeTitle('Proxy Meetings');
				//return this.renderMeetingDateProxyCountsTable(1);
				return <><div className="row">
					<div>
						{this.renderMeetingDateProxyCountsTable(1)}
					</div>
					<div className="ml-3">
						{this.renderMeetingDatesChart({ width: 840, chartType: 'Calendar' })}
					</div>
				</div></>;
			case "upcomingmeetings": // Upcoming Meetings
				this.context.changeTitle('Proxy Meetings');
				return <div className="row">
					<div>
						{this.renderMeetingDateProxyCountsTable(2)}
					</div>
					<div className="ml-4">
						{this.renderMeetingDatesChart({ width: 300 })}
					</div>
				</div>;
			case "years": // Years
				this.context.changeTitle('Proxy Report');
				return <>
					{this.renderYearlyProxyCountsTable()}
					{this.renderYearChart()}
				</>;
			case "meetingtypes": // Meeting Types
				this.context.changeTitle('Proxy Meeting Types');
				return <>
					{this.renderMeetingTypeProxyCountsTable()}
					{this.renderMeetingTypeChart()}
				</>;
			case "families": // Families
				this.context.changeTitle('Families');
				return <>
					{this.renderFamilyProxyCountsTable()}
				</>;
			case "ownership-families": // Families
				this.context.changeTitle('Ownership Fund Families');
				return <>
					{this.renderFamilyOwnershipCountsTable()}
				</>;
			default: // Unknown Report, Redirect to Dashboard
				return <Redirect to={"/dashboard"} />;
		}
	}

	private ensureDataFetched() {
		let name = this.props.match.params.name || 'solicitors';
		if (this.reportFilter && this.reportFilter.name == name)
			return;
		this.reportFilter = { name: name };
		this.props.requestReport(this.reportFilter);
	}

	paginationTotalRenderer = (from: any, to: any, size: any) => (
		size ?
			<span className="react-bootstrap-table-pagination-total pl-2">
				Showing {from} to {to} of {formatNumber(size)} Results
			</span> : <span></span>
	);

	private emptyDataMessage = () => {
		if (this.props.isLoading)
			return 'Loading...';
		return 'No Data to Display';
	}

	private renderYearlyProxyCountsTable() {
		var columns = [
			{
				dataField: 'year',
				text: 'Year',
				sort: true,
				align: "center",
				headerStyle: () => { return { width: '70px' }; },
				headerTitle: () => 'Year Filed on SEC EDGAR',
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount',
				text: 'Proxies',
				sort: true,
				align: "right",
				headerStyle: () => { return { width: '80px' }; },
				headerTitle: () => '# Proxies Filed in Year',
				title: (cell: any, row: any) => `View Proxies Filed in the year ${row.year}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/proxies" onClick={() => this.setProxyFilterYear(row.year)} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'annual',
				text: 'Annual',
				sort: true,
				align: "right",
				headerTitle: () => '# Annual Meeting Proxies in Year',
				title: (cell: any, row: any) => `View List of Annual Meeting Proxies in ${row.year}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilterMeetingType("Annual", row.year)} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'special',
				text: 'Special',
				sort: true,
				align: "right",
				headerTitle: () => '# Special Meeting Proxies in a Year',
				title: (cell: any, row: any) => `View List of Special Meeting Proxies in ${row.year}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilterMeetingType("Special", row.year)} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'routine',
				text: 'Routine',
				sort: true,
				align: "center",
				headerTitle: () => '# Routine Meeting Proxies in a Year',
				title: (cell: any, row: any) => `View List of Routine Meeting Proxies in ${row.year}`,
				//formatter: (cell: any) => formatNumber(cell),
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ routine: "R", year: row.year })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'nonRoutine',
				text: 'Non-Routine',
				sort: true,
				align: "center",
				headerTitle: () => '# Non-Routine Meeting Proxies in a Year',
				title: (cell: any, row: any) => `View List of Non-Routine Meeting Proxies in ${row.year}`,
				//formatter: (cell: any) => formatNumber(cell),
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ routine: "N", year: row.year })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'openEnd',
				text: 'Open-end',
				sort: true,
				align: "center",
				headerTitle: () => '# Proxies for Open-end Investment by a Solicitor',
				title: (cell: any, row: any) => `View List of Proxies for Open-end Investment in ${row.year}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ openOrClosedCIK: "0", year: row.year })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'closedEnd',
				text: 'Closed-end',
				sort: true,
				align: "center",
				headerTitle: () => '# Proxies for Closed-end Investment by a Solicitor',
				title: (cell: any, row: any) => `View List of Proxies for Closed-end Investment in ${row.year}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ openOrClosedCIK: "1", year: row.year })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'etf',
				text: 'ETF',
				sort: true,
				align: "center",
				headerTitle: () => '# Proxies for ETFs by a Solicitor',
				title: (cell: any, row: any) => `View List of Proxies for ETFs in ${row.year}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ openOrClosedCIK: "2", year: row.year })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			}
		];

		return (
			<ToolkitProvider
				keyField="year"
				data={this.props.reportData && this.props.reportData.reportData3 ? this.props.reportData.reportData3 : []}
				columns={columns}
			>{
					props => (
						<div>
							<p>Proxy Filings by Year</p>
							<BootstrapTable
								{...props.baseProps}
								bootstrap4={true}
								striped={true}
								bordered={false}
								headerClasses="table-header-class"
								classes={"unset-width"}
								noDataIndication={this.emptyDataMessage}
								hover
								condensed
								defaultSorted={[{
									dataField: 'year', // if dataField is not match to any column you defined, it will be ignored.
									order: 'asc' // desc or asc
								}]}
							/>
							{/*<p className="text-secondary small">* Routine/Non-Routine data is for year 2023 only</p>*/}
						</div>
					)
				}
			</ToolkitProvider>
		);
	}

	private renderSolicitorProxyCountsTable() {
		var columns = [
			{
				dataField: 'solicitor',
				text: 'Solicitor',
				sort: true,
				//headerStyle: () => { return { width: '100px' }; },
				headerTitle: () => 'Solicitor of Proxy',
				title: (cell: any) => `Visit Website of ${cell}`,
				formatter: (cell: any, row: any) => solicitorUrl(cell),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount',
				text: 'Proxies',
				sort: true,
				align: "right",
				//headerStyle: () => { return { width: '80px' }; },
				headerTitle: () => '# Proxies by Solicitor',
				title: (cell: any, row: any) => `View List of Proxies Solicited By ${row.solicitor}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/proxies" onClick={() => this.setProxyFilterSolicitor(row.solicitor)} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount24',
				text: 'Proxies 2024',
				sort: true,
				align: "right",
				//headerStyle: () => { return { width: '80px' }; },
				headerTitle: () => '# Proxies by Solicitor in 2024',
				title: (cell: any, row: any) => `View List of Proxies Solicited by ${row.solicitor}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/proxies" onClick={() => this.setProxyFilterSolicitor(row.solicitor, "2024")} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount23',
				text: 'Proxies 2023',
				sort: true,
				align: "right",
				//headerStyle: () => { return { width: '80px' }; },
				headerTitle: () => '# Proxies by Solicitor in 2023',
				title: (cell: any, row: any) => `View List of Proxies Solicited by ${row.solicitor}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/proxies" onClick={() => this.setProxyFilterSolicitor(row.solicitor, "2023")} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount22',
				text: 'Proxies 2022',
				sort: true,
				align: "right",
				//headerStyle: () => { return { width: '80px' }; },
				headerTitle: () => '# Proxies by Solicitor in 2022',
				title: (cell: any, row: any) => `View List of Proxies Solicited by ${row.solicitor}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/proxies" onClick={() => this.setProxyFilterSolicitor(row.solicitor, "2022")} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount21',
				text: 'Proxies 2021',
				sort: true,
				align: "right",
				//headerStyle: () => { return { width: '80px' }; },
				headerTitle: () => '# Proxies by Solicitor in 2021',
				title: (cell: any, row: any) => `View List of Proxies Solicited by ${row.solicitor}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/proxies" onClick={() => this.setProxyFilterSolicitor(row.solicitor, "2021")} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount20',
				text: 'Proxies 2020',
				sort: true,
				align: "right",
				//headerStyle: () => { return { width: '80px' }; },
				headerTitle: () => '# Proxies by Solicitor in 2020',
				title: (cell: any, row: any) => `View List of Proxies Solicited by ${row.solicitor}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/proxies" onClick={() => this.setProxyFilterSolicitor(row.solicitor, "2020")} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount19',
				text: 'Proxies 2019',
				sort: true,
				align: "right",
				//headerStyle: () => { return { width: '80px' }; },
				headerTitle: () => '# Proxies by Solicitor in 2019',
				title: (cell: any, row: any) => `View List of Proxies Solicited by ${row.solicitor}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/proxies" onClick={() => this.setProxyFilterSolicitor(row.solicitor, "2019")} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'annual',
				text: 'Annual',
				sort: true,
				align: "right",
				headerTitle: () => '# Annual Meeting Proxies by a Solicitor',
				title: (cell: any, row: any) => `View List of Annual Meeting Proxies by ${row.solicitor}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilterSolicitorMeetingType(row.solicitor, "Annual")} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'special',
				text: 'Special',
				sort: true,
				align: "right",
				headerTitle: () => '# Special Meeting Proxies by a Solicitor',
				title: (cell: any, row: any) => `View List of Special Meeting Proxies by ${row.solicitor}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilterSolicitorMeetingType(row.solicitor, "Special")} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'routine',
				text: 'Routine',
				sort: true,
				align: "center",
				headerTitle: () => '# Routine Meeting Proxies by a Solicitor',
				title: (cell: any, row: any) => `View List of Routine Meeting Proxies by ${row.solicitor}`,
				//formatter: (cell: any) => formatNumber(cell),
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ routine: "R", solicitor: row.solicitor })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'nonRoutine',
				text: 'Non-Routine',
				sort: true,
				align: "center",
				headerTitle: () => '# Non-Routine Meeting Proxies by a Solicitor',
				title: (cell: any, row: any) => `View List of Non-Routine Meeting Proxies by ${row.solicitor}`,
				//formatter: (cell: any) => formatNumber(cell),
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ routine: "N", solicitor: row.solicitor })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'openEnd',
				text: 'Open-end',
				sort: true,
				align: "center",
				headerTitle: () => '# Proxies for Open-end Investment by a Solicitor',
				title: (cell: any, row: any) => `View List of Proxies for Open-end Investment by ${row.solicitor}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ openOrClosedCIK: "0", solicitor: row.solicitor })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'closedEnd',
				text: 'Closed-end',
				sort: true,
				align: "center",
				headerTitle: () => '# Proxies for Closed-end Investment by a Solicitor',
				title: (cell: any, row: any) => `View List of Proxies for Closed-end Investment by ${row.solicitor}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ openOrClosedCIK: "1", solicitor: row.solicitor })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'etf',
				text: 'ETF',
				sort: true,
				align: "center",
				headerTitle: () => '# Proxies for ETFs by a Solicitor',
				title: (cell: any, row: any) => `View List of Proxies for ETFs by ${row.solicitor}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ openOrClosedCIK: "2", solicitor: row.solicitor })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
		];

		return (
			<ToolkitProvider
				keyField="solicitor"
				data={this.props.reportData && this.props.reportData.reportData4 ? this.props.reportData.reportData4 : []}
				columns={columns}
				exportCSV={{
					fileName: 'ProxySolicitors.csv',
					blobType: 'text/csv;charset=utf-8'
				}}
			>{
					props => (
						<div>
							<p>Proxy Filings by Solicitor
								{this.props.reportData && this.props.reportData.reportData4 && this.props.reportData.reportData4.length ? <this.MyExportCSV {...props.csvProps} /> : ""}
							</p>
							<BootstrapTable
								{...props.baseProps}
								bootstrap4={true}
								striped={true}
								bordered={false}
								headerClasses="table-header-class"
								classes={"unset-width"}
								noDataIndication={this.emptyDataMessage}
								hover
								condensed
								defaultSorted={[{
									dataField: 'proxyCount', // if dataField is not match to any column you defined, it will be ignored.
									order: 'desc' // desc or asc
								}]}
							/>
							{/*<p className="text-secondary small">* Routine/Non-Routine data is for year 2023 only</p>*/}
						</div>
					)
				}
			</ToolkitProvider>
		);
	}

	onActiveDotClick = (lineInfo: any, data: any, value: any, key: string, chart: RCStackedChart, stateToSet: any = {}) => {
		//let stateToSet: any = {};
		stateToSet[key] = data[key];

		switch (lineInfo.fieldName) {
			case "annual":
				stateToSet["meetingType"] = "Annual";
				break;
			case "special":
				stateToSet["meetingType"] = "Special";
				break;
			case "routine":
				stateToSet["routine"] = "R";
				break;
			case "nonRoutine":
				stateToSet["routine"] = "N";
				break;
		}
		//alert(`${key} ${data[key]} ${lineInfo.fieldName}: ${value}`);
		this.setProxyFilter(stateToSet);
		this.props.history.push('/proxies');
	}

	onBarClick = (barInfo: any, data: any, value: any, key: string, chart: RCStackedChart) => {
		let stateToSet: any = {};
		stateToSet[key] = data[key];

		switch (barInfo.fieldName) {
			case "proxyCount19":
				stateToSet["year"] = "2019";
				break;
			case "proxyCount20":
				stateToSet["year"] = "2020";
				break;
			case "proxyCount21":
				stateToSet["year"] = "2021";
				break;
			case "proxyCount22":
				stateToSet["year"] = "2022";
				break;
			case "proxyCount23":
				stateToSet["year"] = "2023";
				break;
			case "proxyCount24":
				stateToSet["year"] = "2024";
				break;
			case "annual":
				stateToSet["meetingType"] = "Annual";
				break;
			case "special":
				stateToSet["meetingType"] = "Special";
				break;
			case "routine":
				stateToSet["routine"] = "R";
				break;
			case "nonRoutine":
				stateToSet["routine"] = "N";
				break;
		}
		//alert(`${key} ${data[key]} ${barInfo.fieldName}: ${value}`);
		this.setProxyFilter(stateToSet);
		this.props.history.push('/proxies');
	}

	onCalendarClick = (data: any, key: any, chart: NivoCalendarChart) => {
		let stateToSet: any = {};
		stateToSet[key] = data.day;

		this.setProxyFilter(stateToSet);
		this.props.history.push('/proxies');
	}

	// Solicitor Chart
	private renderSolicitorCharts() {
		if (this.props.isLoading)
			return (<></>);

		let barInfo: any = [
			{ fieldName: "proxyCount19", name: "2019" },
			{ fieldName: "proxyCount20", name: "2020" },
			{ fieldName: "proxyCount21", name: "2021" },
			{ fieldName: "proxyCount22", name: "2022" },
			{ fieldName: "proxyCount23", name: "2023" },
			{ fieldName: "proxyCount24", name: "2024" }
		];
		let lineInfo: any = [
			{ fieldName: "annual", name: "Annual" },
			{ fieldName: "special", name: "Special" }
		];
		let areaInfo: any = [
			{ fieldName: "routine", name: "Routine" },
			{ fieldName: "nonRoutine", name: "Non-Routine" }
		];

		const key: string = 'solicitor';

		// Stacked Bar Chart for Solicitors Yearly Counts
		let yearlyChartInfo: any = {
			key,
			barInfo,
			data: _.filter(this.props.reportData?.reportData4 || [], (o) => (o.proxyCount || 0) > 10),
			width: 1240,
			margin: { top: 5, left: -10 },
			//stackOffset: 'expand',
			title: 'Yearly Proxy Filings by Solicitors',
			onBarClick: (barInfo: any, data: any, value: any, key: string, chart: RCStackedChart) => { this.onBarClick(barInfo, data, value, key, chart) }
		};

		// Line Chart for Meeting Type (Annual/Special)
		let meetingTypeChartInfo = {
			key,
			lineInfo,
			data: _.take(this.props.reportData?.reportData4 || [], 11),
			width: 1240,
			margin: { top: 20, left: -10 },
			title: 'Annual/Special Proxy Filings by Solicitors',
			onActiveDotClick: (lineInfo: any, data: any, value: any, key: string, chart: RCStackedChart) => { this.onActiveDotClick(lineInfo, data, value, key, chart) }
		};

		// Area Chart for Routine/None-Routine Data
		let routineChartInfo = {
			key,
			areaInfo,
			data: _.take(this.props.reportData?.reportData4 || [], 11),
			width: 1240,
			margin: { top: 20, left: -10 },
			title: 'Routine/Non-Routine Proxy Filings by Solicitors',
			onActiveDotClick: (lineInfo: any, data: any, value: any, key: string, chart: RCStackedChart) => { this.onActiveDotClick(lineInfo, data, value, key, chart) }
		};
		return <React.Fragment>
			<div className="row mt-3">
			{/*	<div>*/}
			{/*		<SolicitorMarketSharePieChart data={this.props.reportData?.reportData4} isLoading={this.props.isLoading} onActiveDotClick={this.onActiveDotClick} filter="cost" title="Market Share of Fees in 2019-2024"></SolicitorMarketSharePieChart>*/}
			{/*	</div>*/}
			{/*	<div className="ml-5">*/}
			{/*		<SolicitorMarketSharePieChart data={this.props.reportData?.reportData4} isLoading={this.props.isLoading} onActiveDotClick={this.onActiveDotClick} filter="cost24" title="Market Share of Fees in 2024"></SolicitorMarketSharePieChart>*/}
			{/*	</div>*/}
			{/*	<div className="ml-5">*/}
			{/*		<SolicitorMarketSharePieChart data={this.props.reportData?.reportData4} isLoading={this.props.isLoading} onActiveDotClick={this.onActiveDotClick} filter="cost23" title="Market Share of Fees in 2023"></SolicitorMarketSharePieChart>*/}
			{/*	</div>*/}
			{/*	<div className="ml-5">*/}
			{/*		<SolicitorMarketSharePieChart data={this.props.reportData?.reportData4} isLoading={this.props.isLoading} onActiveDotClick={this.onActiveDotClick} filter="cost22" title="Market Share of Fees in 2022"></SolicitorMarketSharePieChart>*/}
			{/*	</div>*/}
			</div>
			{/*<div className="row mt-3">*/}
			{/*	<div>*/}
			{/*		<SolicitorMarketSharePieChart data={this.props.reportData?.reportData4} isLoading={this.props.isLoading} onActiveDotClick={this.onActiveDotClick} filter="cost21" title="Market Share of Fees in 2021"></SolicitorMarketSharePieChart>*/}
			{/*	</div>*/}
			{/*	<div className="ml-5">*/}
			{/*		<SolicitorMarketSharePieChart data={this.props.reportData?.reportData4} isLoading={this.props.isLoading} onActiveDotClick={this.onActiveDotClick} filter="cost20" title="Market Share of Fees in 2020"></SolicitorMarketSharePieChart>*/}
			{/*	</div>*/}
			{/*	<div className="ml-5">*/}
			{/*		<SolicitorMarketSharePieChart data={this.props.reportData?.reportData4} isLoading={this.props.isLoading} onActiveDotClick={this.onActiveDotClick} filter="cost19" title="Market Share of Fees in 2019"></SolicitorMarketSharePieChart>*/}
			{/*	</div>*/}
			{/*</div>*/}
			{/*{renderProxyCountsChart(yearlyChartInfo)}*/}
			{/*<div className="row mt-3">*/}
			{/*	<div>*/}
			{/*		<SolicitorMarketSharePieChart data={this.props.reportData?.reportData4} isLoading={this.props.isLoading} onActiveDotClick={this.onActiveDotClick} filter="" title="Market Share of Proxy Filings in 2019-2023"></SolicitorMarketSharePieChart>*/}
			{/*	</div>*/}
			{/*	<div className="ml-5">*/}
			{/*		<SolicitorMarketSharePieChart data={this.props.reportData?.reportData4} isLoading={this.props.isLoading} onActiveDotClick={this.onActiveDotClick} filter="23" title="Market Share of Proxy Filings in 2023"></SolicitorMarketSharePieChart>*/}
			{/*	</div>*/}
			{/*	<div className="ml-5">*/}
			{/*		<SolicitorMarketSharePieChart data={this.props.reportData?.reportData4} isLoading={this.props.isLoading} onActiveDotClick={this.onActiveDotClick} filter="22" title="Market Share of Proxy Filings in 2022"></SolicitorMarketSharePieChart>*/}
			{/*	</div>*/}
			{/*</div>*/}
			{/*<div className="row mt-3">*/}
			{/*	<div>*/}
			{/*		<SolicitorMarketSharePieChart data={this.props.reportData?.reportData4} isLoading={this.props.isLoading} onActiveDotClick={this.onActiveDotClick} filter="21" title="Market Share of Proxy Filings in 2021"></SolicitorMarketSharePieChart>*/}
			{/*	</div>*/}
			{/*	<div className="ml-5">*/}
			{/*		<SolicitorMarketSharePieChart data={this.props.reportData?.reportData4} isLoading={this.props.isLoading} onActiveDotClick={this.onActiveDotClick} filter="20" title="Market Share of Proxy Filings in 2020"></SolicitorMarketSharePieChart>*/}
			{/*	</div>*/}
			{/*	<div className="ml-5">*/}
			{/*		<SolicitorMarketSharePieChart data={this.props.reportData?.reportData4} isLoading={this.props.isLoading} onActiveDotClick={this.onActiveDotClick} filter="19" title="Market Share of Proxy Filings in 2019"></SolicitorMarketSharePieChart>*/}
			{/*	</div>*/}
			{/*</div>*/}
			{renderProxyCountsChart(meetingTypeChartInfo)}
			{renderProxyCountsChart(routineChartInfo)}
		{/*	<div className="row mt-3">*/}
		{/*		<div>*/}
		{/*			<SolicitorMarketSharePieChart data={this.props.reportData?.reportData4} isLoading={this.props.isLoading} onActiveDotClick={this.onActiveDotClick} filter="OpenEnd" title="Market Share of Proxy Filings for Open-end Funds"></SolicitorMarketSharePieChart>*/}
		{/*		</div>*/}
		{/*		<div className="ml-5">*/}
		{/*			<SolicitorMarketSharePieChart data={this.props.reportData?.reportData4} isLoading={this.props.isLoading} onActiveDotClick={this.onActiveDotClick} filter="ClosedEnd" title="Market Share of Proxy Filings for Closed-end Funds"></SolicitorMarketSharePieChart>*/}
		{/*		</div>*/}
		{/*	</div>*/}
		</React.Fragment>
	}

	// Meeting Type Chart
	private renderMeetingTypeChart() {
		if (this.props.isLoading)
			return (<></>);

		let barInfo: any = [
			{ fieldName: "proxyCount19", name: "2019", stackId: 'a' },
			{ fieldName: "proxyCount20", name: "2020", stackId: 'b' },
			{ fieldName: "proxyCount21", name: "2021", stackId: 'c' },
			{ fieldName: "proxyCount22", name: "2022", stackId: 'd' },
			{ fieldName: "proxyCount23", name: "2023", stackId: 'e' },
			{ fieldName: "proxyCount24", name: "2024", stackId: 'f' }
		];
		let pieInfo: any = [
			//{ fieldName: "annual", name: "Annual" },
			//{ fieldName: "special", name: "Special" }
			{ fieldName: "routine", title: "Routine", hideTickLabel: true, label: "routine" },
			{ fieldName: "nonRoutine", title: "Non-Routine", legendType: 'diamond', hideTickLabel: true, label: "nonRoutine" }
		];

		const key: string = 'meetingType';
		let barChartInfo: any = {
			key,
			barInfo,
			data: this.props.reportData?.reportData6 || [],
			width: 525,
			margin: { top: 5, left: -15 },
			title: 'Yearly Proxy Filings by Meeting Types',
			onBarClick: (barInfo: any, data: any, value: any, key: string, chart: RCStackedChart) => { this.onBarClick(barInfo, data, value, key, chart) }
		};

		//let data = [];
		//if (this.props.reportData?.reportData6) {
		//	data.push({ name: 'Routine', annual: this.props.reportData?.reportData6[1].routine, special: this.props.reportData?.reportData6[0].routine });
		//	data.push({ name: 'Non-Routine', annual: this.props.reportData?.reportData6[1].nonRoutine, special: this.props.reportData?.reportData6[0].nonRoutine });
		//}
		let pieChartInfo = {
			key,
			pieInfo,
			data: this.props.reportData?.reportData6 || [],
			width: 430,
			margin: { top: 5, left: -15 },
			title: 'Routine/Non-Routine Proxy Filings by Meeting Types',
			onPieClick: (lineInfo: any, data: any, value: any, key: string, chart: RCStackedChart) => { this.onActiveDotClick(lineInfo, data, value, key, chart) }
		};

		return <React.Fragment>
			<div className="row">
				<div>
					{renderProxyCountsChart(barChartInfo)}
				</div>
				<div className="ml-5">
					{renderPieChart(pieChartInfo)}
				</div>
			</div>
		</React.Fragment>
	}

	// Yearly Proxy Counts Chart
	private renderYearChart() {
		if (this.props.isLoading)
			return (<></>);

		let barInfo: any = [
			{ fieldName: "proxyCount", name: "Total", stackId: 'a' },
			{ fieldName: "annual", name: "Annual", stackId: 'b' },
			{ fieldName: "special", name: "Special", stackId: 'b' }
		];

		const key: string = 'year';
		let chartInfo: any = {
			key,
			barInfo,
			data: this.props.reportData?.reportData3 || [],
			margin: { top: 5, left: -15 },
			title: 'Yearly Proxy Filings',
			onBarClick: (barInfo: any, data: any, value: any, key: string, chart: RCStackedChart) => { this.onBarClick(barInfo, data, value, key, chart) }
		};

		return <React.Fragment>
			{renderProxyCountsChart(chartInfo)}
		</React.Fragment>
	}

	// Meeting Dates Chart: Upcoming/Past/All Meetings
	private renderMeetingDatesChart(chartInfo: any = {}, allMeetings: boolean = false) {
		if (this.props.isLoading)
			return (<></>);
		// TODO: Hide Saturday, Sunday?
		chartInfo = {
			direction: 'vertical',
			height: 1024,
			width: 300,
			key: 'meetingDate',
			chartType: 'TimeRange', //allMeetings ? 'Calendar' : 'TimeRange',
			margin: { top: 75, right: 0, bottom: 0, left: 20 },
			onCalendarClick: (data: any, key: string, chart: NivoCalendarChart) => { this.onCalendarClick(data, key, chart) },
			...chartInfo
		};

		chartInfo.data = (allMeetings ? this.getAllMeetingDateProxyCounts()
			: (this.props.reportData && this.props.reportData.reportData5 ? this.props.reportData.reportData5 : []))
			.map((d: any, index: number) => {
				let data: any = {};
				if (typeof (d.meetingDate) == 'string') {
					data.day = d.meetingDate.substr(0, 10); // Upcoming/Past Meetings
				} else { // All Meetings
					data.day = format(d.meetingDate, 'yyyy-MM-dd');
				}
				data.value = d.proxyCount;
				data.row = d;
				return data;
			});

		// Day Square Tooltip
		chartInfo.tooltip = (props: any, e: any, chart: NivoCalendarChart) => {
			let d: any = props;
			let color: any = d.color;
			let date: Date = parseISO(d.day);
			let day: string = date.getFullYear() <= 1980 ? format(date, 'MMMM d') : format(date, 'EEE M/d/yyyy');
			let className: string = date.getFullYear() <= 1980 ? '' : 'displayNone'; // Show only for All Meetings tab
			let row: any = d.row ? d.row : d.data.row;
			return <React.Fragment>
				<TooltipWrapper anchor="bottom" position={[0, 0]}>
					<div className='nivoCalendarDiv' style={{ border: '1px solid ' + color }}><table style={{ color }}><tbody>
						<tr><th colSpan={2} style={{ borderBottom: '2px solid ' + color }}>{day}</th></tr>
						<tr><td>Proxies:</td><td>{d.value.toString()}</td></tr>
						<tr className={className}><td>Proxies 2019:</td><td>{row.proxyCount2019 || '-'}</td></tr>
						<tr className={className}><td>Proxies 2020:</td><td>{row.proxyCount2020 || '-'}</td></tr>
						<tr className={className}><td>Proxies 2021:</td><td>{row.proxyCount2021 || '-'}</td></tr>
						<tr className={className}><td>Proxies 2022:</td><td>{row.proxyCount2022 || '-'}</td></tr>
						<tr className={className}><td>Proxies 2023:</td><td>{row.proxyCount2023 || '-'}</td></tr>
						<tr className={className}><td>Proxies 2024:</td><td>{row.proxyCount2024 || '-'}</td></tr>
						<tr><td>Annual:</td><td>{row.annual || '-'}</td></tr>
						<tr><td>Special:</td><td>{row.special || '-'}</td></tr>
						<tr><td>Routine:</td><td>{row.routine || '-'}</td></tr>
						<tr><td>Non-Routine:</td><td>{row.nonRoutine || '-'}</td></tr>
						<tr><td>Open-end:</td><td>{row.openEnd || '-'}</td></tr>
						<tr><td>Closed-end:</td><td>{row.closedEnd || '-'}</td></tr>
					</tbody></table></div>
				</TooltipWrapper>
			</React.Fragment>;
		};

		return <React.Fragment>
			{renderCalendarChart(chartInfo)}
		</React.Fragment>
	}

	private renderMeetingDateProxyCountsTable(pastOrFuture: number) {
		const upcomingClass = this.reportFilter?.name == 'upcomingmeetings' ? 'nav-link active' : 'nav-link';
		const pastClass = this.reportFilter?.name == 'pastmeetings' ? 'nav-link active' : 'nav-link';
		const allClass = this.reportFilter?.name == 'meetingdates' ? 'nav-link active' : 'nav-link';
		var columns = [
			{
				dataField: 'meetingDate',
				text: 'Meeting Date',
				sort: true,
				align: "right",
				headerTitle: () => 'Proxy Meeting Date',
				formatter: (cell: any, row: any) => dateToString(cell),
				csvFormatter: (cell: any) => dateToString(cell)
			},
			{
				dataField: 'proxyCount',
				text: 'Proxies',
				sort: true,
				align: "right",
				headerTitle: () => '# Proxies with Meeting Date',
				title: (cell: any, row: any) => `View List of Proxies for Meetings on ${dateToString(row.meetingDate)}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilterMeetingDate(row.meetingDate)} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'annual',
				text: 'Annual',
				sort: true,
				align: "right",
				headerTitle: () => '# Annual Meeting Proxies with Meeting Date',
				title: (cell: any, row: any) => `View List of Proxies fo Annual Meetings on ${dateToString(row.meetingDate)}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilterMeetingDate(row.meetingDate, "Annual")} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'special',
				text: 'Special',
				sort: true,
				align: "right",
				headerTitle: () => '# Special Meeting Proxies with Meeting Date',
				title: (cell: any, row: any) => `View List of Proxies for Special Meetings on ${dateToString(row.meetingDate)}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilterMeetingDate(row.meetingDate, "Special")} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'routine',
				text: 'Routine',
				sort: true,
				align: "right",
				headerTitle: () => '# Routine Meeting Proxies with Meeting Date',
				title: (cell: any, row: any) => `View List of Proxies for Routine Meetings on ${dateToString(row.meetingDate)}`,
				//formatter: (cell: any) => formatNumber(cell),
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ routine: "R", meetingDate: row.meetingDate })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'nonRoutine',
				text: 'Non-Routine',
				sort: true,
				align: "right",
				headerTitle: () => '# Non-Routine Meeting Proxies with Meeting Date',
				title: (cell: any, row: any) => `View List of Proxies for Non-Routine Meetings on ${dateToString(row.meetingDate)}`,
				//formatter: (cell: any) => formatNumber(cell),
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ routine: "N", meetingDate: row.meetingDate })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'openEnd',
				text: 'Open-end',
				sort: true,
				align: "center",
				headerTitle: () => '# Proxies for Open-end Investment by a Solicitor',
				title: (cell: any, row: any) => `View List of Proxies for Open-end Investment Meetings on ${dateToString(row.meetingDate)}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ openOrClosedCIK: "0", meetingDate: row.meetingDate })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'closedEnd',
				text: 'Closed-end',
				sort: true,
				align: "center",
				headerTitle: () => '# Proxies for Closed-end Investment by a Solicitor',
				title: (cell: any, row: any) => `View List of Proxies for Closed-end Investment Meetings on ${dateToString(row.meetingDate)}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ openOrClosedCIK: "1", meetingDate: row.meetingDate })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'etf',
				text: 'ETF',
				sort: true,
				align: "center",
				headerTitle: () => '# Proxies for ETFs by a Solicitor',
				title: (cell: any, row: any) => `View List of Proxies for ETF Meetings on ${dateToString(row.meetingDate)}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ openOrClosedCIK: "2", meetingDate: row.meetingDate })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			}
		];

		return (
			<ToolkitProvider
				keyField="meetingDate"
				data={this.props.reportData && this.props.reportData.reportData5 ? this.props.reportData.reportData5 : []}
				columns={columns}
			>{
					props => (
						<div>
							<ul className="nav nav-tabs mb-2">
								<li className="nav-item">
									<Link to="/report/upcomingmeetings" className={upcomingClass} aria-current="page">Upcoming Meetings</Link>
								</li>
								<li className="nav-item">
									<Link to="/report/pastmeetings" className={pastClass}>Past Meetings</Link>
								</li>
								<li className="nav-item">
									<Link to="/report/meetingdates" className={allClass}>All Meetings</Link>
								</li>
							</ul>
							<BootstrapTable
								{...props.baseProps}
								bootstrap4={true}
								striped={true}
								bordered={false}
								headerClasses="table-header-class"
								classes={"unset-width"}
								noDataIndication={this.emptyDataMessage}
								hover
								condensed
							//defaultSorted={[{
							//	dataField: 'meetingDate', // if dataField is not match to any column you defined, it will be ignored.
							//	order: 'asc' // desc or asc
							//}]}
							/>
							{/*<p className="text-secondary small">* Routine/Non-Routine data is for year 2023 only</p>*/}
						</div>
					)
				}
			</ToolkitProvider>
		);
	}

	private getAllMeetingDateProxyCounts(): any {
		let data = _.values(_.reduce(this.props.reportData && this.props.reportData.reportData5 ? this.props.reportData.reportData5 : [], function (result: any, obj) {
			var dDate = new Date(obj.meetingDate || Date.now());
			var date = new Date(1980, dDate.getMonth() || 0, dDate.getDate() || 1);
			var name = "" + (dDate.getMonth() || 0) + " - " + dDate.getDate() || 1;
			var year = dDate.getFullYear();
			result[name] = result[name] || {};
			result[name]["proxyCount" + year] = (result[name]["proxyCount" + year] || 0) + obj.proxyCount;
			result[name]["proxyCount"] = (result[name]["proxyCount"] || 0) + obj.proxyCount;
			result[name]["annual"] = (result[name]["annual"] || 0) + obj.annual;
			result[name]["special"] = (result[name]["special"] || 0) + obj.special;
			result[name]["routine"] = (result[name]["routine"] || 0) + obj.routine;
			result[name]["nonRoutine"] = (result[name]["nonRoutine"] || 0) + obj.nonRoutine;
			result[name]["openEnd"] = (result[name]["openEnd"] || 0) + obj.openEnd;
			result[name]["closedEnd"] = (result[name]["closedEnd"] || 0) + obj.closedEnd;
			result[name]["etf"] = (result[name]["etf"] || 0) + obj.etf;
			result[name]["meetingDate"] = date;
			//result[name]["meetingDateOrg"] = obj.meetingDate;
			return result;
		}, {}));
		return data;
	}

	private renderAllMeetingDateProxyCountsTable(pastOrFuture: number) {
		const upcomingClass = this.reportFilter?.name == 'upcomingmeetings' ? 'nav-link active' : 'nav-link';
		const pastClass = this.reportFilter?.name == 'pastmeetings' ? 'nav-link active' : 'nav-link';
		const allClass = this.reportFilter?.name == 'meetingdates' ? 'nav-link active' : 'nav-link';
		let data: any = this.getAllMeetingDateProxyCounts();
		var columns = [
			{
				dataField: 'meetingDate',
				text: 'Meeting Date',
				sort: true,
				align: "right",
				headerTitle: () => 'Proxy Meeting Date',
				formatter: (cell: any, row: any) => dateToMDString(cell),
				csvFormatter: (cell: any) => dateToMDString(cell)
			},
			{
				dataField: 'proxyCount',
				text: 'Proxies',
				sort: true,
				align: "right",
				headerTitle: () => '# Proxies with Meeting Date',
				title: (cell: any, row: any) => `View List of Proxies with meeting date ${dateToMDString(row.meetingDate)}`,
				//formatter: (cell: any) => formatNumber(cell),
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilterMeetingDate(row.meetingDate)} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount2024',
				text: 'Proxies 2024',
				sort: true,
				align: "right",
				headerTitle: () => '# Proxies with Meeting Date in 2024',
				title: (cell: any, row: any) => cell ? `View List of Proxies with meeting date ${dateToString(row.meetingDate, 2024)}` : '',
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilterMeetingDate2(row.meetingDate, 2024)} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount2023',
				text: 'Proxies 2023',
				sort: true,
				align: "right",
				headerTitle: () => '# Proxies with Meeting Date in 2023',
				title: (cell: any, row: any) => cell ? `View List of Proxies with meeting date ${dateToString(row.meetingDate, 2023)}` : '',
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilterMeetingDate2(row.meetingDate, 2023)} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount2022',
				text: 'Proxies 2022',
				sort: true,
				align: "right",
				headerTitle: () => '# Proxies with Meeting Date in 2022',
				title: (cell: any, row: any) => cell ? `View List of Proxies with meeting date ${dateToString(row.meetingDate, 2022)}` : '',
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilterMeetingDate2(row.meetingDate, 2022)} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount2021',
				text: 'Proxies 2021',
				sort: true,
				align: "right",
				headerTitle: () => '# Proxies with Meeting Date in 2021',
				title: (cell: any, row: any) => cell ? `View List of Proxies with meeting date ${dateToString(row.meetingDate, 2021)}` : '',
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilterMeetingDate2(row.meetingDate, 2021)} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount2020',
				text: 'Proxies 2020',
				sort: true,
				align: "right",
				headerTitle: () => '# Proxies with Meeting Date in 2020',
				title: (cell: any, row: any) => cell ? `View List of Proxies with meeting date ${dateToString(row.meetingDate, 2020)}` : '',
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilterMeetingDate2(row.meetingDate, 2020)} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount2019',
				text: 'Proxies 2019',
				sort: true,
				align: "right",
				headerTitle: () => '# Proxies with Meeting Date in 2019',
				title: (cell: any, row: any) => cell ? `View List of Proxies with meeting date ${dateToString(row.meetingDate, 2019)}` : '',
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilterMeetingDate2(row.meetingDate, 2019)} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'annual',
				text: 'Annual',
				sort: true,
				align: "right",
				headerTitle: () => '# Annual Meeting Proxies with Meeting Date',
				title: (cell: any, row: any) => `View List of Annual Meeting Proxies with meeting date ${dateToMDString(row.meetingDate)}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilterMeetingDate(row.meetingDate, "Annual")} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'special',
				text: 'Special',
				sort: true,
				align: "right",
				headerTitle: () => '# Special Meeting Proxies with Meeting Date',
				title: (cell: any, row: any) => `View List of Special Meeting Proxies with meeting date ${dateToMDString(row.meetingDate)}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilterMeetingDate(row.meetingDate, "Special")} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'routine',
				text: 'Routine',
				sort: true,
				align: "right",
				headerTitle: () => '# Routine Meeting Proxies with Meeting Date',
				title: (cell: any, row: any) => `View List of Routine Meeting Proxies with meeting date ${dateToMDString(row.meetingDate)}`,
				//formatter: (cell: any) => formatNumber(cell),
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ routine: "R", meetingDate: row.meetingDate })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'nonRoutine',
				text: 'Non-Routine',
				sort: true,
				align: "right",
				headerTitle: () => '# Non-Routine Meeting Proxies with Meeting Date',
				title: (cell: any, row: any) => `View List of Non-Routine Meeting Proxies with meeting date ${dateToMDString(row.meetingDate)}`,
				//formatter: (cell: any) => formatNumber(cell),
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ routine: "N", meetingDate: row.meetingDate })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'openEnd',
				text: 'Open-end',
				sort: true,
				align: "center",
				headerTitle: () => '# Proxies for Open-end Investment by a Solicitor',
				title: (cell: any, row: any) => `View List of Proxies with meeting date ${dateToMDString(row.meetingDate)} for Open-end Investment`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ openOrClosedCIK: "0", meetingDate: row.meetingDate })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'closedEnd',
				text: 'Closed-end',
				sort: true,
				align: "center",
				headerTitle: () => '# Proxies for Closed-end Investment by a Solicitor',
				title: (cell: any, row: any) => `View List of Proxies with meeting date ${dateToMDString(row.meetingDate)} for Closed-end Investment`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ openOrClosedCIK: "1", meetingDate: row.meetingDate })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'etf',
				text: 'ETF',
				sort: true,
				align: "center",
				headerTitle: () => '# Proxies for ETFs by a Solicitor',
				title: (cell: any, row: any) => `View List of Proxies with meeting date ${dateToMDString(row.meetingDate)} for ETFs`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ openOrClosedCIK: "2", meetingDate: row.meetingDate })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			}
		];

		return (
			<ToolkitProvider
				keyField="meetingDay"
				//data={this.props.reportData && this.props.reportData.meetingDays ? this.props.reportData.meetingDays : []}
				data={data}
				columns={columns}
			>{
					props => (
						<div>
							<ul className="nav nav-tabs mb-2">
								<li className="nav-item">
									<Link to="/report/upcomingmeetings" className={upcomingClass} aria-current="page">Upcoming Meetings</Link>
								</li>
								<li className="nav-item">
									<Link to="/report/pastmeetings" className={pastClass}>Past Meetings</Link>
								</li>
								<li className="nav-item">
									<Link to="/report/meetingdates" className={allClass}>All Meetings</Link>
								</li>
							</ul>
							<BootstrapTable
								{...props.baseProps}
								bootstrap4={true}
								striped={true}
								bordered={false}
								headerClasses="table-header-class"
								classes={"unset-width"}
								noDataIndication={this.emptyDataMessage}
								hover
								condensed
								defaultSorted={[{
									dataField: 'meetingDate', // if dataField is not match to any column you defined, it will be ignored.
									order: 'asc' // desc or asc
								}]}
							/>
							{/*<p className="text-secondary small">* Routine/Non-Routine data is for year 2023 only</p>*/}
						</div>
					)
				}
			</ToolkitProvider>
		);
	}

	private renderMeetingTypeProxyCountsTable() {
		var columns = [
			{
				dataField: 'meetingType',
				text: 'Type',
				sort: true,
				headerStyle: () => { return { width: '70px' }; },
				headerTitle: () => 'Proxy Meeting Type',
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount',
				text: 'Proxies',
				sort: true,
				align: "right",
				headerTitle: () => '# Proxies with Meeting Type',
				title: (cell: any, row: any) => `View List of Proxies with ${row.meetingType} Meeting`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/proxies" onClick={() => this.setProxyFilterMeetingType(row.meetingType)} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount24',
				text: 'Proxies 2024',
				sort: true,
				align: "right",
				headerTitle: () => '# Proxies with Meeting Type in 2024',
				title: (cell: any, row: any) => `View List of Proxies with ${row.meetingType} Meeting in 2024`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/proxies" onClick={() => this.setProxyFilterMeetingType(row.meetingType, "2024")} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount23',
				text: 'Proxies 2023',
				sort: true,
				align: "right",
				headerTitle: () => '# Proxies with Meeting Type in 2023',
				title: (cell: any, row: any) => `View List of Proxies with ${row.meetingType} Meeting in 2023`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/proxies" onClick={() => this.setProxyFilterMeetingType(row.meetingType, "2023")} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount22',
				text: 'Proxies 2022',
				sort: true,
				align: "right",
				headerTitle: () => '# Proxies with Meeting Type in 2022',
				title: (cell: any, row: any) => `View List of Proxies with ${row.meetingType} Meeting in 2022`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/proxies" onClick={() => this.setProxyFilterMeetingType(row.meetingType, "2022")} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount21',
				text: 'Proxies 2021',
				sort: true,
				align: "right",
				headerTitle: () => '# Proxies with Meeting Type in 2021',
				title: (cell: any, row: any) => `View List of Proxies with ${row.meetingType} Meeting in 2021`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/proxies" onClick={() => this.setProxyFilterMeetingType(row.meetingType, "2021")} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount20',
				text: 'Proxies 2020',
				sort: true,
				align: "right",
				headerTitle: () => '# Proxies with Meeting Type in 2020',
				title: (cell: any, row: any) => `View List of Proxies with ${row.meetingType} Meeting in 2020`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/proxies" onClick={() => this.setProxyFilterMeetingType(row.meetingType, "2020")} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount19',
				text: 'Proxies 2019',
				sort: true,
				align: "right",
				headerTitle: () => '# Proxies with Meeting Type in 2019',
				title: (cell: any, row: any) => `View List of Proxies with ${row.meetingType} Meeting in 2019`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/proxies" onClick={() => this.setProxyFilterMeetingType(row.meetingType, "2019")} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'routine',
				text: 'Routine',
				sort: true,
				align: "right",
				headerTitle: () => '# Routine Meeting Proxies with Meeting Type',
				title: (cell: any, row: any) => `View List of Routine ${row.meetingType} Meeting Proxies`,
				//formatter: (cell: any) => formatNumber(cell),
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ routine: "R", meetingType: row.meetingType })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'nonRoutine',
				text: 'Non-Routine',
				sort: true,
				align: "right",
				headerTitle: () => '# Non-Routine Meeting Proxies with Meeting Type',
				title: (cell: any, row: any) => `View List of Non-Routine ${row.meetingType} Meeting Proxies`,
				//formatter: (cell: any) => formatNumber(cell),
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ routine: "N", meetingType: row.meetingType })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'openEnd',
				text: 'Open-end',
				sort: true,
				align: "center",
				headerTitle: () => '# Proxies for Open-end Investment by a Solicitor',
				title: (cell: any, row: any) => `View List of ${row.meetingType} Meeting Proxies for Open-end Investment`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ openOrClosedCIK: "0", meetingType: row.meetingType })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'closedEnd',
				text: 'Closed-end',
				sort: true,
				align: "center",
				headerTitle: () => '# Proxies for Closed-end Investment by a Solicitor',
				title: (cell: any, row: any) => `View List of ${row.meetingType} Meeting Proxies for Closed-end Investment`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ openOrClosedCIK: "1", meetingType: row.meetingType })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'etf',
				text: 'ETF',
				sort: true,
				align: "center",
				headerTitle: () => '# Proxies for ETFs by a Solicitor',
				title: (cell: any, row: any) => `View List of ${row.meetingType} Meeting Proxies for ETFs`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilter({ openOrClosedCIK: "2", meetingType: row.meetingType })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			}
		];

		return (
			<ToolkitProvider
				keyField="meetingType"
				data={this.props.reportData && this.props.reportData.reportData6 ? this.props.reportData.reportData6 : []}
				columns={columns}
			>{
					props => (
						<div>
							<p>Meeting Types</p>
							<BootstrapTable
								{...props.baseProps}
								bootstrap4={true}
								striped={true}
								bordered={false}
								headerClasses="table-header-class"
								classes={"unset-width"}
								noDataIndication={this.emptyDataMessage}
								hover
								condensed
								defaultSorted={[{
									dataField: 'meetingType', // if dataField is not match to any column you defined, it will be ignored.
									order: 'asc' // desc or asc
								}]}
							/>
							{/*<p className="text-secondary small">* Routine/Non-Routine data is for year 2023 only</p>*/}
						</div>
					)
				}
			</ToolkitProvider>
		);
	}

	private renderFamilyProxyCountsTable() {
		var columns = [
			{
				dataField: 'name',
				text: 'Name',
				sort: true,
				headerStyle: () => { return { width: '70px' }; },
				headerTitle: () => 'Family',
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/companies" onClick={() => this.setProxyFilter({ family: row.name })} className="mr-4">{cell}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount',
				text: 'Proxies',
				sort: true,
				align: "right",
				headerTitle: () => '# Proxies with Family',
				title: (cell: any, row: any) => `View List of Proxies for ${row.name}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/proxies" onClick={() => this.setProxyFilter({ family: row.name})} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount24',
				text: 'Proxies 2024',
				sort: true,
				align: "right",
				headerTitle: () => '# Proxies with Family in 2024',
				title: (cell: any, row: any) => `View List of Proxies for ${row.name} in 2024`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/proxies" onClick={() => this.setProxyFilter({ family: row.name, year: "2024" })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount23',
				text: 'Proxies 2023',
				sort: true,
				align: "right",
				headerTitle: () => '# Proxies with Family in 2023',
				title: (cell: any, row: any) => `View List of Proxies for ${row.name} in 2023`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/proxies" onClick={() => this.setProxyFilter({ family: row.name, year: "2023" })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount22',
				text: 'Proxies 2022',
				sort: true,
				align: "right",
				headerTitle: () => '# Proxies with Family in 2022',
				title: (cell: any, row: any) => `View List of Proxies for ${row.name} in 2022`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/proxies" onClick={() => this.setProxyFilter({ family: row.name, year: "2022" })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount21',
				text: 'Proxies 2021',
				sort: true,
				align: "right",
				headerTitle: () => '# Proxies with Family in 2021',
				title: (cell: any, row: any) => `View List of Proxies for ${row.name} in 2021`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/proxies" onClick={() => this.setProxyFilter({ family: row.name, year: "2021" })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount20',
				text: 'Proxies 2020',
				sort: true,
				align: "right",
				headerTitle: () => '# Proxies with Family in 2020',
				title: (cell: any, row: any) => `View List of Proxies for ${row.name} in 2020`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/proxies" onClick={() => this.setProxyFilter({ family: row.name, year: "2020" })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount19',
				text: 'Proxies 2019',
				sort: true,
				align: "right",
				headerTitle: () => '# Proxies with Family in 2019',
				title: (cell: any, row: any) => `View List of Proxies for ${row.name} in 2019`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/proxies" onClick={() => this.setProxyFilter({ family: row.name, year: "2019" })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'dateFiled',
				text: 'Last Filed',
				sort: true,
				align: "right",
				headerAlign: 'right',
				headerStyle: { width: '100px' },
				headerTitle: () => 'Date of Last Proxy Filing Filed on SEC EDGAR',
				//title: () => 'Date Filed on SEC EDGAR',
				formatter: (cell: any) => dateToString(cell),
				csvFormatter: (cell: any) => dateToString(cell)
			}
		];

		return (
			<ToolkitProvider
				keyField="name"
				data={this.props.reportData && this.props.reportData.reportData7 ? this.props.reportData.reportData7 : []}
				columns={columns}
			>{
					props => (
						<div>
							<p>{this.props.reportData && this.props.reportData.reportData7 && this.props.reportData.reportData7.length ? formatNumber(this.props.reportData.reportData7.length) + " " : ""}
								Fund Families that filed Proxy Filing on/after 1/1/2019.</p>
							<BootstrapTable
								{...props.baseProps}
								bootstrap4={true}
								striped={true}
								bordered={false}
								headerClasses="table-header-class"
								classes={"unset-width"}
								noDataIndication={this.emptyDataMessage}
								hover
								condensed
								defaultSorted={[{
									dataField: 'proxyCount', // if dataField is not match to any column you defined, it will be ignored.
									order: 'desc' // desc or asc
								}]}
							/>
							{/*<p className="text-secondary small">* Routine/Non-Routine data is for year 2023 only</p>*/}
						</div>
					)
				}
			</ToolkitProvider>
		);
	}

	private renderFamilyOwnershipCountsTable() {
		var columns = [
			{
				dataField: 'name',
				text: 'Name',
				sort: true,
				headerStyle: () => { return { width: '70px' }; },
				headerTitle: () => 'Family',
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/ownership-companies" onClick={() => this.setProxyFilter({ family: row.name })} className="mr-4">{cell}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'ownershipCount',
				text: 'Filings',
				sort: true,
				align: "right",
				headerTitle: () => '# Ownership Filings with Family',
				title: (cell: any, row: any) => `View List of Ownership Filings for ${row.name}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/ownership-filings" onClick={() => this.setProxyFilter({ family: row.name })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'ownershipCount24',
				text: 'Filings 2024',
				sort: true,
				align: "right",
				headerTitle: () => '# Ownership Filings with Family in 2024',
				title: (cell: any, row: any) => `View List of Ownership Filings for ${row.name} in 2024`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/ownership-filings" onClick={() => this.setProxyFilter({ family: row.name, year: "2024" })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'ownershipCount23',
				text: 'Filings 2023',
				sort: true,
				align: "right",
				headerTitle: () => '# Ownership Filings with Family in 2023',
				title: (cell: any, row: any) => `View List of Ownership Filings for ${row.name} in 2023`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/ownership-filings" onClick={() => this.setProxyFilter({ family: row.name, year: "2023" })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'ownershipCount22',
				text: 'Filings 2022',
				sort: true,
				align: "right",
				headerTitle: () => '# Ownership Filings with Family in 2022',
				title: (cell: any, row: any) => `View List of Ownership Filings for ${row.name} in 2022`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/ownership-filings" onClick={() => this.setProxyFilter({ family: row.name, year: "2022" })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'ownershipCount21',
				text: 'Filings 2021',
				sort: true,
				align: "right",
				headerTitle: () => '# Ownership Filings with Family in 2021',
				title: (cell: any, row: any) => `View List of Ownership Filings for ${row.name} in 2021`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/ownership-filings" onClick={() => this.setProxyFilter({ family: row.name, year: "2021" })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'ownershipCount20',
				text: 'Filings 2020',
				sort: true,
				align: "right",
				headerTitle: () => '# Ownership Filings with Family in 2020',
				title: (cell: any, row: any) => `View List of Ownership Filings for ${row.name} in 2020`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/ownership-filings" onClick={() => this.setProxyFilter({ family: row.name, year: "2020" })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'ownershipCount19',
				text: 'Filings 2019',
				sort: true,
				align: "right",
				headerTitle: () => '# Ownership Filings with Family in 2019',
				title: (cell: any, row: any) => `View List of Ownership Filings for ${row.name} in 2019`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to="/ownership-filings" onClick={() => this.setProxyFilter({ family: row.name, year: "2019" })} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'ownershipDateFiled',
				text: 'Last Filed',
				sort: true,
				align: "right",
				headerAlign: 'right',
				headerStyle: { width: '100px' },
				headerTitle: () => 'Date of Last Ownership Filing Filed on SEC EDGAR',
				//title: () => 'Date Filed on SEC EDGAR',
				formatter: (cell: any) => dateToString(cell),
				csvFormatter: (cell: any) => dateToString(cell)
			}
		];

		return (
			<ToolkitProvider
				keyField="name"
				data={this.props.reportData && this.props.reportData.reportData7 ? this.props.reportData.reportData7 : []}
				columns={columns}
			>{
					props => (
						<div>
							<p>{this.props.reportData && this.props.reportData.reportData7 && this.props.reportData.reportData7.length ? formatNumber(this.props.reportData.reportData7.length) + " " : ""}
								Fund Families that filed Ownership Filing on/after 1/1/2019.</p>
							<BootstrapTable
								{...props.baseProps}
								bootstrap4={true}
								striped={true}
								bordered={false}
								headerClasses="table-header-class"
								classes={"unset-width"}
								noDataIndication={this.emptyDataMessage}
								hover
								condensed
								defaultSorted={[{
									dataField: 'proxyCount', // if dataField is not match to any column you defined, it will be ignored.
									order: 'desc' // desc or asc
								}]}
							/>
							{/*<p className="text-secondary small">* Routine/Non-Routine data is for year 2023 only</p>*/}
						</div>
					)
				}
			</ToolkitProvider>
		);
	}

	private setProxyFilterCik(cik: number) {
		this.context.setProxyFilter(cik.toString(), "", "", "", "", "");
		//this.props.history.push('/proxies');
		//e.preventDefault();
	}

	private setProxyFilterYear(year: number) {
		this.context.setProxyFilter("", "", year.toString(), "", "", "");
	}

	private setProxyFilterSolicitor(solicitor: string, year: string = "") {
		this.context.setProxyFilter("", "", year, solicitor, "", "");
	}

	private setProxyFilterSolicitorMeetingType(solicitor: string, meetingType: string) {
		this.context.setProxyFilter("", "", "", solicitor, meetingType, "");
	}

	private setProxyFilterMeetingDate(meetingDate: Date, meetingType: string = "") {
		this.context.setProxyFilter("", "", "", "", meetingType, DateToJSON(new Date(meetingDate)));
	}

	private setProxyFilterMeetingDate2(meetingDate: Date, year: number) {
		meetingDate = new Date(year, meetingDate.getMonth(), meetingDate.getDate());
		this.context.setProxyFilter("", "", "", "", "", DateToJSON(meetingDate));
	}

	private setProxyFilterMeetingType(meetingType: string, year: string = "") {
		this.context.setProxyFilter("", "", year, "", meetingType, "");
	}

	private setProxyFilter({ cik = '', family = '', year = '', solicitor = '', meetingType = '', meetingDate = '', routine = '', openOrClosedCIK = '' }: ProxyFilterParameters) {
		if (meetingDate) {
			meetingDate = (typeof meetingDate === "string") ? DateToJSON(parseISO(meetingDate)) : DateToJSON(meetingDate);
		}
		this.context.setProxyFilter(cik, family, year, solicitor, meetingType, meetingDate, routine, openOrClosedCIK);
	}
}

export default connect(
	(state: ApplicationState) => state.reportState, // Selects which state properties are merged into the component's props
	ReportStore.actionCreators // Selects which action creators are merged into the component's props
)(Report as any); // eslint-disable-line @typescript-eslint/no-explicit-any
