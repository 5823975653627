import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { fetchWithAuth } from './Util';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ProxyDataState {
	isLoading: boolean;
	fund?: string;
	data: ProxyFilingData;
	filingText?: string;
}

export interface ProxyFilingData {
	san?: string;
	formType?: string;
	dateFiled?: Date;
	fileName?: string;
	fileSize?: number;
	reportDate?: Date;
	series?: number;
	classes?: number;
	funds?: string;
	url?: string;
	description?: string;
	recordDate?: Date;
	meetingDate?: Date;
	meetingType?: string;
	routine?: string;
	cost?: string;
	costValue?: number;
	costPaidBy?: string;
	solicitor?: string;
	solicitorKey?: string;
	fundCounsel?: string;
	proposal1?: string;
	proposal2?: string;
	proposal3?: string;
	proposal4?: string;
	proposal5?: string;
	proposal6?: string;
	proposal7?: string;
	note?: string;
	recordDateStartOffset?: number;
	recordDateEndOffset?: number;
	meetingDateStartOffset?: number;
	meetingDateEndOffset?: number;
	meetingTypeStartOffset?: number;
	meetingTypeEndOffset?: number;
	routineStartOffset?: number;
	routineEndOffset?: number;
	costStartOffset?: number;
	costEndOffset?: number;
	costPaidByStartOffset?: number;
	costPaidByEndOffset?: number;
	solicitorStartOffset?: number;
	solicitorEndOffset?: number;
	fundCounselStartOffset?: number;
	fundCounselEndOffset?: number;
	proposal1StartOffset?: number;
	proposal1EndOffset?: number;
	proposal2StartOffset?: number;
	proposal2EndOffset?: number;
	proposal3StartOffset?: number;
	proposal3EndOffset?: number;
	proposal4StartOffset?: number;
	proposal4EndOffset?: number;
	proposal5StartOffset?: number;
	proposal5EndOffset?: number;
	proposal6StartOffset?: number;
	proposal6EndOffset?: number;
	proposal7StartOffset?: number;
	proposal7EndOffset?: number;
	noteStartOffset?: number;
	noteEndOffset?: number;
	documentFileNameStartOffset?: number;
	documentFileNameEndOffset?: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestProxyDataAction {
	type: 'REQUEST_PROXY_DATA';
	fund?: string;
}

interface ReceiveProxyDataAction {
	type: 'RECEIVE_PROXY_DATA';
	fund?: string;
	data: ProxyFilingData;
	filingText?: string;
}

interface ResetStateAction {
	type: 'RESET_STATE';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestProxyDataAction | ReceiveProxyDataAction | ResetStateAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const proxyFilingDataActionCreators = {
	requestProxyData: (fund?: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Only load data if it's something we don't already have (and are not already loading)
		const appState = getState();
		if (appState && appState.proxyFilingData && fund !== appState.proxyFilingData.fund) {
			fetchWithAuth({ url: `api/edgar/proxy`, queryParams: {san: fund } }) // api/Edgar/Fmaster
				.then(response => {
					return response.json() as Promise<ProxyFilingData>;
				})
				.then(data => {
					//var filingUrl = "https://www.sec.gov/Archives/" + data.filename;
					//debugger;
					//dispatch({ type: 'RECEIVE_PROXY_DATA', fund: fund, data: data, filingText: filingUrl });
					fetchWithAuth({ url: `api/edgar/file`, queryParams: { san: data.san } })
						.then(response => {
							return response.text();
						})
						.then(dataFile => {
							//debugger;
							dispatch({ type: 'RECEIVE_PROXY_DATA', fund: fund, data: data, filingText: dataFile});
						})
						.catch(error => {
							console.error('There was an error!', error);
						});
				})
				.catch(error => {
					console.error('There was an error!', error);
				});

			dispatch({ type: 'REQUEST_PROXY_DATA', fund: fund });
		}
	},
	resetState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Reset State
		dispatch({ type: 'RESET_STATE' });
	}
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const proxyFilingDataUnloadedState: ProxyDataState = { data: {}, isLoading: false };

export const reducer: Reducer<ProxyDataState> = (state: ProxyDataState | undefined, incomingAction: Action): ProxyDataState => {
	if (state === undefined) {
		return proxyFilingDataUnloadedState;
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'RESET_STATE':
			return {
				fund: '',
				data: {},
				isLoading: false
			};
		case 'REQUEST_PROXY_DATA':
			return {
				fund: action.fund,
				data: state.data,
				isLoading: true
			};
		case 'RECEIVE_PROXY_DATA':
			// Only accept the incoming data if it matches the most recent request. This ensures we correctly
			// handle out-of-order responses.
			if (action.fund === state.fund) {
				return {
					fund: action.fund,
					data: action.data,
					filingText: action.filingText,
					isLoading: false
				};
			}
			break;
	}

	return state;
};
