import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { fetchWithAuth } from './Util';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface FilingSeriesClassesState {
	isLoading: boolean;
	san?: string;
	dataType?: number;
	sort?: string;
	direction?: string;
	filingSeriesClasses: FilingSeriesClass[];
}

export interface FilingSeriesClass {
	san?: string;
	cik?: number;
	seriesId?: string;
	seriesName?: string;
	classId?: string;
	className?: string;
	ticker?: string;
	classes?: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestFilingSeriesClassesAction {
	type: 'REQUEST_FILING_SERIES_CLASSES';
	san?: string;
	dataType?: number;
}

interface ReceiveFilingSeriesClassesAction {
	type: 'RECEIVE_FILING_SERIES_CLASSES';
	san?: string;
	dataType?: number;
	filingSeriesClasses: FilingSeriesClass[];
}

interface ResetStateAction {
	type: 'RESET_STATE';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestFilingSeriesClassesAction | ReceiveFilingSeriesClassesAction | ResetStateAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
	// san: SAN or CIK or Series ID
	// dataType: 0: CIK Classes, 1: CIK Funds, 2: SAN Classes, 3: SAN Funds, 4: Series Classes
	requestFilingSeriesClasses: (san?: string, dataType?: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Only load data if it's something we don't already have (and are not already loading)
		const appState = getState();
		if (appState && appState.filingSeriesClasses && (san !== appState.filingSeriesClasses.san || dataType !== appState.filingSeriesClasses.dataType)) {
			fetchWithAuth({ url: 'api/edgar/funds', queryParams: { san: san, datatype: dataType } })
				.then(response => response.json() as Promise<FilingSeriesClass[]>)
				.then(data => {
					dispatch({ type: 'RECEIVE_FILING_SERIES_CLASSES', san: san, dataType: dataType , filingSeriesClasses: data });
				})
				.catch(error => {
					console.error('There was an error!', error);
				});

			dispatch({ type: 'REQUEST_FILING_SERIES_CLASSES', san: san, dataType: dataType });
		}
	},
	resetState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Reset State
		dispatch({ type: 'RESET_STATE' });
	}
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedStateFilingSeriesClasses: FilingSeriesClassesState = { filingSeriesClasses: [], isLoading: false };

export const reducer: Reducer<FilingSeriesClassesState> = (state: FilingSeriesClassesState | undefined, incomingAction: Action): FilingSeriesClassesState => {
	if (state === undefined) {
		return unloadedStateFilingSeriesClasses;
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'RESET_STATE':
			return {
				san: '',
				dataType: 0,
				filingSeriesClasses: [],
				isLoading: false
			};
		case 'REQUEST_FILING_SERIES_CLASSES':
			return {
				san: action.san,
				dataType: action.dataType,
				filingSeriesClasses: state.filingSeriesClasses,
				isLoading: true
			};
		case 'RECEIVE_FILING_SERIES_CLASSES':
			// Only accept the incoming data if it matches the most recent request. This ensures we correctly
			// handle out-of-order responses.
			if (action.san === state.san && action.dataType === state.dataType) {
				return {
					san: action.san,
					dataType: action.dataType,
					filingSeriesClasses: action.filingSeriesClasses,
					isLoading: false
				};
			}
			break;
	}

	return state;
};
