import { React, Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { FaUser, FaUserAlt, FaUserCircle, FaUserEdit, FaRegUser, FaRegUserCircle } from 'react-icons/fa';
import { getUserProfile } from '../../store/Util';

export class LoginMenu extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isAuthenticated: false,
			userName: null,
			user: null
		};
	}

	componentDidMount() {
		this._subscription = authService.subscribe(() => this.populateState());
		this.populateState();
	}

	componentWillUnmount() {
		authService.unsubscribe(this._subscription);
	}

	async populateState() {
		const [isAuthenticated, user, userProfile] = await Promise.all([authService.isAuthenticated(), authService.getUser(), getUserProfile()])
		this.setState({
			isAuthenticated,
			userName: user && user.name,
			user: userProfile || user
		});
	}

	hasUserRole(role) {
		if (this.state && this.state.user && this.state.user.role && this.state.user.role.length > 0) {
			return (this.state.user.role || []).includes(role);
		}
		return false;
	}

	userClaimValue = (claim) => {
		if (this.state && this.state.user && this.state.user[claim]) {
			return this.state.user[claim];
		}
		return undefined;
	}

	render() {
		const { isAuthenticated, userName } = this.state;
		if (!isAuthenticated) {
			const registerPath = `${ApplicationPaths.Register}`;
			const loginPath = `${ApplicationPaths.Login}`;
			return this.anonymousView(registerPath, loginPath);
		} else {
			const profilePath = `${ApplicationPaths.Profile}`;
			const preferencesPath = `${ApplicationPaths.Preferences}`;
			const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
			return this.authenticatedView(userName, profilePath, preferencesPath, logoutPath);
		}
	}

	// Menu after Login for Authenticated User
	authenticatedView(userName, profilePath, preferencesPath, logoutPath) {
		return (<Fragment>
			<NavItem>
				<NavLink tag={Link} className="text-light" to="/dashboard" title="Dashboard">Dashboard</NavLink>
			</NavItem>
			<NavItem>
				{/*<NavLink tag={Link} className="text-light" to="/companies" title="Fund Companies">Companies</NavLink>*/}
				<NavLink tag={Link} className="text-light" to="/report/families" title="Fund Families Filing Proxies">Fund Families</NavLink>
			</NavItem>
			<NavItem>
				<NavLink tag={Link} className="text-light" to="/proxies" title="Proxy Filings">Proxies</NavLink>
			</NavItem>
			<NavItem>
				<NavLink tag={Link} className="text-light" to="/solicitors" title="Proxy Solicitors">Solicitors</NavLink>
			</NavItem>
			{/*<NavItem>
				<NavLink tag={Link} className="text-light" to="/ownership-filings" title="Ownership Filings">Ownership</NavLink>
			</NavItem>*/}
			<NavItem className="dropdown">
				<a href="#" className="nav-link dropdown-toggle text-light" id="navbarDropdown" data-toggle="dropdown" aria-expanded="false">
					Ownership <b className="caret"></b>
				</a>
				<div className="dropdown-menu dropdown-menu-left">
					<NavLink tag={Link} className="text-dark dropdown-item" to="/ownership-filings" title="Ownership Filings">Filings</NavLink>
					<NavLink tag={Link} className="text-dark dropdown-item" to="/ownership-companies" title="Ownership Funds">Funds</NavLink>
					<NavLink tag={Link} className="text-dark dropdown-item" to="/report/ownership-families" title="Fund Families">Fund Families</NavLink>
				</div>
			</NavItem>
			<NavItem className="dropdown">
				<a href="#" className="nav-link dropdown-toggle text-light" id="navbarDropdown" data-toggle="dropdown" aria-expanded="false">
					Reports <b className="caret"></b>
				</a>
				<div className="dropdown-menu dropdown-menu-right">
					<NavLink tag={Link} className="text-dark dropdown-item" to="/report/upcomingmeetings" title="Upcoming Proxy Meetings">Upcoming Meetings</NavLink>
					<NavLink tag={Link} className="text-dark dropdown-item" to="/report/meetingtypes" title="Proxy Meeting Types">Meeting Types</NavLink>
					<NavLink tag={Link} className="text-dark dropdown-item" to="/report/years" title="Proxy Filing Years">Proxies by Year</NavLink>
					<NavLink tag={Link} className="text-dark dropdown-item" to="/companies" title="Fund Companies Filing Proxies">Proxies by Company</NavLink>
					{/*<NavLink tag={Link} className="text-dark dropdown-item" to="/report/families" title="Fund Families Filing Proxies">Proxies by Family</NavLink>*/}
				</div>
			</NavItem>
			{/*{this.hasUserRole("Admin") || this.hasUserRole("SuperAdmin") ? this.adminMenu() : <></>}*/}
			<NavItem className="dropdown">
				<a href="#" className="nav-link dropdown-toggle text-light" id="navbarDropdown" data-toggle="dropdown" aria-expanded="false" title="Profile">
					<FaUser />{/*<FaUserAlt /> <FaRegUser /> <FaRegUserCircle /> <FaUserEdit /> <FaUserCircle />*/}<b className="caret"></b>
				</a>
				<div className="dropdown-menu dropdown-menu-right">
					<NavLink tag={Link} className="text-dark dropdown-item" to={profilePath}>Hello {userName}</NavLink>
					<NavLink tag={Link} className="text-dark dropdown-item" to={preferencesPath}>Preferences</NavLink>
					{this.hasUserRole("SuperAdmin") ?
						<NavLink tag={Link} className="text-dark dropdown-item" to="/admin/connected-users" title="Connected Users">Connected Users</NavLink>
						: <></>}
					<NavLink tag={Link} className="text-dark dropdown-item" to={logoutPath}>Logout</NavLink>
				</div>
			</NavItem>
		</Fragment>);
	}

	adminMenu() {
		return (<Fragment>
			<NavItem className="dropdown">
				<a href="#" className="nav-link dropdown-toggle text-light" id="navbarDropdown" data-toggle="dropdown" aria-expanded="false">
					Admin <b className="caret"></b>
				</a>
				{this.hasUserRole("SuperAdmin") ?
					<div className="dropdown-menu dropdown-menu-right">
						<NavLink tag={Link} className="text-dark dropdown-item" to="/admin/connected-users" title="Connected Users">Connected Users</NavLink>
					</div> : <></>}
			</NavItem>
		</Fragment>);
	}

	// Menu before Login
	anonymousView(registerPath, loginPath) {
		return (<Fragment>
			{/*<NavItem>*/}
			{/*	<NavLink tag={Link} className="text-light" to={registerPath}>Register</NavLink>*/}
			{/*</NavItem>*/}
			<NavItem>
				<NavLink tag={Link} className="text-light" to={loginPath}>Login</NavLink>
			</NavItem>
		</Fragment>);
	}
}
