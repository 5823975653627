import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { fetchWithAuth } from './Util';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface OwnershipFilingsState {
	isLoading: boolean;
	cik?: string;
	year?: string;
	sort?: string;
	ownershipFilings: OwnershipFiling[];
}

export interface OwnershipFiling {
	san: string;
	dateFiled: Date;
	formType: string;
	fileName: string;
	fileSize: number;
	subjectCompany: string;
	filedBy: string;
	groupMembers: string;
	description?: string;
	documentFileName: string;
	cusiPs?: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestOwnershipFilingsAction {
	type: 'REQUEST_OWNERSHIP_FILINGS';
	cik?: string;
	year?: string;
}

interface ResetStateAction {
	type: 'RESET_STATE';
}

interface ReceiveOwnershipFilingsAction {
	type: 'RECEIVE_OWNERSHIP_FILINGS';
	cik?: string;
	year?: string;
	ownershipFilings: OwnershipFiling[];
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestOwnershipFilingsAction | ReceiveOwnershipFilingsAction | ResetStateAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
	requestOwnershipFilings: (cik: string = "", year: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Only load data if it's something we don't already have (and are not already loading)
		const appState = getState();
		if (appState && appState.ownershipFilings && (cik != appState.ownershipFilings.cik
			|| year != appState.ownershipFilings.year)) {
			fetchWithAuth({ url: `api/edgar/ownership-filings`, queryParams: { cik, year } })
				.then(response => response.json() as Promise<OwnershipFiling[]>)
				.then(data => {
					//debugger;
					dispatch({ type: 'RECEIVE_OWNERSHIP_FILINGS', cik, year, ownershipFilings: data });
				})
				.catch(error => {
					console.error('There was an error!', error);
				});

			dispatch({ type: 'REQUEST_OWNERSHIP_FILINGS', cik, year });
		}
	},
	resetState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Reset State
		dispatch({ type: 'RESET_STATE' });
	}
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedStateOwnershipFilings: OwnershipFilingsState = { ownershipFilings: [], isLoading: false };

export const reducer: Reducer<OwnershipFilingsState> = (state: OwnershipFilingsState | undefined, incomingAction: Action): OwnershipFilingsState => {
	if (state === undefined) {
		return unloadedStateOwnershipFilings;
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'RESET_STATE':
			return {
				cik: undefined,
				year: undefined,
				ownershipFilings: [],
				isLoading: false
			};
		case 'REQUEST_OWNERSHIP_FILINGS':
			return {
				cik: action.cik,
				year: action.year,
				ownershipFilings: state.ownershipFilings, // TODO: Change to []?
				isLoading: true
			};
		case 'RECEIVE_OWNERSHIP_FILINGS':
			// Only accept the incoming data if it matches the most recent request. This ensures we correctly
			// handle out-of-order responses.
			if (action.cik == state.cik && action.year == state.year) {
				return {
					cik: action.cik,
					year: action.year,
					ownershipFilings: action.ownershipFilings,
					isLoading: false
				};
			}
			break;
	}

	return state;
};
