import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { fetchWithAuth } from './Util';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface FilingsState {
	isLoading: boolean;
	fund?: string;
	cik?: string;
	year?: string;
	solicitor?: string;
	meetingType?: string;
	meetingDate?: string;
	routine?: string;
	openOrClosedCIK?: string;
	sort?: string;
	filings: Filing[];
}

export interface Filing {
	cik?: number;
	companyName: string;
	formType: string;
	san: string;
	dateFiled: Date;
	date: string;
	funds: string;
	series?: number;
	classes?: number;
	reportDate: Date;
	fileName: string;
	documentFileName: string;
	description?: string;
	recordDate?: Date;
	meetingDate?: Date;
	meetingType?: string;
	cost?: string;
	costPaidBy?: string;
	solicitor?: string;
	solicitorKey?: string;
	fundCounsel?: string;
	proposal1?: string;
	proposal2?: string;
	proposal3?: string;
	proposal4?: string;
	proposal5?: string;
	proposal6?: string;
	proposal7?: string;
	note?: string;
	recordDateStartOffset?: number;
	recordDateEndOffset?: number;
	meetingDateStartOffset?: number;
	meetingDateEndOffset?: number;
	meetingTypeStartOffset?: number;
	meetingTypeEndOffset?: number;
	routineStartOffset?: number;
	routineEndOffset?: number;
	costStartOffset?: number;
	costEndOffset?: number;
	costPaidByStartOffset?: number;
	costPaidByEndOffset?: number;
	solicitorStartOffset?: number;
	solicitorEndOffset?: number;
	fundCounselStartOffset?: number;
	fundCounselEndOffset?: number;
	proposal1StartOffset?: number;
	proposal1EndOffset?: number;
	proposal2StartOffset?: number;
	proposal2EndOffset?: number;
	proposal3StartOffset?: number;
	proposal3EndOffset?: number;
	proposal4StartOffset?: number;
	proposal4EndOffset?: number;
	proposal5StartOffset?: number;
	proposal5EndOffset?: number;
	proposal6StartOffset?: number;
	proposal6EndOffset?: number;
	proposal7StartOffset?: number;
	proposal7EndOffset?: number;
	noteStartOffset?: number;
	noteEndOffset?: number;
	documentFileNameStartOffset?: number;
	documentFileNameEndOffset?: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestFilingsAction {
	type: 'REQUEST_FILINGS';
	fund?: string;
	cik?: string;
	year?: string;
	solicitor?: string;
	meetingType?: string;
	meetingDate?: string;
	routine?: string;
	openOrClosedCIK?: string;
}

interface ResetStateAction {
	type: 'RESET_STATE';
}

interface ReceiveFilingsAction {
	type: 'RECEIVE_FILINGS';
	fund?: string;
	cik?: string;
	year?: string;
	solicitor?: string;
	meetingType?: string;
	meetingDate?: string;
	routine?: string;
	openOrClosedCIK?: string;
	filings: Filing[];
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestFilingsAction | ReceiveFilingsAction | ResetStateAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
	requestFilings: (fund: string = "", cik: string = "", year: string = "", solicitor: string = "", meetingType: string = "", meetingDate: string = "", routine: string = "", openOrClosedCIK: string = "", classId: string = "", seriesId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Only load data if it's something we don't already have (and are not already loading)
		const appState = getState();
		if (appState && appState.filings && (fund != appState.filings.fund || cik != appState.filings.cik
			|| year != appState.filings.year || solicitor != appState.filings.solicitor
			|| meetingType != appState.filings.meetingType || meetingDate != appState.filings.meetingDate || routine != appState.filings.routine
			|| openOrClosedCIK != appState.filings.openOrClosedCIK)) {
			cik = classId != "" ? classId : seriesId != "" ? seriesId : cik;
			fetchWithAuth({ url: `api/edgar/proxies`, queryParams: { fund, cik, year, solicitor, meetingType, meetingDate, routine, openOrClosedCIK } })
				.then(response => response.json() as Promise<Filing[]>)
				.then(data => {
					dispatch({ type: 'RECEIVE_FILINGS', fund, cik, year, solicitor, meetingType, meetingDate, routine, openOrClosedCIK, filings: data });
				})
				.catch(error => {
					console.error('There was an error!', error);
				});

			dispatch({ type: 'REQUEST_FILINGS', fund, cik, year, solicitor, meetingType, meetingDate, routine, openOrClosedCIK });
		}
	},
	resetState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Reset State
		dispatch({ type: 'RESET_STATE' });
	},
	deleteProxy: (san: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Only load data if it's something we don't already have (and are not already loading)
		const appState = getState();
		if (appState && appState.filings) {
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' }
			};

			fetchWithAuth({ url: `api/edgar/delete-proxy?san=${san}` }, requestOptions)
				.then(response => {
					let data: Filing[] = (((appState || {}).filings || {}).filings || []).filter(d => d.san != san);
					dispatch({ type: 'RECEIVE_FILINGS', ...appState.filings, filings: data });
				})
				.catch(error => {
					console.error('There was an error!', error);
				});

			dispatch({ type: 'REQUEST_FILINGS', ...appState.filings });
		}
	}
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedStateProxies: FilingsState = { filings: [], isLoading: false };

export const reducer: Reducer<FilingsState> = (state: FilingsState | undefined, incomingAction: Action): FilingsState => {
	if (state === undefined) {
		return unloadedStateProxies;
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'RESET_STATE':
			return {
				fund: '',
				cik: '',
				year: '',
				solicitor: '',
				meetingType: '',
				meetingDate: '',
				routine: '',
				openOrClosedCIK: '',
				filings: [],
				isLoading: false
			};
		case 'REQUEST_FILINGS':
			return {
				fund: action.fund,
				cik: action.cik,
				year: action.year,
				solicitor: action.solicitor,
				meetingType: action.meetingType,
				meetingDate: action.meetingDate,
				routine: action.routine,
				openOrClosedCIK: action.openOrClosedCIK,
				filings: state.filings, // TODO: Change to []?
				isLoading: true
			};
		case 'RECEIVE_FILINGS':
			// Only accept the incoming data if it matches the most recent request. This ensures we correctly
			// handle out-of-order responses.
			if (action.fund == state.fund && action.cik == state.cik && action.year == state.year
				&& action.solicitor == state.solicitor && action.meetingType == state.meetingType
				&& action.meetingDate == state.meetingDate && action.routine == state.routine && action.openOrClosedCIK == state.openOrClosedCIK) {
				return {
					fund: action.fund,
					cik: action.cik,
					year: action.year,
					solicitor: action.solicitor,
					meetingType: action.meetingType,
					meetingDate: action.meetingDate,
					routine: action.routine,
					openOrClosedCIK: action.openOrClosedCIK,
					filings: action.filings,
					isLoading: false
				};
			}
			break;
	}

	return state;
};
