import React, { Component, PureComponent } from 'react';
import { ResponsiveCalendarCanvas, Calendar, ResponsiveCalendar, TimeRange, CalendarCanvas } from '@nivo/calendar'
import { ChartColors, CalendarChartColors } from '../../store/Util';
import _ from 'lodash';
import { endOfMonth, format, formatDistance, formatRelative, parseISO, startOfMonth, subDays } from 'date-fns';

// nivo Calendar chart
export class NivoCalendarChart extends Component<{ chartInfo?: any }> {
	onCalendarClick = (payload: any, e: any) => {
		if (payload.value && this.props.chartInfo.onCalendarClick && typeof this.props.chartInfo.onCalendarClick === 'function') {
			this.props.chartInfo.onCalendarClick.call(this, payload, this.props.chartInfo.key, this);
		}
	}

	// Render nivo Calendar
	public render() {
		let chartInfo = {
			height: 600,
			data: [],
			width: 1024,
			direction: 'horizontal',
			margin: { top: 40, right: 40, bottom: 40, left: 40 },
			align: 'left',
			...this.props?.chartInfo
		};
		chartInfo.from = chartInfo.from ? chartInfo.from : startOfMonth(parseISO(_.min(chartInfo.data.map((d: any) => d.day)) || '2024-01-01'));
		chartInfo.to = chartInfo.to ? chartInfo.to : endOfMonth(parseISO(_.max(chartInfo.data.map((d: any) => d.day)) || '2024-12-31'));

		let commonProps: any = {
			direction: chartInfo.direction,
			height: chartInfo.height,
			width: chartInfo.width,
			data: chartInfo.data,
			from: chartInfo.from,
			to: chartInfo.to, //chartInfo.to,
			emptyColor: "#eeeeee",
			// TODO: Color should be lighter/darker transparency based on value. Ex: Blue x value / (max-min)
			colors: CalendarChartColors,
			margin: chartInfo.margin,
			yearSpacing: 40,
			yearLegend: (year: number) => { return year <= 1980 ? '2019 - 2024' : year }, // All Meetings
			//monthBorderColor="#ffffff",
			monthSpacing: 14,
			dayBorderWidth: 2,
			dayBorderColor: "#ffffff",
			weekdayLegendOffset: 2, // Gap between Weekday Tickmarks and Day Squares
			weekdayTicks: [1, 2, 3, 4, 5], // Show All Weekday tickmarks
			onClick: (payload: any, e: any) => { this.onCalendarClick(payload, e) },
			legends: [
				{
					anchor: 'bottom-right',
					direction: 'row',
					translateY: 36,
					itemCount: 4,
					itemWidth: 42,
					itemHeight: 36,
					itemsSpacing: 14,
					itemDirection: 'right-to-left'
				}
			],
			onMouseEnter: (event: any, chartElement: any) => {
				chartElement.target.style.cursor = event.value ? 'pointer' : 'default';
			}
		}

		if (chartInfo.tooltip && typeof chartInfo.tooltip === 'function') {
			commonProps.tooltip = (props: any, e: any) => { return chartInfo.tooltip.call(this, props, e, this); }
		}

		return (<React.Fragment>
			<div>
				{this.showTitle()}
				{
					chartInfo.chartType == "TimeRange"
						? <TimeRange {...commonProps} />
						: chartInfo.chartType == "CalendarCanvas"
							? <CalendarCanvas {...commonProps} monthBorderColor="#ffffff" />
							: <Calendar {...commonProps} monthBorderColor="#ffffff" />
				}
			</div>
		</React.Fragment>
		);
	}

	private showTitle() {
		return (this.props?.chartInfo && this.props?.chartInfo.title ? <div className="d-block text-center">{this.props?.chartInfo.title}</div> : <></>)
	}

};
