//import _ from 'lodash';
import { replace, trimStart } from 'lodash';
import * as React from 'react';
import { FaEdit, FaRegEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { ApplicationPaths } from '../components/api-authorization/ApiAuthorizationConstants';
import authService from '../components/api-authorization/AuthorizeService';
import { NivoCalendarChart } from '../components/charts/nivo-calendar';
import { RCFunnelChart } from '../components/charts/rc-funnel-chart';
import { RCPieChart } from '../components/charts/rc-pie-chart';
import { RCStackedChart } from '../components/charts/rc-stacked-bar-chart';
import { UpdateLastActivityAppWS } from './AppWS';
//import * as H from 'history';

export interface ProxyFilterParameters {
	cik?: string;
	family?: string;
	year?: string;
	solicitor?: string;
	meetingType?: string;
	meetingDate?: Date | string;
	routine?: string;
	openOrClosedCIK?: string
}

export const solicitorShortNames: Map<string, string> = new Map([
	['Morrow Sodali Fund Solutions', 'MSFS']
]);

const solicitorUrls = new Map([
	["Advantage Proxy", "https://advantageproxy.com/proxy-solicitation"],
	["Alliance Advisors", "https://allianceadvisors.com/what-we-do/#shareholder-engagement-solicitation"], // https://www.allianceadvisors.com/services/proxy-solicitation
	["AST", "https://equiniti.com/us/mutual-funds/proxy-solicitation-services"], // https://www.astfinancial.com/mutual-fund-proxy-services
	["BetaNXT", "https://betanxt.com"],
	["BofA Securities", "https://business.bofa.com"],
	["Broadridge", "https://www.broadridge.com/resource/proxy-management-solutions"],
	["Campaign Management", "https://www.campaign-mgmt.com"], // Campaign Management LLC
	["Carson Proxy Advisors", "https://carsonproxy.com"],
	["ClearTrust", "https://www.cleartrustonline.com"], // ClearTrust, LLC
	["Colonial Stock Transfer", "https://www.colonialstock.com/proxy-and-annual-meeting.htm"], // Colonial Stock Transfer
	["Computershare", "https://www.computershare.com/us/business/corporate-governance-proxy-services/achieve-effective-mutual-fund-proxy-campaigns"],
	["Continental Stock Transfer & Trust", "https://continentalstock.com/products-services/shareholder-meeting-and-proxy-services"], // Continental Stock Transfer & Trust
	["DF King", "https://equiniti.com/us/mutual-funds/proxy-solicitation-services"], // https://dfkingltd.com/proxy-solicitation.html
	["Di Costa", "https://morrowsodali.com/our-services/proxy-solicitation-and-shareholder-meeting-services"], // https://dicostapartners.com/our-services
	["Donnelley", "https://www.dfinsolutions.com/solutions/proxy-fund-companies"],
	["EQ", "https://equiniti.com/us/mutual-funds/proxy-solicitation-services"], // EQ Fund Solutions LLC
	["Equity", "https://www.equitystock.com"], // Equity Stock Transfer LLC
	["First Coast Results", "https://fcrvote.com"], // First Coast Results, Inc.
	["First Cover Stock Transfer", "https://firstcover.com"], // First Cover Stock Transfer, LLC
	["Georgeson", "https://www.georgeson.com/us/business/proxy-solicitation/prepare-for-shareholder-meetings"],
	["HKL", "https://hklco.com"],
	["Innisfree", "https://www.innisfreema.com"],
	["InvestorCom", "https://investorcom.com"],
	["Issuer Direct", "https://www.issuerdirect.com/services/annual-meeting-proxy"],
	["Kingsdale Advisors", "https://kingsdaleadvisors.com/strategic-communications-toronto-new-york#services-page-grid"],
	["Laurel Hill", "https://www.laurelhill.com/proxy-services"],
	["Lioness Consulting", "https://www.lionessconsultingllc.com"], // Lioness Consulting LLC
	["MacKenzie Partners", "http://mackenziepartners.com/proxy-services.php"],
	["Mediant", "https://betanxt.com/mediant"],
	["Morrow Sodali", "https://morrowsodali.com/our-services/proxy-solicitation-and-shareholder-meeting-services"],
	["Morrow Sodali Fund Solutions", "https://morrowsodali.com/our-services/proxy-solicitation-and-shareholder-meeting-services"],
	["MSFS", "https://morrowsodali.com/our-services/proxy-solicitation-and-shareholder-meeting-services"],
	["Morrow Sodali Fund Solutions (MSFS)", "https://morrowsodali.com/our-services/proxy-solicitation-and-shareholder-meeting-services"],
	["Odyssey", "https://odysseytrust.com/us"], // Odyssey Trust Company
	["Okapi", "https://okapipartners.com/corporate-proxy-solicitation"],
	["Pacific Stock Transfer", "https://securitize.io/pacific-stock-transfer"], // Pacific Stock Transfer Company, A Securitize Company
	["Proxy Advisory Group", "https://allianceadvisors.com/what-we-do/#shareholder-engagement-solicitation"],
	["Regan & Associates", "https://www.reganproxy.com"], // Regan & Associates, Inc.
	["Saratoga", "https://saratogaproxy.com/services/proxy-advice-full-service-proxy-solicitation"],
	["Sodali", "https://sodali.com/our-services/proxy-solicitation-services"], // Sodali & Co
	["Solo", "https://transferonlinesolo.com"], // Transfer Online SOLO, LLC
	["Sterling Issuer Services", "https://issuerservices.us"], // Sterling Issuer Services, LLC
	["Transhare", "https://www.transhare.com"], // Transhare Corporation
	["Transfer Online", "https://transferonline.com/IssuerServices/ProxyMailing"], // Transfer Online, Inc
	["TSX Trust", "https://www.tsxtrust.com"],
	["VStock Transfer", "https://www.vstocktransfer.com/proxy-annual-meeting-services"], // Vstock Transfer, LLC
	["West Coast Stock Transfer", "https://westcoaststocktransfer.com"] // West Coast Stock Transfer, Inc.
]);

const formTypes = new Map([
	["DEF 14A", "Definitive proxy statements"],
	["DEFA14A", "Definitive additional proxy soliciting materials"],
	["N-1A", "Initial registration statement filed on Form N1A for open-end management investment companies"],
	["N-1A/A", "Initial registration statement filed on Form N1A for open-end management investment companies"],
	["N-14", "Initial registration statement filed on Form N14 for open-end investment company, including those filed with automatic effectiveness under Rule 488 (business combinations)"],
	["N-14/A", "Initial registration statement filed on Form N14 for open-end investment company, including those filed with automatic effectiveness under Rule 488 (business combinations)"],
	["N-14 8C", "Initial registration statement filed on Form N-14 by closed-end investment company (business combinations)"],
	["N-14 8C/A", "Pre-effective amendment"],
	["PRE 14A", "Preliminary proxy statement not related to a contested matter or merger/acquisition"],
	["PRER14A", "Preliminary revised proxy soliciting materials"]
]);

export const ChartColors = ["#007bff", "green", "#EA5657", "#8884d8", "#82ca9d", "lightskyblue", "lightgreen", "#ffc658", "orange", "red", "gray"]; // "#8ACBC7", 
// Colors for Calendar and Time Range Charts: Light Blue to Dark Blue
export const CalendarChartColors = ["lightblue", "lightskyblue", "deepskyblue", "#4793ff", "#388bff", "#2583ff", "#007bff", "#0073f6", "#006ced", "#0064e4", "royalblue", "blue", "mediumblue", "darkblue"];

/* Proxy Dashboard Links */
// Link to View/Edit Proxy Data in a Filing
export const viewEditProxyLink = (san: string, edit: boolean = false): any => {
	if (!san) return ("");
	if (edit)
		return <Link to={`/edit-data/${san}`} title="Edit Proxy Data"><FaEdit /></Link>; //  className='btn btn-outline-primary btn-sm pt-0'
	else
		return <Link to={`/view-data/${san}`} title="View Proxy Data"><FaRegEye /></Link>;
}

// Link to View Ownership Filing
export const ownershipFilingLink = (san?: string, cell?: string): any => {
	if (!san || !cell) return ("");
	return (<Link onClick={(e) => e.stopPropagation()} to={`/ownership-filing/${san}`} title="View Ownership Data">{cell}</Link>);
}

// Link to View Funds in a Filing
export const sanFundsLink = (san?: string, count?: number): any => {
	if (!san || !count) return ("");
	return (<Link to={"/funds/san/" + san} onClick={(e) => e.stopPropagation()} title="View List of Funds in this Filing" className="mr-4">{count}</Link>);
}

// Link to View Fund-Classes in a Filing
export const sanClassesLink = (san?: string, count?: number): any => {
	if (!san || !count) return ("");
	return (<Link to={"/classes/san/" + san} onClick={(e) => e.stopPropagation()} title="View List of Fund-Classes in this Filing" className="mr-4">{count}</Link>);
}

// Link to View Funds in a Company
export const cikFundsLink = (cik?: string, count?: number): any => {
	if (!cik || !count) return ("");
	return (<Link to={"/funds/cik/" + cik} onClick={(e) => e.stopPropagation()} className="mr-4">{count}</Link>);
}

// Link to View Fund-Classes in a Company
export const cikClassesLink = (cik?: string, count?: number): any => {
	if (!cik || !count) return ("");
	return (<Link to={"/classes/cik/" + cik} onClick={(e) => e.stopPropagation()} className="mr-4">{count}</Link>);
}

// Link to View Classes in a Series/Fund
export const seriesClassesLink = (seriesId?: string, count?: number): any => {
	if (!seriesId || !count) return ("");
	return (<Link to={"/classes/seriesid/" + seriesId} onClick={(e) => e.stopPropagation()} className="mr-4">{count}</Link>);
}

/* SEC Links */
export const cikFilingsLink = (cik: any, text?: any): any => {
	if (!cik) return ("");
	return (<a href={"https://www.sec.gov/edgar/browse/?CIK=" + cik} onClick={(e) => e.stopPropagation()} className="mr-4" target="_blank">{text || cik}</a>);
}

export const cikDefFilingsLink = (cik: any, text?: any): any => {
	if (!cik) return ("");
	return (<a href={"https://www.sec.gov/cgi-bin/browse-edgar?action=getcompany&type=DEF&CIK=" + cik} onClick={(e) => e.stopPropagation()} target="_blank">{text || trimStart(cik, "SC0")}</a>);
}

export const votingRecordsLink = (cik: any, text?: any): any => {
	if (!cik) return ("");
	return (<a href={"https://www.sec.gov/cgi-bin/browse-edgar?action=getcompany&type=N-PX&CIK=" + cik} onClick={(e) => e.stopPropagation()} target="_blank">{text || 'Voting Records'}</a>);
}

export const filingLink = (fileName: any, text: any): any => {
	if (!fileName || !text) return ("");
	return (<a href={"https://www.sec.gov/Archives/" + fileName} target="_blank" title="View SEC Filing">{text}</a>);
}

export const filingIndexLink = (fileName: any, san: any, text: any): any => {
	if (!fileName || !san || !text) return ("");
	return (<a href={"https://www.sec.gov/Archives/" + fileName.replace("-", "").replace(".txt", "/") + san + "-index.htm"} target="_blank" title="View SEC Filing Detail">{text}</a>);
}

export const filingDocumentLink = (fileName: string, documentFileName: string, san: string, text: string): any => {
	if (!fileName || !san || !text) return ("");
	const htmlContent = text.replace(/;/g, "<br/>");
	return (<a href={"https://www.sec.gov/Archives/" + fileName.replace("-", "").replace("-", "").replace(".txt", "/") + documentFileName} target="_blank" title="View SEC Filing Document"
		dangerouslySetInnerHTML={
			{ __html: htmlContent }
		}></a>);
}

export const indexUrl = (san?: string, fileName?: string): string => {
	if (!san || !fileName) return "";
	return "https://www.sec.gov/Archives/" + fileName.replace("-", "").replace(".txt", "/") + san + "-index.htm";
}

/* Morningstar Links */
export const morningstarLink = (ticker: any, text?: any): any => {
	if (!ticker) return ("");
	return (<a href={"https://www.morningstar.com/funds/xnas/" + ticker} target="_blank">{text || ticker}</a>);
}

/* Helper Functions */
export const formatMoney = (amount?: string): string => {
	if (!amount) return "";
	if (!amount.match(/^\d+(.\d*){0,1}$/)) return amount;
	return "$" + replace(amount, /(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

// Format Number to Comma Separated
export const formatNumber = (amount?: number): string => {
	if (!amount) return "";
	return replace(amount.toString(), /(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

// Format Currency to Comma Separated
export const formatCurrency = (amount?: number): string => {
	if (!amount) return "";
	return "$" + replace(amount.toString(), /(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

// Format Percentage
export const formatPercentage = (amount?: number, decimals: number = 0): string => {
	if (!amount) return "";
	return amount.toFixed(decimals) + '%';
}

// Format Date to 1/1/2023
export const dateToString = (date: Date | string | undefined, year: number = 0): string => {
	if (!date) return "";
	return (new Date(date).getMonth() + 1) + "/" + (new Date(date).getDate() + 0) + "/" + (year ? year : new Date(date).getFullYear());
}

// Format Date to January 1
export const dateToMDString = (date: Date | string | undefined): string => {
	if (!date) return "";
	const month = new Date(date).toLocaleString('default', { month: 'long' });
	return month + " " + (new Date(date).getDate() + 0);
}

// Format 0101 to January 1
export const mdToString = (md: string | undefined): string => {
	if (!md) return "";
	const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	const month = months[parseInt(md.substring(0, 2)) - 1];
	return month + " " + parseInt(md.substring(2));
}

// Format 0101, 2023 to 1/1/2023
export const mdyToString = (md: string | undefined, y: number): string => {
	if (!md) return "";
	const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	const month = months[parseInt(md.substring(0, 2)) - 1];
	return month + " " + parseInt(md.substring(2)) + ", " + y;
}

export const buildUrl = (params: { url: string, queryParams?: any }): string => {
	let url = params.url;
	if (params.queryParams) {
		url += (url.indexOf('?') === -1 ? '?' : '&') + queryParams(params.queryParams);
	}
	return url;
}

export const queryParams = (params: any = {}): string => {
	params = params || {};
	return Object.keys(params)
		.map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
		.join('&');
}

export const getUserProfile = async (): Promise<Response> => {
	let url = '/connect/userinfo';
	const token = await authService.getAccessToken();
	let options: any = {};
	if (token) {
		options.headers = {
			'Authorization': `Bearer ${token}`
		};
	}
	return fetch(url, options).then(response => {
		if (!response.ok) {
			return Promise.resolve(null);
		}
		return response.json();
		//return response;
	})
		.catch((e) => {
			return Promise.reject(null);
		});
}

export const fetchWithAuth = async (params: { url: string, queryParams?: any }, options: any = {}): Promise<Response> => {
	let url = buildUrl(params);
	const token = await authService.getAccessToken();
	options = options || {};
	if (token) {
		options.headers = {
			...options.headers,
			'Authorization': `Bearer ${token}`
		};
	}
	return fetch(url, options).then(response => {
		if (!response.ok) {
			if ([401, 403].includes(response.status)) {
				// auto logout if 401 Unauthorized or 403 Forbidden response returned from api
				console.log("Unauthorized: Redirecting to login");
				window.history.pushState({ state: { local: true } }, "", ApplicationPaths.LogOut);
				window.history.go();
			}
			return Promise.reject(response);
		}
		return UpdateLastActivityAppWS().then(() => response);
		//return response;
	});
}

// Link to View Solicitor Website using Solicitor Name
export const solicitorUrl = (solicitor: string) => {
	if (!solicitor) return ("");
	if (!solicitorUrls.has(solicitor))
		return solicitor;
	return (<a onClick={(e) => e.stopPropagation()} href={solicitorUrls.get(solicitor)} target="_blank">{solicitor}</a>);
}

// Format Date to YYYY-MM-DDT00:00:00
export const DateToJSON = (date: Date) => {
	return date ? `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}T00:00:00` : date;
}

// Format Dates in a Collection to YYYY-MM-DDT00:00:00
export const fixDateToStringify = (value: any) => {
	let newValue: any = {};
	for (const key in value) {
		if (value[key] instanceof Date) {
			newValue[key] = DateToJSON(value[key]);
		}
		else {
			newValue[key] = value[key];
		}
	}
	return newValue;
}

// Render Calendar Chart
export const renderCalendarChart = (chartInfo?: any) => {
	return (
		<React.Fragment>
			<NivoCalendarChart chartInfo={chartInfo}></NivoCalendarChart>
		</React.Fragment>
	);
}

// Render Stacked Bar Chart
export const renderProxyCountsChart = (chartInfo?: any) => {
	return (
		<React.Fragment>
			<RCStackedChart chartInfo={chartInfo}></RCStackedChart>
		</React.Fragment>
	);
}

// Render Pie Chart
export const renderPieChart = (chartInfo?: any) => {
	return (
		<React.Fragment>
			<RCPieChart chartInfo={chartInfo}></RCPieChart>
		</React.Fragment>
	);
}

// Render Funnel Chart
export const renderFunnelChart = (chartInfo?: any) => {
	return (
		<React.Fragment>
			<RCFunnelChart chartInfo={chartInfo}></RCFunnelChart>
		</React.Fragment>
	);
}

export const sizePerPageOptionRenderer = ({
	text, page, onSizePerPageChange
}: any): JSX.Element => {
	return (
		<li
			key={text}
			role="presentation"
			className="dropdown-item"
		>
			<a
				href="#"
				//tabIndex="-1"
				role="menuitem"
				data-page={page}
				onMouseDown={(e) => {
					onSizePerPageChange(page);
					e.preventDefault();
				}}
				onClick={(e) => {
					e.preventDefault();
				}}
			>
				{text}
			</a>
		</li>
	);
}