import * as React from 'react';
import BootstrapTable, { SelectRowProps } from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AppContext } from '../AppContext';
import { ApplicationState } from '../store';
import * as CompaniesStore from '../store/Companies';
import { cikClassesLink, cikFilingsLink, cikFundsLink, dateToString, fetchWithAuth, formatNumber, votingRecordsLink, sizePerPageOptionRenderer } from '../store/Util';

const { SearchBar, ClearSearchButton } = Search;

// At runtime, Redux will merge together...
type CompanyProps =
	CompaniesStore.CompaniesState // ... state we've requested from the Redux store
	& typeof CompaniesStore.actionCreators // ... plus action creators we've requested
	& RouteComponentProps<{ san: string }>; // ... plus incoming routing parameters

class Companies extends React.PureComponent<CompanyProps, { family?: string, ciks: number[], showDeselectAll: boolean, openDialog: boolean }> {
	static contextType = AppContext;
	context!: React.ContextType<typeof AppContext>;

	familyNameEl: React.RefObject<HTMLInputElement>;

	// This method is called when the component is first added to the document
	constructor(props: any) {
		super(props);
		this.state = { ciks: [], showDeselectAll: false, openDialog: false };
		this.familyNameEl = React.createRef();
	}

	public componentDidMount() {
		this.props.resetState();
		this.loadFamilies();
	}

	// This method is called when the route parameters change
	public componentDidUpdate() {
		this.ensureDataFetched();
	}

	MyExportCSV = (props: any) => {
		const handleClick = () => {
			props.onExport(props.data);
		};
		return (
			<img src={"microsoft-excel.svg"} height="30px" onClick={handleClick} className="float-right pl-2 mt-1" role="button" title="Export Proxy Filing Fund Companies to Excel (.csv)" />
		);
	}

	public render() {
		return (
			<React.Fragment>
				<div className="col-12 overflow-auto">
					<div className="position-absolute">
						{/*<h1 id="tabelLabel">Proxy Filing Companies</h1>*/}
						{this.renderCompaniesTable()}
					</div>
				</div>
				<Modal isOpen={this.state.openDialog} returnFocusAfterClose={false}
					centered={true} keyboard={true} onHide={() => this.toggleModal(false)}>
					<ModalHeader toggle={() => this.toggleModal(false)}>
						New Fund Family
					</ModalHeader>
					<ModalBody>
						Enter Fund Family Name for selected Companies:
						<input type="text" className="form-control" id="familyName" ref={this.familyNameEl} minLength={3} maxLength={50} />
					</ModalBody>
					<ModalFooter>
						<Button
							color="primary"
							onClick={(e) => this.onViewProxies(e)}
						>
							View Proxy Filings
						</Button>
						{' '}
						<Button onClick={() => this.toggleModal(false)}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</React.Fragment>
		);
	}

	private toggleModal(openDialog: boolean) {
		if (openDialog) {
			this.setState({ ciks: this.state.ciks, showDeselectAll: this.state.showDeselectAll, openDialog: openDialog },
				() => {
					setTimeout(() => { this.familyNameEl && this.familyNameEl.current && this.familyNameEl.current.focus() }, 1);
				}
			);
		} else {
			this.setState({ ciks: this.state.ciks, showDeselectAll: this.state.showDeselectAll, openDialog: openDialog });
		}
	}

	// View Proxies button click handler: Get Family Name and Save
	private onViewProxies(e: any) {
		if (!this.familyNameEl || !this.familyNameEl.current || !this.familyNameEl.current.value) {
			alert("Please enter Family Name.");
			return;
		}
		var familyName = this.familyNameEl.current.value;
		//console.log(familyName);
		this.addFamily({ name: familyName, ciks: this.state.ciks });
		//var ciks = this.state.ciks.join(", ");
		//this.context.addFamily(familyName, ciks);
		//this.context.setProxyFilter("", `ciks:${ciks}`, "", "", "", "");
		//this.props.history.push("proxies");
	}

	// Submit New Family Name with CIKs to Server for Saving in Database
	addFamily = (family: any) => {
		console.log("addFamily:", family);
		//debugger;
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(family)
		};

		fetchWithAuth({ url: '/api/edgar/add-family' }, requestOptions)
			.then(response => {
				if (response.ok) {
					// Success. Set Family Filter and View Proxies
					this.context.setProxyFilter("", family.name, "", "", "", "");
					this.props.history.push('/proxies');
				}
			});
	}

	private ensureDataFetched() {
		const san = this.props.match.params.san || "-"; // match.params.
		const ownershipCompanies = this.props.match.url.startsWith("/ownership-companies");
		this.props.requestCompanies(san, this.context.getProxyFilterFamily(), ownershipCompanies);
	}

	onSelect = (row: any, isSelect: boolean, rowIndex: number, e: any) => {
		this.setState({
			ciks: isSelect ? [...this.state.ciks, row.cik]
				: this.state.ciks.filter(x => x !== row.cik),
			showDeselectAll: (isSelect ? [...this.state.ciks, row.cik]
				: this.state.ciks.filter(x => x !== row.cik)).length > 0
		});
	}

	onSelectAll = () => {
		this.setState({ ciks: [], showDeselectAll: false });
	}

	paginationTotalRenderer = (from: any, to: any, size: any) => (
		size ?
			<span className="react-bootstrap-table-pagination-total pl-2">
				Showing {from} to {to} of {formatNumber(size)} Results
			</span> : <span></span>
	);

	private emptyDataMessage = () => {
		if (this.props.isLoading)
			return 'Loading...';
		return 'No Data to Display';
	}

	private renderCompaniesTable() {
		const ownershipCompanies = this.props.match.url.startsWith("/ownership-companies");
		const selectRow: SelectRowProps<any> = {
			mode: 'checkbox',
			onSelect: this.onSelect,
			hideSelectAll: !this.state.showDeselectAll,
			onSelectAll: this.onSelectAll,
			clickToSelect: true,
			//hideSelectColumn: true,
			//bgColor: 'lightgray' // '#00BFFF'
		};

		var columns: any[] = [
			{
				dataField: 'cik',
				text: 'CIK',
				sort: true,
				align: "right",
				headerStyle: { width: '80px' },
				headerTitle: () => 'SEC EDGAR Company Index Key',
				title: (cell: any, row: any) => `View All Filings of Company ${row.name}`,
				hidden: true,
				csvExport: false,
				formatter: (cell: any) => cikFilingsLink(cell)
			},
			{
				dataField: 'name',
				text: 'Company Name',
				sort: true,
				headerTitle: () => "Fund Company Name",
				title: (cell: any) => `View Proxies of Company ${cell}`,
				formatter: (cell: any, row: any) => cikFilingsLink(row.cik, cell)
			}
		];

		var proxyColumns: any[] = [
			{
				dataField: 'series',
				text: 'Funds',
				sort: true,
				defaultSortDirection: 'desc',
				align: "center",
				headerStyle: { width: '75px' },
				headerTitle: () => '# Funds Registered in Company',
				title: (cell: any, row: any) => cell ? `View List of Funds in ${row.name}` : '',
				formatter: (cell: any, row: any) => cikFundsLink(row.cik, cell),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'classes',
				text: 'Classes',
				sort: true,
				align: "center",
				headerStyle: { width: '85px' },
				headerTitle: () => '# Fund-Classes Registered in Company',
				title: (cell: any, row: any) => cell ? `View List of Fund-Classes in ${row.name}` : '',
				formatter: (cell: any, row: any) => cikClassesLink(row.cik, cell),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount',
				text: 'Proxies',
				sort: true,
				align: "center",
				headerAlign: 'center',
				headerStyle: { width: '80px' },
				headerTitle: () => '# Proxies Filed by Company since 2019',
				title: (cell: any, row: any) => `View Proxies of Company ${row.name}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"proxies"} onClick={() => this.setProxyFilterCik(row.cik)}>{cell}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount24',
				text: 'Proxies 2024',
				sort: true,
				align: "center",
				headerAlign: 'center',
				headerStyle: { width: '80px' },
				headerTitle: () => '# Proxies Filed by Company in 2024',
				title: (cell: any, row: any) => `View Proxies of Company ${row.name} Filed in 2024`,
				formatter: (cell: any, row: any) => {
					return (
						cell ? <Link to={"proxies"} onClick={() => this.setProxyFilterCik(row.cik, "2024")}>{cell}</Link> : ''
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount23',
				text: 'Proxies 2023',
				sort: true,
				align: "center",
				headerAlign: 'center',
				headerStyle: { width: '80px' },
				headerTitle: () => '# Proxies Filed by Company in 2023',
				title: (cell: any, row: any) => `View Proxies of Company ${row.name} Filed in 2023`,
				formatter: (cell: any, row: any) => {
					return (
						cell ? <Link to={"proxies"} onClick={() => this.setProxyFilterCik(row.cik, "2023")}>{cell}</Link> : ''
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount22',
				text: 'Proxies 2022',
				sort: true,
				align: "center",
				headerAlign: 'center',
				headerStyle: { width: '80px' },
				headerTitle: () => '# Proxies Filed by Company in 2022',
				title: (cell: any, row: any) => `View Proxies of Company ${row.name} Filed in 2022`,
				formatter: (cell: any, row: any) => {
					return (
						cell ? <Link to={"proxies"} onClick={() => this.setProxyFilterCik(row.cik, "2022")}>{cell}</Link> : ''
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount21',
				text: 'Proxies 2021',
				sort: true,
				align: "center",
				headerAlign: 'center',
				headerStyle: { width: '80px' },
				headerTitle: () => '# Proxies Filed by Company in 2021',
				title: (cell: any, row: any) => `View Proxies of Company ${row.name} Filed in 2021`,
				formatter: (cell: any, row: any) => {
					return (
						cell ? <Link to={"proxies"} onClick={() => this.setProxyFilterCik(row.cik, "2021")}>{cell}</Link> : ''
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount20',
				text: 'Proxies 2020',
				sort: true,
				align: "center",
				headerAlign: 'center',
				headerStyle: { width: '80px' },
				headerTitle: () => '# Proxies Filed by Company in 2020',
				title: (cell: any, row: any) => `View Proxies of Company ${row.name} Filed in 2020`,
				formatter: (cell: any, row: any) => {
					return (
						cell ? <Link to={"proxies"} onClick={() => this.setProxyFilterCik(row.cik, "2020")}>{cell}</Link> : ''
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount19',
				text: 'Proxies 2019',
				sort: true,
				align: "center",
				headerAlign: 'center',
				headerStyle: { width: '80px' },
				headerTitle: () => '# Proxies Filed by Company in 2019',
				title: (cell: any, row: any) => `View Proxies of Company ${row.name} Filed in 2019`,
				formatter: (cell: any, row: any) => {
					return (
						cell ? <Link to={"proxies"} onClick={() => this.setProxyFilterCik(row.cik, "2019")}>{cell}</Link> : ''
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'dateFiled',
				text: 'Last Filed',
				sort: true,
				align: "right",
				headerAlign: 'right',
				headerStyle: { width: '100px' },
				headerTitle: () => 'Date of Last Proxy Filing Filed on SEC EDGAR',
				//title: () => 'Date Filed on SEC EDGAR',
				formatter: (cell: any) => dateToString(cell),
				csvFormatter: (cell: any) => dateToString(cell)
			},
			{
				dataField: 'votingRecords',
				hidden: true,
				isDummyField: true,
				text: '',
				csvExport: false,
				title: (cell: any, row: any) => `View Proxy Voting Records of Company ${row.name}`,
				formatter: (cell: any, row: any) => votingRecordsLink(row.cik)
			}
		];

		var ownershipColumns: any[] = [
			{
				dataField: 'ownershipCount',
				text: 'Filings',
				sort: true,
				align: "center",
				headerAlign: 'center',
				headerStyle: { width: '80px' },
				headerTitle: () => '# Ownership Filings Filed by Company since 2019',
				title: (cell: any, row: any) => `View Ownership Filings of Company ${row.name}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"ownership-filings"} onClick={() => this.setOwnershipFilter(row.cik)}>{cell}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'ownershipCount24',
				text: 'Filings 2024',
				sort: true,
				align: "center",
				headerAlign: 'center',
				headerStyle: { width: '80px' },
				headerTitle: () => '# Ownership Filings Filed by Company in 2024',
				title: (cell: any, row: any) => `View Ownership Filings of Company ${row.name} Filed in 2024`,
				formatter: (cell: any, row: any) => {
					return (
						cell ? <Link to={"ownership-filings"} onClick={() => this.setOwnershipFilter(row.cik, "2024")}>{cell}</Link> : ''
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'ownershipCount23',
				text: 'Filings 2023',
				sort: true,
				align: "center",
				headerAlign: 'center',
				headerStyle: { width: '80px' },
				headerTitle: () => '# Ownership Filings Filed by Company in 2023',
				title: (cell: any, row: any) => `View Ownership Filings of Company ${row.name} Filed in 2023`,
				formatter: (cell: any, row: any) => {
					return (
						cell ? <Link to={"ownership-filings"} onClick={() => this.setOwnershipFilter(row.cik, "2023")}>{cell}</Link> : ''
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'ownershipCount22',
				text: 'Filings 2022',
				sort: true,
				align: "center",
				headerAlign: 'center',
				headerStyle: { width: '80px' },
				headerTitle: () => '# Ownership Filings Filed by Company in 2022',
				title: (cell: any, row: any) => `View Ownership Filings of Company ${row.name} Filed in 2022`,
				formatter: (cell: any, row: any) => {
					return (
						cell ? <Link to={"ownership-filings"} onClick={() => this.setOwnershipFilter(row.cik, "2022")}>{cell}</Link> : ''
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'ownershipCount21',
				text: 'Filings 2021',
				sort: true,
				align: "center",
				headerAlign: 'center',
				headerStyle: { width: '80px' },
				headerTitle: () => '# Ownership Filings Filed by Company in 2021',
				title: (cell: any, row: any) => `View Ownership Filings of Company ${row.name} Filed in 2021`,
				formatter: (cell: any, row: any) => {
					return (
						cell ? <Link to={"ownership-filings"} onClick={() => this.setOwnershipFilter(row.cik, "2021")}>{cell}</Link> : ''
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'ownershipCount20',
				text: 'Filings 2020',
				sort: true,
				align: "center",
				headerAlign: 'center',
				headerStyle: { width: '80px' },
				headerTitle: () => '# Ownership Filings Filed by Company in 2020',
				title: (cell: any, row: any) => `View Ownership Filings of Company ${row.name} Filed in 2020`,
				formatter: (cell: any, row: any) => {
					return (
						cell ? <Link to={"ownership-filings"} onClick={() => this.setOwnershipFilter(row.cik, "2020")}>{cell}</Link> : ''
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'ownershipCount19',
				text: 'Filings 2019',
				sort: true,
				align: "center",
				headerAlign: 'center',
				headerStyle: { width: '80px' },
				headerTitle: () => '# Ownership Filings Filed by Company in 2019',
				title: (cell: any, row: any) => `View Ownership Filings of Company ${row.name} Filed in 2019`,
				formatter: (cell: any, row: any) => {
					return (
						cell ? <Link to={"ownership-filings"} onClick={() => this.setOwnershipFilter(row.cik, "2019")}>{cell}</Link> : ''
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'ownershipDateFiled',
				text: 'Last Filed',
				sort: true,
				align: "right",
				headerAlign: 'right',
				headerStyle: { width: '100px' },
				headerTitle: () => 'Date of Last Ownership Filing Filed on SEC EDGAR',
				formatter: (cell: any) => dateToString(cell),
				csvFormatter: (cell: any) => dateToString(cell)
			}
		];

		if (ownershipCompanies) {
			columns.push(...ownershipColumns);
		} else {
			columns.push(...proxyColumns);
		}

		return (
			<ToolkitProvider
				keyField="cik"
				data={this.props.companies || []}
				columns={columns}
				search={{
					searchFormatted: true,
					onColumnMatch: this.onColumnMatch
				}}
				exportCSV={{
					fileName: ownershipCompanies ? 'Ownership Filing Companies.csv' : 'Proxy Filing Companies.csv',
					blobType: 'text/csv;charset=utf-8'
				}}
			>{
					props => (
						<div>
							<p className="mb-0">
								{this.props.companies && this.props.companies.length ? formatNumber(this.props.companies.length) + " " : ""}
								Fund Companies that filed {ownershipCompanies ? 'Ownership' : 'Proxy'} Filing on/after 1/1/2019. &nbsp;
								{this.props.companies && this.props.companies.length ? <this.MyExportCSV {...props.csvProps} /> : ""}
								<select onChange={this.onChangeFamily} value={this.context.getProxyFilterFamily() || '%'} className="float-right form-control w-auto ml-3" style={{ maxWidth: "300px" }} title="Select Fund Family">
									<option value="">Select Family ...</option>
									{this.getDefaultAndCustomFamilies()}
								</select>
								{this.state && this.state.ciks && this.state.ciks.length
									? <button type="button" className="btn btn-outline-primary btn-sm float-right ml-3" title="View Proxy Filings for Selected Companies"
										onClick={(e) => this.toggleModal(true)}>View Proxy Filings</button> : ""}
								<SearchBar
									{...props.searchProps}
								/>
							</p>
							<p className="mb-1">

							</p>
							<BootstrapTable
								{...props.baseProps}
								//keyField="cik"
								//data={this.props.companies || []}
								//columns={columns}
								bootstrap4={true}
								striped={true}
								bordered={false}
								headerClasses="table-header-class"
								classes={"unset-width"}
								noDataIndication={this.emptyDataMessage}
								selectRow={selectRow}
								hover
								condensed
								defaultSorted={[{
									dataField: 'proxyCount', // if dataField is not match to any column you defined, it will be ignored.
									order: 'desc' // desc or asc
								}]}
								pagination={paginationFactory({
									hidePageListOnlyOnePage: true,
									showTotal: true,
									paginationTotalRenderer: this.paginationTotalRenderer,
									paginationSize: 10, // # of Page Links. Default: 5
									sizePerPage: 25,
									sizePerPageList: [25, 50, 100, 250, 500, this.props.companies ? this.props.companies.length : 1000],
									hideSizePerPage: !(this.props.companies && this.props.companies.length),
									sizePerPageOptionRenderer
								})}
							/>
						</div>
					)
				}
			</ToolkitProvider>
		);
	}

	defaultFamilies: any[] = [];

	private loadFamilies() {
		const ownershipCompanies = this.props.match.url.startsWith("/ownership-companies");
		const title = (ownershipCompanies ? 'Ownership' : 'Proxy') + ' Filing Companies';
		const url = '/api/edgar/' + (ownershipCompanies ? 'ownership-families' : 'families');
		fetchWithAuth({ url: url })
			.then(response => response.json())
			.then(data => {
				this.defaultFamilies = data;
				this.ensureDataFetched();
				this.ensureDataFetched();
				this.context.changeTitle(title);
			})
			.catch(error => {
				console.error('There was an error!', error);
			});
	}

	onChangeFamily = (event: any) => {
		this.context.patchState({ family: event.target.value });
		this.ensureDataFetched();
	}

	// Get Dropdown Options of Default and Custom Families
	getDefaultAndCustomFamilies = () => {
		const customFamilies: any[] = this.context.getFamilies();
		var options = customFamilies.map((customFamily) => {
			return (<option value={customFamily.ciks}>{customFamily.name}</option>);
		}, this);
		this.defaultFamilies.map((defaultFamily) => {
			options.push(<option value={defaultFamily.name}>{defaultFamily.name}</option>);
		}, this);
		return options;
	}

	// Custom match logic on every cell value
	private onColumnMatch(opts: any) {
		const getNodeText: any = (node: any) => {
			if (['string', 'number'].includes(typeof node)) return node;
			if (node instanceof Array) return node.map(getNodeText).join('');
			if (typeof node === 'object' && node) return getNodeText(node.props.children);
		}
		var targetValue = getNodeText(opts.value);
		if (targetValue !== null && typeof targetValue !== 'undefined') {
			targetValue = targetValue.toString().toLowerCase();
			if (targetValue.indexOf(opts.searchText) > -1) {
				return true;
			}
		}
	}

	private setProxyFilterCik(cik: number, year: string = "") {
		this.context.setProxyFilter(cik.toString(), "", year, "", "", "");
	}

	private setOwnershipFilter(cik: number, year: string = "") {
		this.context.setOwnershipFilter(cik.toString(), year);
	}
}

export default connect(
	(state: ApplicationState) => state.companies, // Selects which state properties are merged into the component's props
	CompaniesStore.actionCreators // Selects which action creators are merged into the component's props
)(Companies as any); // eslint-disable-line @typescript-eslint/no-explicit-any
