import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { formatNumber, renderPieChart, solicitorShortNames } from '../store/Util';
import { RCStackedChart } from './charts/rc-stacked-bar-chart';

// At runtime, Redux will merge together...
export class SolicitorMarketSharePieChart extends Component<
	{ data?: any, isLoading: boolean, onActiveDotClick: any, filter?: any, title?: string },
	{ year?: string, openOrClosedCIK?: string }
> {
	static contextType = AppContext;
	context!: React.ContextType<typeof AppContext>;
	constructor(props: any) {
		super(props);
		this.state = {
			year: '',
			openOrClosedCIK: ''
		};
	}

	// This method is called when the component is first added to the document
	public componentDidMount() {
		this.setState({ year: this.props.filter === undefined ? this.context.getSolicitorFilter() : this.props.filter });
	}

	// This method is called when the route parameters change
	public componentDidUpdate() {
	}

	public render() {
		if (this.props.isLoading)
			return (<></>);

		let year = this.state.year == 'OpenEnd' || this.state.year == 'ClosedEnd' || this.state.year == 'ETF' ? '' : this.state.year;
		let countColumn = this.state.year == 'OpenEnd' ? 'openEnd' : this.state.year == 'ClosedEnd' ? 'closedEnd' : this.state.year == 'ETF' ? 'etf' : `proxyCount${year}`;
		if (year && year.startsWith("cost")) { // Solicitation Cost
			countColumn = countColumn.replace("proxyCount", "");
		}
		let pieInfo: any = [
			{ fieldName: "percentage", hideTickLabel: true, label: "value", name: "percentageTxt" }
		];

		let sData = _.orderBy((this.props.data || []), countColumn, 'desc');
		let total = _.sumBy(sData, countColumn);
		sData = sData.map((d: any) => {
			let percentage = ((d[countColumn] || 0) * 100) / total;
			let solicitor = percentage > 4 ? d.solicitor : 'Other';
			return { solicitor, percentage, d, proxyCount: d[countColumn] };
		});

		sData = _(sData).groupBy('solicitor').map((value, key) => {
			return {
				solicitor: key,
				percentage: _.sumBy(value, 'percentage'),
				value
			};
		}).value();

		const key: string = 'solicitor';
		let chartInfo: any = {
			chartColors: this.context.getSolicitorColors(this.props.data || [], sData || []),
			key,
			pieInfo,
			data: sData || [],
			width: 350,
			height: 350,
			outerRadius: 150,
			pieSpacing: 25,
			margin: { top: 5, left: 0 },
			//title: 'Yearly Proxy Filings by Meeting Types',
			onPieClick: (lineInfo: any, data: any, value: any, key: string, chart: RCStackedChart) => { this.onActiveDotClick(lineInfo, data, value, key, chart) },
			//label: "solicitor",
			customLabel: (props: any) => {
				const RADIAN = Math.PI / 180;
				let midAngle = (props.viewBox.startAngle + props.viewBox.endAngle) / 2;
				const radius = props.viewBox.innerRadius + (props.viewBox.outerRadius - props.viewBox.innerRadius) * 0.5;
				const x = props.cx + radius * Math.cos(-midAngle * RADIAN);
				const y = props.cy + radius * Math.sin(-midAngle * RADIAN);
				//let solicitor = props.value[0].solicitor == 'Morrow Sodali Fund Solutions' ? 'MSFS' : props.value[0].solicitor;
				return (
					<text x={x} y={y} fill="white" textAnchor={x > props.cx ? 'start' : 'end'} dominantBaseline="central" style={{ pointerEvents: 'none' }}>
						<tspan x={x} text-anchor="middle">{`${solicitorShortNames.get(props.value[0].solicitor) || props.value[0].solicitor}`}</tspan>
						<tspan x={x} text-anchor="middle" dy="18">{`${formatNumber(_.sumBy(props.value, 'proxyCount'))} (${(_.sumBy(props.value, 'percentage')).toFixed(2)}%)`}</tspan>
					</text>
				);
			},
			tooltip: (props: any) => {
				let d: any = props.payload[0].payload;
				let color: any = d.fill;
				let rows: any = d.value;
				return <React.Fragment>
					<div className='nivoCalendarDiv' style={{ border: '1px solid ' + color }}>
						<table style={{ color }}>
							<tbody>
								{rows.filter((x: any) => x.d[countColumn] > 0).map((r: any) => {
									return <tr><td>{solicitorShortNames.get(r.d.solicitor) && r.d.solicitor !== solicitorShortNames.get(r.d.solicitor) ? `${r.d.solicitor} (${solicitorShortNames.get(r.d.solicitor) })` :r.d.solicitor} :</td><td className={'text-right'}>{formatNumber(r.d[countColumn])}</td><td className={'text-right'}>({r.percentage.toFixed(2)}%)</td></tr>
								})}
								{rows.length <= 1 ? '' : <tr><td>{'Total'} :</td><td className={'text-right'}>{formatNumber(_.sumBy(rows, 'proxyCount'))}</td><td className={'text-right'}>({_.sumBy(rows, 'percentage').toFixed(2)}%)</td></tr>}
							</tbody>
						</table></div>
				</React.Fragment>;
			}
		};

		return (<React.Fragment>
			<>
				{this.props.title ? this.props.title : <Link to="/report/solicitors">Market Share of Proxy Filings by Solicitor</Link>}
				{this.props.filter === undefined ?
					<select onChange={this.onChangeFilter} value={this.context.getSolicitorFilter() || ''} className="ml-2" title="Select Filter">
						<option value="">{`2019-2024`}</option>
						<option value="24">2024</option>
						<option value="23">2023</option>
						<option value="22">2022</option>
						<option value="21">2021</option>
						<option value="20">2020</option>
						<option value="19">2019</option>
						<option value="OpenEnd">Open-end</option>
						<option value="ClosedEnd">Closed-end</option>
						<option value="ETF">ETF</option>
					</select>
					: ''}
				{renderPieChart(chartInfo)}
			</>
		</React.Fragment>);
	}

	onChangeFilter = (event: any) => {
		this.setState({ year: event.target.value });
		this.context.setSolicitorFilter(event.target.value);
	}

	onActiveDotClick = (lineInfo: any, data: any, value: any, key: string, chart: RCStackedChart) => {
		let stateToSet: any = {};
		let year = this.state.year?.replace('cost','');
		stateToSet["openOrClosedCIK"] = year == 'OpenEnd' ? "0" : year == 'ClosedEnd' ? "1" : year == 'ETF' ? "2" : "";
		stateToSet["year"] = year == 'OpenEnd' || year == 'ClosedEnd' || year == 'ETF' ? '' : (year ? `20${year}` : '');
		//this.props.setProxyFilter(stateToSet);
		this.props.onActiveDotClick(lineInfo, data, value, key, chart, stateToSet)
	}
}
