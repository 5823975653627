import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { fetchWithAuth } from './Util';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CompaniesState {
	isLoading: boolean;
	san?: string;
	family?: string;
	sort?: string;
	direction?: string;
	companies: Company[];
}

export interface Company {
	cik?: number;
	name?: string;
	product?: string;
	dateFiled?: Date;
	series?: number;
	classes?: number;
	proxyCount?: number;
	proxyCount19?: number;
	proxyCount20?: number;
	proxyCount21?: number;
	proxyCount22?: number;
	proxyCount23?: number;
	proxyCount24?: number;
	ownershipCount?: number;
	ownershipCount19?: number;
	ownershipCount20?: number;
	ownershipCount21?: number;
	ownershipCount22?: number;
	ownershipCount23?: number;
	ownershipCount24?: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestCompaniesAction {
	type: 'REQUEST_FILING_SERIES_CLASSES';
	san?: string;
	family?: string;
}

interface ReceiveCompaniesAction {
	type: 'RECEIVE_FILING_SERIES_CLASSES';
	san?: string;
	family?: string;
	companies: Company[];
}

interface ResetStateAction {
	type: 'RESET_STATE';
}
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestCompaniesAction | ReceiveCompaniesAction | ResetStateAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
	requestCompanies: (san?: string, family?: string, ownership?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Only load data if it's something we don't already have (and are not already loading)
		const appState = getState();
		if (appState && appState.companies && (san !== appState.companies.san || family !== appState.companies.family)) {
			fetchWithAuth({ url: `api/edgar/companies`, queryParams: { family } })
				.then(response => response.json() as Promise<Company[]>)
				.then(data => {
					dispatch({
						type: 'RECEIVE_FILING_SERIES_CLASSES', family: family, san: san,
						companies: ownership ? data.filter(f => f.ownershipCount && f.ownershipCount > 0 && (f.product == 'CEF' || f.product == 'BDC')) : data.filter(f => f.proxyCount && f.proxyCount > 0)
					});
				})
				.catch(error => {
					console.error('There was an error!', error);
				});

			dispatch({ type: 'REQUEST_FILING_SERIES_CLASSES', family: family, san: san });
		}
	},
	resetState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Reset State
		dispatch({ type: 'RESET_STATE' });
	}
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedStateCompanies: CompaniesState = { companies: [], isLoading: false };

export const reducer: Reducer<CompaniesState> = (state: CompaniesState | undefined, incomingAction: Action): CompaniesState => {
	if (state === undefined) {
		return unloadedStateCompanies;
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'RESET_STATE':
			return {
				san: '',
				family: '',
				companies: [],
				isLoading: false
			};
		case 'REQUEST_FILING_SERIES_CLASSES':
			return {
				san: action.san,
				family: action.family,
				companies: state.companies,
				isLoading: true
			};
		case 'RECEIVE_FILING_SERIES_CLASSES':
			// Only accept the incoming data if it matches the most recent request. This ensures we correctly
			// handle out-of-order responses.
			if (action.san === state.san) {
				return {
					san: action.san,
					family: action.family,
					companies: action.companies,
					isLoading: false
				};
			}
			break;
	}

	return state;
};
