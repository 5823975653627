import React, { Component, PureComponent } from 'react';
import { CartesianGrid, Cell, Funnel, FunnelChart, LabelList, Legend, Pie, PieChart, Tooltip } from 'recharts';
import { ChartColors } from '../../store/Util';

class CustomizedAxisTick extends PureComponent<any> {
	render() {
		const { x, y, stroke, payload } = this.props;

		// .toString().substring(0, 13)
		return (
			<g transform={`translate(${x},${y})`}>
				<text x={0} y={0} dy={16} textAnchor="middle" className="small" fill="#666" transform="rotate(0)">
					{payload.value}
				</text>
			</g>
		);
	}
}

export class RCFunnelChart extends Component<{ chartInfo?: any }> {

	onfunnelClick = (payload: any) => {
		let key = this.props.chartInfo.label; // Solicitor, Meeting Type, ...

		if (this.props.chartInfo.onFunnelClick && typeof this.props.chartInfo.onFunnelClick === 'function') {
			this.props.chartInfo.onFunnelClick.call(this, {}, payload.payload, payload.payload.proxyCount, key);
		}

	}

	CustomTooltip = (payload: any, data: any) => {
		if (!payload?.active)
			return <></>;
		if (this.props.chartInfo.tooltip && typeof this.props.chartInfo.tooltip === 'function') {
			return this.props.chartInfo.tooltip.call(this, payload);
		}
	};

	public render() {
		let chartInfo = {
			data: [],
			key: "",
			width: 500,
			height: 300,
			margin: {
				top: 20,
				right: 30,
				left: 20,
				bottom: 5
			},
			tooltip: false,
			...this.props?.chartInfo
		};
		return (
			<React.Fragment>
				<div>
					{this.showTitle()}
					<FunnelChart
						width={chartInfo.width}
						height={chartInfo.height}
						//data={chartInfo.data}
						margin={chartInfo.margin}
					>
						{/*Chart Title*/}
						<text x={chartInfo.width / 2} y={15} fill="black" textAnchor="middle" dominantBaseline="central">
							<tspan fontSize="14">{chartInfo.title}</tspan>
						</text>
						<CartesianGrid strokeDasharray="3 3" />
						<Tooltip content={chartInfo.tooltip ? <this.CustomTooltip /> : undefined} />
						{/*<Legend />*/}
						<Funnel
							dataKey={chartInfo.key}
							nameKey={chartInfo.label}
							data={chartInfo.data}
							isAnimationActive
							onClick={(payload) => { this.onfunnelClick(payload) }}
							style={{ cursor: 'pointer' }}
						>
							<LabelList position="inside" fill="#000" stroke="none" dataKey={chartInfo.label} style={{ pointerEvents: 'none' }} />
						</Funnel>
					</FunnelChart>
				</div>
			</React.Fragment>
		);
	}

	private showTitle() {
		return (this.props?.chartInfo && this.props?.chartInfo.title ? <div className="d-block text-center">{this.props?.chartInfo.title}</div> : <></>)
	}
};
