import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { fetchWithAuth } from './Util';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ConnectedUsersState {
	isLoading: boolean;
	sort?: string;
	direction?: string;
	last_request_date: number;
	connectedUsers: ConnectedUser[];
}

export interface ConnectedUser {
	connectionId?: string;
	userName?: string;
	ipAddress?: string;
	connectionDate?: Date;
	lastActivityDate?: Date;
	appVersion ?: string;
	appUserVersion?: string;
	page?: string;
	browser?: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestConnectedUsersAction {
	type: 'REQUEST_CONNECTED_USERS';
	last_request_date: number;
}

interface ReceiveConnectedUsersAction {
	type: 'RECEIVE_CONNECTED_USERS';
	last_request_date: number;
	connectedUsers: ConnectedUser[];
}

interface ResetStateAction {
	type: 'RESET_STATE';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestConnectedUsersAction | ReceiveConnectedUsersAction | ResetStateAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

const pollConnectedUsers = (last_request_date: any, dispatch: any, getState: any) => {
	fetchWithAuth({ url: 'api/admin/connected-users' })
		.then(response => response.json() as Promise<ConnectedUser[]>)
		.then(data => {
			setTimeout(() => {
				pollConnectedUsers(Date.now(), dispatch, getState);
			}, 10000);
			dispatch({ type: 'RECEIVE_CONNECTED_USERS', connectedUsers: data, last_request_date });
		})
		.catch(error => {
			console.error('There was an error!', error);
		});

	dispatch({ type: 'REQUEST_CONNECTED_USERS', last_request_date });
}

export const actionCreators = {
	// san: SAN or CIK or Series ID
	// dataType: 0: CIK Classes, 1: CIK Funds, 2: SAN Classes, 3: SAN Funds, 4: Series Classes
	requestConnectedUsers: (last_request_date: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Only load data if it's something we don't already have (and are not already loading)
		const appState = getState();
		if (appState && appState.connectedUsers && appState.connectedUsers.last_request_date < last_request_date) {
			pollConnectedUsers(Date.now(), dispatch, getState);
			//dispatch({ type: 'REQUEST_CONNECTED_USERS' });
		}
	},
	resetState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Reset State
		dispatch({ type: 'RESET_STATE' });
	}
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedStateConnectedUsers: ConnectedUsersState = { connectedUsers: [], isLoading: false, last_request_date: 0 };

export const reducer: Reducer<ConnectedUsersState> = (state: ConnectedUsersState | undefined, incomingAction: Action): ConnectedUsersState => {
	if (state === undefined) {
		return unloadedStateConnectedUsers;
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'RESET_STATE':
			return {
				connectedUsers: [],
				isLoading: false,
				last_request_date: 0
			};
		case 'REQUEST_CONNECTED_USERS':
			return {
				connectedUsers: state.connectedUsers,
				isLoading: true,
				last_request_date: action.last_request_date
			};
		case 'RECEIVE_CONNECTED_USERS':
			// Only accept the incoming data if it matches the most recent request. This ensures we correctly
			// handle out-of-order responses.
			return {
				connectedUsers: action.connectedUsers,
				isLoading: false,
				last_request_date: action.last_request_date
			};
			break;
	}

	return state;
};
