import * as Companies from './Companies';
import * as ConnectedUsers from './ConnectedUsers';
import * as Dashboard from './Dashboard';
import * as FilingSeriesClasses from './FilingSeriesClasses';
import * as ProxyFilingData from './ProxyData';
import * as Filings from './Filings';
import * as OwnershipFilings from './OwnershipFilings';
import * as Report from './Report';
import * as OwnershipFilingData from './OwnershipData';

// The top-level state object
export interface ApplicationState {
	connectedUsers: ConnectedUsers.ConnectedUsersState | undefined;
	dashboardData: Dashboard.DashboardState | undefined;
	filings: Filings.FilingsState | undefined;
	ownershipFilings: OwnershipFilings.OwnershipFilingsState | undefined;
	ownershipFilingData: OwnershipFilingData.OwnershipDataState | undefined;
	proxyFilingData: ProxyFilingData.ProxyDataState | undefined;
	filingSeriesClasses: FilingSeriesClasses.FilingSeriesClassesState | undefined;
	companies: Companies.CompaniesState | undefined;
	reportState: Report.ReportState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
	connectedUsers: ConnectedUsers.reducer,
	dashboardData: Dashboard.reducer,
	filings: Filings.reducer,
	ownershipFilings: OwnershipFilings.reducer,
	ownershipFilingData: OwnershipFilingData.reducer,
	proxyFilingData: ProxyFilingData.reducer,
	filingSeriesClasses: FilingSeriesClasses.reducer,
	companies: Companies.reducer,
	reportState: Report.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
	(dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
