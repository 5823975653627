import * as React from 'react';
import BootstrapTable, { SelectRowProps } from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory, { dateFilter, selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { ApplicationState } from '../store';
import { dateToString, filingDocumentLink, filingIndexLink, formatNumber, ownershipFilingLink } from '../store/Util';
import * as OwnershipFilingsStore from '../store/OwnershipFilings';
import { FaRegEye } from 'react-icons/fa';
import { BsX } from 'react-icons/bs';

const { SearchBar, ClearSearchButton } = Search;

// At runtime, Redux will merge together...
type OwnershipFilingProps =
	OwnershipFilingsStore.OwnershipFilingsState // ... state we've requested from the Redux store
	& typeof OwnershipFilingsStore.actionCreators // ... plus action creators we've requested
	& RouteComponentProps<{ cik: string }>; // ... plus incoming routing parameters

const searchRowControls = ''; // '.search-label, .advanced-search, .clear-button';
const filterControls = '.filter-label, .filter';

let dateFiledFilter: any;
let formTypeFilter: any;
let descriptionFilter: any;
let cusipFilter: any;
let subjectCompanyFilter: any;
let filedByFilter: any;
let groupMembersFilter: any;

const ClearButton = (props: any) => {
	const handleClick = () => {
		props.onSearch("");
		props.clearAllFilters();
	};
	return (
		<button
			className="btn btn-outline-primary btn-sm ml-4 clear-button"
			onClick={handleClick}
		>
			Clear
		</button>
	);
};

class FetchOwnershipData extends React.PureComponent<OwnershipFilingProps, { expanded: string[] }> {
	cik?: string;

	MyExportCSV = (props: any) => {
		const handleClick = () => {
			props.onExport(props.data);
		};
		return (
			<img src={"microsoft-excel.svg"} height="30px" onClick={handleClick} className="float-right pr-4 mt-1" role="button" title="Export Proxy Filing Data to Excel (.csv)" />
		);
	}

	ShowHideSearchRowIcon = () => {
		return (
			<img src={"magnifying-glass.png"} height="30px" onClick={this.showHideSearchRow} className="ml-4" role="button" title="Show/Hide Search Tools" />
		);
	}

	// Show Advanced Search Button
	ShowHideTableColumnFiltersButton = () => {
		return (
			<button className="btn btn-outline-primary btn-sm ml-4 advanced-search" onClick={this.showHideFilters} title="Toggle Advanced Search (Show/Hide Column Filters)">Advanced Search</button>
		);
	}

	showHideSearchRow = () => {
		var hide = false;
		// Hide Search Row
		document.querySelectorAll(searchRowControls).forEach(function (el) {
			if (el.classList.contains('d-none')) {
				el.classList.remove('d-none');
			} else {
				el.classList.add('d-none');
				hide = true;
			}
		});
		if (!hide) return;
		// Hide Filters
		this.hideFilters();
	};

	showHideFilters = () => {
		document.querySelectorAll(filterControls).forEach(function (el) {
			if (el.classList.contains('d-none')) {
				el.classList.remove('d-none');
			} else {
				el.classList.add('d-none');
			}
		});
	};

	hideSearchRow = () => {
		document.querySelectorAll(searchRowControls).forEach(function (el) {
			if (!el.classList.contains('d-none')) {
				el.classList.add('d-none');
			}
		});
	};

	hideFilters = () => {
		document.querySelectorAll(filterControls).forEach(function (el) {
			if (!el.classList.contains('d-none')) {
				el.classList.add('d-none');
			}
		});
	};

	clearAllFilters() {
		dateFiledFilter("");
		formTypeFilter("");
		descriptionFilter("");
		cusipFilter("");
		subjectCompanyFilter("");
		filedByFilter("");
		groupMembersFilter("");

		const comparators = '.number-filter-comparator, .date-filter-comparator';
		document.querySelectorAll(comparators).forEach(function (el) {
			(el as HTMLSelectElement).value = "=";
		});
	}

	// This method is called when the component is first added to the document
	static contextType = AppContext;
	context!: React.ContextType<typeof AppContext>;

	defaultFamilies: any[] = [];

	constructor(props: any) {
		super(props);
		this.state = { expanded: [] };
	}

	public componentDidMount() {
		this.props.resetState();
		this.ensureDataFetched();
		this.hideFilters();
		this.context.changeTitle('Ownership Filings');
	}

	// This method is called when the route parameters change
	public componentDidUpdate() {
		this.ensureDataFetched();
	}

	public render() {
		return (
			<React.Fragment>
				<div className="col-12 overflow-auto">
					<div className="position-absolute">
						{/*<h1 id="tabelLabel">Proxy Filings</h1>*/}
						{this.renderProxyFilingsTable()}
					</div>
				</div>
			</React.Fragment>
		);
	}

	private ensureDataFetched() {
		const cik = this.context.getOwnershipFilterCik();
		let year = this.context.getOwnershipFilterYear();
		if (!cik && !year) {
			year = new Date().getFullYear().toString(); // Default to Current Year
			this.context.setOwnershipFilter("", year);
		}
		this.props.requestOwnershipFilings(cik, year);
	}

	private renderFilterDescription() {
		const cik = this.context.getOwnershipFilterCik();
		const year = this.context.getOwnershipFilterYear();
		//debugger;
		//alert(this.props.fund);
		// <span>Proxy Filings for {meetingType} Meetings by Solicitor {solicitorUrl(solicitor)} in {year}
		const cikDesc = cik ? <>CIK: <Link to={"classes/cik/" + cik} onClick={(e) => e.stopPropagation()}>{cik}</Link></> : '';
		const yearDesc = year && year != '-1' ? ` in ${year}` : '';
		const forv = cik ? ' for ' : '';
		const yearTodayDesc = !(cik || year) ? ` Today` : '';
		const clearFilterButton = year != '-1' ? <button type="button" className="close float-none text-danger ml-1" aria-label="Close" onClick={this.onClearFilter}><BsX></BsX></button> : '';
		return (<React.Fragment>{
			<span>Ownership Filings {forv} {cikDesc} {yearTodayDesc} {yearDesc}
				{clearFilterButton}
			</span>
		}</React.Fragment>);
	}

	public buildFilterDesc() {
		const cik = this.context.getOwnershipFilterCik();
		const year = this.context.getOwnershipFilterYear();
		const cikDesc = cik ? `CIK: ${cik}` : '';
		const yearDesc = year ? ` in ${year}` : '';
		const forv = cik ? ' for ' : '';
		const yearTodayDesc = !(cik || year) ? ` Today` : '';

		return `Ownership Filings ${forv} ${cikDesc} ${yearTodayDesc} {familyDesc} ${yearDesc}`;
	}

	onClearFilter = (event: any) => {
		this.cik = "";
		const family = '%'; // this.context.getOwnershipFilterFamily() || '%'; //  || "Columbia%"
		this.context.setOwnershipFilter("", "-1");
		this.props.requestOwnershipFilings("", "-1");
	}

	// Custom match logic on every cell value
	private onColumnMatch(opts: any) {
		const getNodeText: any = (node: any) => {
			if (['string', 'number'].includes(typeof node)) return node;
			if (node instanceof Array) return node.map(getNodeText).join('');
			if (typeof node === 'object' && node) return getNodeText(node.props.children);
		}
		var targetValue = getNodeText(opts.value);
		if (targetValue !== null && typeof targetValue !== 'undefined') {
			targetValue = targetValue.toString().toLowerCase();
			if (targetValue.indexOf(opts.searchText) > -1) {
				return true;
			}
		}
	}

	private emptyDataMessage = () => {
		if (this.props.isLoading)
			return 'Loading...';
		return 'No Data to Display';
	}

	private renderProxyFilingsTable() {
		//debugger;

		const selectRow: SelectRowProps<any> = {
			mode: 'checkbox',
			clickToSelect: true,
			hideSelectColumn: true,
			bgColor: 'lightgray' // '#00BFFF'
		};

		const formTypeOptions = {
			'SC 13D': 'SC 13D',
			'SC 13D/A': 'SC 13D/A',
			'SC 13G': 'SC 13G',
			'SC 13G/A': 'SC 13G/A'
		};

		var columns = [
			{
				dataField: 'dateFiled',
				text: 'Date',
				sort: true,
				align: "right",
				headerStyle: () => { return { width: '100px' }; },
				headerTitle: () => 'Date Filed on SEC EDGAR',
				//title: () => 'Date Filed on SEC EDGAR',
				filter: dateFilter({
					withoutEmptyComparatorOption: true,
					getFilter: (filter: any) => {
						dateFiledFilter = filter;
					}
				}),
				filterValue: (cell: any, row: any): any => { // Time Zone is missing in Dates received from server
					return cell + 'Z';
				},
				formatter: (cell: any, row: any) => dateToString(cell),
				csvFormatter: (cell: any) => dateToString(cell)
			},
			{
				dataField: 'formType',
				text: 'Form Type',
				sort: true,
				headerStyle: () => { return { width: '100px' }; },
				headerTitle: () => "SEC Form Type",
				title: (cell: any) => `View this ${cell} Filing`,
				filter: selectFilter({
					options: formTypeOptions,
					getFilter: (filter: any) => {
						formTypeFilter = filter;
					}
				}),
				//filter: textFilter({
				//	getFilter: (filter: any) => {
				//		formTypeFilter = filter;
				//	}
				//}),
				formatter: (cell: any, row: any) => filingIndexLink(row.fileName, row.san, cell),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'description',
				text: 'Document',
				sort: true,
				headerTitle: () => "Document Description",
				hidden: true,
				filter: textFilter({
					getFilter: (filter: any) => {
						descriptionFilter = filter;
					}
				}),
				csvExport: false,
				formatter: (cell: any, row: any) => filingDocumentLink(row.fileName, row.documentFileName, row.san, cell),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'cusiPs',
				text: 'CUSIP',
				sort: true,
				filter: textFilter({
					getFilter: (filter: any) => {
						cusipFilter = filter;
					}
				}),
				//csvType: String, // String, Number
				formatter: (cell: any, row: any) => { return cell ? cell.replaceAll(",", ", ") : ''; },
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'subjectCompany',
				text: 'Subject',
				sort: true,
				headerTitle: () => "Subject Company",
				filter: textFilter({
					getFilter: (filter: any) => {
						subjectCompanyFilter = filter;
					}
				}),
				//formatter: (cell: any, row: any) => filingDocumentLink(row.fileName, row.documentFileName, row.san, cell),
				formatter: (cell: any, row: any) => {
					return (
						cell ? <Link to={"ownership-filings"} onClick={() => this.setOwnershipFilter(row.subjectCompanyCik, "")}>{cell}</Link> : ''
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'filedBy',
				text: 'Filed By',
				sort: true,
				headerTitle: () => "Filed By",
				filter: textFilter({
					getFilter: (filter: any) => {
						filedByFilter = filter;
					}
				}),
				//formatter: (cell: any, row: any) => ownershipFilingLink(row.san, cell),
				formatter: (cell: any, row: any) => {
					return (
						cell ? <Link to={"ownership-filings"} onClick={() => this.setOwnershipFilter(row.filedByCik, "")}>{cell}</Link> : ''
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'groupMembers',
				text: 'Group Members',
				sort: true,
				headerTitle: () => "Group Members",
				filter: textFilter({
					getFilter: (filter: any) => {
						groupMembersFilter = filter;
					}
				}),
				formatter: (cell: any, row: any) => filingDocumentLink(row.fileName, row.documentFileName, row.san, cell),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'san',
				text: 'Actions',
				align: "center",
				//style: { width: '100px' },
				headerStyle: () => { return { width: '100px', 'text-align': 'center' }; },
				csvExport: false,
				searchable: false,
				formatter: (cell: any, row: any) => {
					let viewBtn = <Link className='btn btn-outline-primary btn-sm' to={`/ownership-filing/${cell}`} title="View Ownership Data"><FaRegEye /></Link>;
					return (<>{viewBtn}</>);
				},
			}
		];

		return (
			<ToolkitProvider
				keyField="san"
				data={this.props.ownershipFilings || []}
				columns={columns}
				search={{
					searchFormatted: true,
					onColumnMatch: this.onColumnMatch
				}}
				exportCSV={{
					// TODO: Handle Special Characters like Smart Quotes
					fileName: 'Ownership.csv',
					//separator: '|',
					//ignoreHeader: true,
					//noAutoBOM: true,
					blobType: 'text/csv;charset=utf-8'
				}}
			>{
					props => (
						<div>
							<p>{this.props.ownershipFilings.length ? formatNumber(this.props.ownershipFilings.length) + " " : ""}
								{this.renderFilterDescription()}
								{this.props.ownershipFilings.length ? <this.MyExportCSV {...props.csvProps} /> : ""}
								<SearchBar
									{...props.searchProps}
									className="ml-4"
									style={{ width: "400px", height: "40px", background: "url(magnifying-glass.png) 370px no-repeat", backgroundSize: "20px 20px", paddingRight: "30px" }}
								/>
								{this.ShowHideTableColumnFiltersButton()}
								<ClearButton
									{...props.searchProps}
									clearAllFilters={this.clearAllFilters}
								/>
							</p>
							{/*<ClearSearchButton {...props.searchProps} className='btn btn-outline-primary btn-sm ml-2 clear-button' onClear={() => this.clearAllFilters()} />*/}
							<BootstrapTable
								{...props.baseProps}
								//keyField="san"
								//data={this.props.filings}
								//columns={columns}
								selectRow={selectRow}
								bootstrap4={true}
								striped={true}
								bordered={false}
								headerClasses="table-header-class"
								classes={"unset-width"}
								noDataIndication={this.emptyDataMessage}
								hover
								//condensed
								defaultSorted={[{
									dataField: 'dateFiled', // if dataField is not match to any column you defined, it will be ignored.
									order: 'desc' // desc or asc
								}]}
								filter={filterFactory()}
							/>
						</div>
					)
				}
			</ToolkitProvider>
		);
	}

	private setOwnershipFilter(cik?: number, year: string = "") {
		this.context.setOwnershipFilter(cik ? cik.toString() : "", year);
	}
}

export default connect(
	(state: ApplicationState) => state.ownershipFilings, // Selects which state properties are merged into the component's props
	OwnershipFilingsStore.actionCreators // Selects which action creators are merged into the component's props
)(FetchOwnershipData as any); // eslint-disable-line @typescript-eslint/no-explicit-any
