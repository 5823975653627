import * as React from 'react';
import BootstrapTable, { ExpandRowProps, SelectRowProps } from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory, { Comparator, dateFilter, numberFilter, selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { ApplicationState } from '../store';
import { dateToMDString, dateToString, fetchWithAuth, filingDocumentLink, filingIndexLink, formatMoney, formatNumber, sanClassesLink, sanFundsLink, solicitorUrl } from '../store/Util';
import * as FilingsStore from '../store/Filings';
import { FaEdit, FaRegEye, FaRegTrashAlt } from 'react-icons/fa';
import { BsX } from 'react-icons/bs';
import { replace } from 'lodash';
import { BiAddToQueue } from "react-icons/bi";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import TextareaAutosize from 'react-textarea-autosize';

const { SearchBar, ClearSearchButton } = Search;

// At runtime, Redux will merge together...
type FilingProps =
	FilingsStore.FilingsState // ... state we've requested from the Redux store
	& typeof FilingsStore.actionCreators // ... plus action creators we've requested
	& RouteComponentProps<{ cik: string }>; // ... plus incoming routing parameters

const searchRowControls = ''; // '.search-label, .advanced-search, .clear-button';
const filterControls = '.filter-label, .filter';

let dateFiledFilter: any;
let formTypeFilter: any;
let descriptionFilter: any;
let companyNamesFilter: any;
let seriesFilter: any;
let classesFilter: any;
let recordDateFilter: any;
let meetingDateFilter: any;
let meetingTypeFilter: any;
let routineFilter: any;
let costFilter: any;
let costPaidByFilter: any;
let solicitorFilter: any;
let fundCounselFilter: any;
let proposalsFilter: any;

const ClearButton = (props: any) => {
	const handleClick = () => {
		props.onSearch("");
		props.clearAllFilters();
	};
	return (
		<button
			className="btn btn-outline-primary btn-sm ml-4 clear-button"
			onClick={handleClick}
		>
			Clear
		</button>
	);
};

class FetchData extends React.PureComponent<FilingProps, { expanded: string[], addFilingDialogState?: any }> {
	cik?: string;

	MyExportCSV = (props: any) => {
		const handleClick = () => {
			props.onExport(props.data);
		};
		return (
			<img src={"microsoft-excel.svg"} height="30px" onClick={handleClick} className="float-right pr-4 mt-1" role="button" title="Export Proxy Filing Data to Excel (.csv)" />
		);
	}

	MyEML = (props: any) => {
		const handleClick = () => {
			//debugger;
			props.onEmail.call(this, props.data);
		};
		return (
			<img src={"email.png"} height="30px" onClick={handleClick} className="float-right pr-4 mt-1" role="button" title="Email Filing Data" />
		);
	}

	MyAddProxy = (props: any) => {
		const handleClick = () => {
			//debugger;
		};
		return (
			<Link to={'/proxies'} className='float-right mr-4 btn btn btn-outline-primary btn-sm mt-1' onClick={(e: any) => this.addProxy(e)} title="Add Proxy Filing"><BiAddToQueue /></Link>
		);
	}


	addProxy = (e: MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({ addFilingDialogState: { open: true, processing: false, processed: false } });
	}

	onEmail(data: any) {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data),
			responseType: 'blob'
		};

		fetchWithAuth({ url: 'api/edgar/proxiesemail', queryParams: { filter: this.buildFilterDesc() } }, requestOptions)
			.then(response => {
				return response.blob();
			})
			.then(response => {
				let url = window.URL.createObjectURL(response);
				let a = document.createElement('a');
				a.href = url;
				a.download = 'ProxiesToday.eml';
				a.click();
				a.remove();
				//a.parentNode?.removeChild(a);
			});
		//debugger;
	}

	ShowHideSearchRowIcon = () => {
		return (
			<img src={"magnifying-glass.png"} height="30px" onClick={this.showHideSearchRow} className="ml-4" role="button" title="Show/Hide Search Tools" />
		);
	}

	// Show Advanced Search Button
	ShowHideTableColumnFiltersButton = () => {
		return (
			<button className="btn btn-outline-primary btn-sm ml-4 advanced-search" onClick={this.showHideFilters} title="Toggle Advanced Search (Show/Hide Column Filters)">Advanced Search</button>
		);
	}

	showHideSearchRow = () => {
		var hide = false;
		// Hide Search Row
		document.querySelectorAll(searchRowControls).forEach(function (el) {
			if (el.classList.contains('d-none')) {
				el.classList.remove('d-none');
			} else {
				el.classList.add('d-none');
				hide = true;
			}
		});
		if (!hide) return;
		// Hide Filters
		this.hideFilters();
	};

	showHideFilters = () => {
		document.querySelectorAll(filterControls).forEach(function (el) {
			if (el.classList.contains('d-none')) {
				el.classList.remove('d-none');
			} else {
				el.classList.add('d-none');
			}
		});
	};

	hideSearchRow = () => {
		document.querySelectorAll(searchRowControls).forEach(function (el) {
			if (!el.classList.contains('d-none')) {
				el.classList.add('d-none');
			}
		});
	};

	hideFilters = () => {
		document.querySelectorAll(filterControls).forEach(function (el) {
			if (!el.classList.contains('d-none')) {
				el.classList.add('d-none');
			}
		});
	};

	clearAllFilters() {
		dateFiledFilter("");
		formTypeFilter("");
		descriptionFilter("");
		companyNamesFilter("");
		seriesFilter("");
		classesFilter("");
		recordDateFilter("");
		meetingDateFilter("");
		meetingTypeFilter("");
		routineFilter("");
		costFilter("");
		costPaidByFilter("");
		solicitorFilter("");
		fundCounselFilter("");
		proposalsFilter("");

		const comparators = '.number-filter-comparator, .date-filter-comparator';
		document.querySelectorAll(comparators).forEach(function (el) {
			(el as HTMLSelectElement).value = "=";
		});
	}

	// This method is called when the component is first added to the document
	static contextType = AppContext;
	context!: React.ContextType<typeof AppContext>;

	defaultFamilies: any[] = [];

	constructor(props: any) {
		super(props);
		this.state = { expanded: [], addFilingDialogState: { open: false, processing: false, processed: false } };
	}

	public componentDidMount() {
		this.props.resetState();
		if (this.props.match.params.cik) {
			this.context.setProxyFilter(this.props.match.params.cik, "", "", "", "", "");
		}
		this.loadFamilies();
	}

	private loadFamilies() {
		fetchWithAuth({ url: '/api/edgar/families' })
			.then(response => response.json())
			.then(data => {
				this.defaultFamilies = data;
				this.ensureDataFetched();
				this.context.changeTitle('Proxy Filings');
				//this.hideSearchRow();
				this.hideFilters();
			})
			.catch(error => {
				console.error('There was an error!', error);
			});
	}

	// This method is called when the route parameters change
	public componentDidUpdate() {
		this.ensureDataFetched();
	}

	public render() {
		return (
			<React.Fragment>
				<div className="col-12 overflow-auto">
					<div className="position-absolute">
						{/*<h1 id="tabelLabel">Proxy Filings</h1>*/}
						{this.renderProxyFilingsTable()}
					</div>
				</div>
				{/*{this.renderPagination()}*/}
				{this.renderAddFilingDialog()}
			</React.Fragment>
		);
	}

	private renderAddFilingDialog() {
		return (
			<Modal isOpen={this.state.addFilingDialogState?.open} returnFocusAfterClose={false}
				centered={true} keyboard={true} onHide={() => this.closeAddFilingModal(false)}>
				{this.state.addFilingDialogState?.processing ?
					<><ModalHeader>
						Add Filings
					</ModalHeader>
						<ModalBody>
							<div className="processing-container">
								<div className="spinner"></div>
								<div className="spinner-text">Please wait</div>
							</div>
						</ModalBody></>
					:
					this.state.addFilingDialogState?.processed ?
						<><ModalHeader toggle={() => this.closeAddFilingModal(false)} >
							Add Filings
						</ModalHeader>
							<ModalBody>
								<div className="pl-5 pr-5">
									{this.state.addFilingDialogState?.errorMsg
										?
										<div className="row text-danger">
											{this.state.addFilingDialogState?.errorMsg }
										</div>
										:
										<></>
									}
									{this.state.addFilingDialogState?.data?.added?.length
										?
										<div className="row text-primary">
											Added: {this.state.addFilingDialogState?.data?.added?.join(', ')}
										</div>
										:
										<></>
									}
									{this.state.addFilingDialogState?.data?.exists?.length
										?
										<div className="row text-secondary">
											Already Exists: {this.state.addFilingDialogState?.data?.exists?.join(', ')}
										</div>
										:
										<></>
									}
									{this.state.addFilingDialogState?.data?.notFound?.length
										?
										<div className="row text-danger">
											Not Found: {this.state.addFilingDialogState?.data?.notFound?.join(', ')}
										</div>
										:
										<></>
									}
								</div>
							</ModalBody>
							<ModalFooter>
								<Button onClick={(e) => this.closeAddFilingModal(false, e)} color="primary">
									Ok
								</Button>
							</ModalFooter></>
						:
						<><form onSubmit={(value: any) => { this.closeAddFilingModal(true, value); }}>
							<ModalHeader toggle={() => this.closeAddFilingModal(false)} >
								Add Filings
							</ModalHeader>
							<ModalBody>
								<div className="row">
									<div className="col">
										<div className="form-group">
											<label htmlFor="addFilingVal">Please enter filing SAN(s) separated by comma:</label>
											<TextareaAutosize
												//cacheMeasurements
												minRows={1}
												maxRows={19}
												id="addFilingVal"
												name="addFilingVal"
												className="form-control"
												placeholder="Enter SAN(s)"
												title="Enter comma separated SEC Filing Accession Numbers"
											/>
										</div>
									</div>
								</div>
							</ModalBody>
							<ModalFooter>
								<Button
									color="danger"
									onClick={(e) => this.closeAddFilingModal(false, e)}
								>
									Cancel
								</Button>
								{' '}
								<Button type="submit" color="primary">
									Add
								</Button>
							</ModalFooter>
						</form></>
				}
			</Modal>
		);
	}

	private closeAddFilingModal(userAction: boolean, e?: any) {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		if (userAction) {
			let value = (document.getElementById('addFilingVal') as HTMLTextAreaElement)?.value;
			if (value) {
				this.setState({ addFilingDialogState: { open: true, processing: true, processed: false } });
				value = value.replace('\n', ',').replace('\r', ',');
				let sans = value.trim().split(',');

				const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(sans)
				};

				fetchWithAuth({ url: `api/edgar/add-filings` }, requestOptions)
					.then(response => {
						if (response.ok) {
							return response.json() as Promise<any>;
						}
						throw new Error('Error. Please try again later.');
					})
					.then(data => {
						this.setState({ addFilingDialogState: { open: true, processing: false, processed: true, data: data } });
					})
					.catch((error) => {
						this.setState({ addFilingDialogState: { open: true, processing: false, processed: true, errorMsg: 'Error. Please try again later.' } });
					});

				//setTimeout(() => {
				//	this.setState({ openAddFilingDialog: false, processingAddFiling: false });
				//}, 5000);
			}
		}
		else {
			this.setState({ addFilingDialogState: { open: false, processing: false, processed: false } });
		}
	}

	private ensureDataFetched() {
		const fund = this.context.getProxyFilterFamily() || '%'; //  || "Columbia%"
		const classId = this.context.getProxyFilterClassId();
		const seriesId = this.context.getProxyFilterSeriesId();
		const cik = this.context.getProxyFilterCik();
		const year = this.context.getProxyFilterYear();
		const solicitor = this.context.getProxyFilterSolicitor();
		const meetingType = this.context.getProxyFilterMeetingType();
		const meetingDate = this.context.getProxyFilterMeetingDate();
		const routine = this.context.getProxyFilterRoutine();
		const openOrClosedCIK = this.context.getProxyFilterOpenOrClosedCIK();
		this.props.requestFilings(fund, cik, year, solicitor, meetingType, meetingDate, routine, openOrClosedCIK, classId, seriesId);
	}

	private renderFundDropdown() {
		const fund = this.context.getProxyFilterFamily() || '%'; //  || "Columbia%"
		const classId = this.context.getProxyFilterClassId();
		const seriesId = this.context.getProxyFilterSeriesId();
		const cik = this.context.getProxyFilterCik();
		const year = this.context.getProxyFilterYear();
		const solicitor = this.context.getProxyFilterSolicitor();
		const meetingType = this.context.getProxyFilterMeetingType();
		const meetingDate = this.context.getProxyFilterMeetingDate();
		const routine = this.context.getProxyFilterRoutine();
		const routineDesc = routine == 'R' ? 'Routine' : routine == 'N' ? 'Non-Routine' : '';
		const openOrClosedCIK = this.context.getProxyFilterOpenOrClosedCIK();
		const openOrClosedCIKDesc = openOrClosedCIK == '0' ? 'for Open-end Investments ' : openOrClosedCIK == '1' ? ' for Closed-end Investments ' : openOrClosedCIK == '2' ? ' for ETFs ' : ''
		//const customFamilies: any[] = this.context.getFamilies();
		//debugger;
		//alert(this.props.fund);
		// <span>Proxy Filings for {meetingType} Meetings by Solicitor {solicitorUrl(solicitor)} in {year}
		const classIdDesc = classId ? <>Class: {classId}</> : '';
		const seriesIdDesc = seriesId ? <>Series: {seriesId}</> : '';
		const cikDesc = cik ? <>CIK: <Link to={"classes/cik/" + cik} onClick={(e) => e.stopPropagation()}>{cik}</Link></> : '';
		const fundDesc = fund != '%' ? ` ${fund}` : '';
		const yearDesc = year && year != "-1" ? ` in ${year}` : '';
		const solicitorDesc = solicitor ? <> by {solicitorUrl(replace(solicitor, 'Other', 'Other Solicitors'))}</> : ''; // Solicitor 
		const meetingDateDesc = meetingDate && new Date(meetingDate).getFullYear() != 1980 ? ` on ${dateToString(meetingDate)}` : '';
		const meetingDate1980Desc = meetingDate && new Date(meetingDate).getFullYear() == 1980 ? ` on ${dateToMDString(meetingDate)}` : '';
		const forv = classId || seriesId || cik || meetingType || meetingDate || routine || fund != '%' ? ' for ' : '';
		const meetings = meetingType || meetingDate || routine ? 'Meetings' : '';
		const yearTodayDesc = !(classId || seriesId || cik || year || solicitor || meetingType || meetingDate || routine || fund != '%') ? ` Today` : '';
		const clearFilterButton = !yearTodayDesc ? <button type="button" className="close float-none text-danger ml-1" aria-label="Close" onClick={this.onClearFilter}><BsX></BsX></button> : '';
		return (<React.Fragment>{
			<span>Proxy Filings {forv} {classIdDesc} {seriesIdDesc} {cikDesc} {yearTodayDesc} {routineDesc} {meetingType} {meetings} {fundDesc}
				{solicitorDesc} {meetingDateDesc} {meetingDate1980Desc} {yearDesc} {openOrClosedCIKDesc}
				{clearFilterButton}
			</span>
		}</React.Fragment>);
	}

	public buildFilterDesc() {
		const fund = this.context.getProxyFilterFamily() || '%'; //  || "Columbia%"
		const classId = this.context.getProxyFilterClassId();
		const seriesId = this.context.getProxyFilterSeriesId();
		const cik = this.context.getProxyFilterCik();
		const year = this.context.getProxyFilterYear();
		const solicitor = this.context.getProxyFilterSolicitor();
		const meetingType = this.context.getProxyFilterMeetingType();
		const meetingDate = this.context.getProxyFilterMeetingDate();
		const routine = this.context.getProxyFilterRoutine();
		const routineDesc = routine == 'R' ? 'Routine' : routine == 'N' ? 'Non-Routine' : '';
		const openOrClosedCIK = this.context.getProxyFilterOpenOrClosedCIK();
		const openOrClosedCIKDesc = openOrClosedCIK == '0' ? 'for Open-end Investments ' : openOrClosedCIK == '1' ? ' for Closed-end Investments ' : ''
		const classIdDesc = classId ? <>Class: {classId}</> : '';
		const seriesIdDesc = seriesId ? <>Series: {seriesId}</> : '';
		const cikDesc = cik ? `CIK: ${cik}` : '';
		const fundDesc = fund != '%' ? ` ${fund}` : '';
		const yearDesc = year ? ` in ${year}` : '';
		const solicitorDesc = solicitor ? ` by ${replace(solicitor, 'Other', 'Other Solicitors')}` : ''; // Solicitor 
		const meetingDateDesc = meetingDate && new Date(meetingDate).getFullYear() != 1980 ? ` on ${dateToString(meetingDate)}` : '';
		const meetingDate1980Desc = meetingDate && new Date(meetingDate).getFullYear() == 1980 ? ` on ${dateToMDString(meetingDate)}` : '';
		const forv = classId || seriesId || cik || meetingType || meetingDate || routine || fund != '%' ? ' for ' : '';
		const meetings = meetingType || meetingDate || routine ? 'Meetings' : '';
		const yearTodayDesc = !(classId || seriesId || cik || year || solicitor || meetingType || meetingDate || routine || fund != '%') ? ` Today` : '';

		return `Proxy Filings ${forv} ${classIdDesc} ${seriesIdDesc} ${cikDesc} ${yearTodayDesc} ${routineDesc} ${meetingType} ${meetings} ${fundDesc} ${solicitorDesc} ${meetingDateDesc} ${meetingDate1980Desc} ${yearDesc} ${openOrClosedCIKDesc}`;
	}

	// Get Dropdown Options of Default and Custom Families
	getDefaultAndCustomFamilies = () => {
		const customFamilies: any[] = this.context.getFamilies();
		var options = customFamilies.map((customFamily) => {
			return (<option value={customFamily.ciks}>{customFamily.name}</option>);
		}, this);
		this.defaultFamilies.map((defaultFamily) => {
			options.push(<option value={defaultFamily.name}>{defaultFamily.name}</option>);
		}, this);
		return options;
	}

	onClearFilter = (event: any) => {
		this.cik = "";
		const fund = '%'; // this.context.getProxyFilterFamily() || '%'; //  || "Columbia%"
		this.context.setProxyFilter("", fund, "", "", "", "");
		this.props.requestFilings(fund, "");
	}

	onSendEmail = (event: any) => {
		this.cik = "";
		const fund = this.context.getProxyFilterFamily() || '%'; //  || "Columbia%"
		this.context.setProxyFilter("", fund, "", "", "", "");
		this.props.requestFilings();
	}

	onChangeFund = (event: any) => {
		this.cik = "";
		//this.setState({ fund: event.target.value, cik: 'xyz' });
		this.context.setProxyFilter(this.cik, event.target.value, "", "", "", "");
		this.props.requestFilings(event.target.value, this.cik);
		//this.props.history.push('/proxies');
	}

	//onEditFund = (event: any) => {
	//	const fund = this.context.getProxyFilterFamily() || '%'; //  || "Columbia%"
	//	const cik = this.context.getProxyFilterCik();
	//	this.context.setProxyFilter(cik, fund);
	//}

	private formatProposal(proposal: string, index: number) {
		if (!proposal && index > 1) return "";
		return (<p><b>Proposal {index}</b>: {proposal || "-"}</p>);
	}

	confirmDeleteProxy = (e: MouseEvent, san: string) => {
		e.preventDefault();
		e.stopPropagation();
		this.context.showConfirm("Are you sure?", "Delete Proxy Filing", this.deleteProxy, { san: san });
	}

	deleteProxy = (userAction: boolean, data?: any) => {
		if (userAction) {
			this.props.deleteProxy(data.san);
		}
	}

	showProposal = (e: MouseEvent, san: string) => {
		e.preventDefault();
		e.stopPropagation();
		//console.log('Before:' + this.state.expanded.join(','));
		if (this.state.expanded.includes(san)) {
			this.setState(() => ({
				expanded: this.state.expanded.filter(x => x != san) // []
			}));
		} else {
			//let expanded = this.state.expanded;
			//expanded.push(san);
			this.state.expanded.push(san);
			this.setState(() => ({
				expanded: [...this.state.expanded] // [san]
			}));
		}
		//console.log('After:' + this.state.expanded.join(','));
	}

	// Custom match logic on every cell value
	private onColumnMatch(opts: any) {
		const getNodeText: any = (node: any) => {
			if (['string', 'number'].includes(typeof node)) return node;
			if (node instanceof Array) return node.map(getNodeText).join('');
			if (typeof node === 'object' && node) return getNodeText(node.props.children);
		}
		var targetValue = getNodeText(opts.value);
		if (targetValue !== null && typeof targetValue !== 'undefined') {
			targetValue = targetValue.toString().toLowerCase();
			if (targetValue.indexOf(opts.searchText) > -1) {
				return true;
			}
		}
	}

	private emptyDataMessage = () => {
		if (this.props.isLoading)
			return 'Loading...';
		return 'No Data to Display';
	}

	private renderProxyFilingsTable() {
		//debugger;
		const nonExpandable: string[] = this.props.filings.map(row => row.proposal1 ? "" : row.san);
		const expandRow: ExpandRowProps<any, string> = {
			showExpandColumn: false,
			onlyOneExpanding: false,
			expandByColumnOnly: true,
			expandColumnPosition: 'right',
			nonExpandable: nonExpandable,
			renderer: (row: any) => (
				<div>
					{this.formatProposal(row.proposal1, 1)}
					{this.formatProposal(row.proposal2, 2)}
					{this.formatProposal(row.proposal3, 3)}
					{this.formatProposal(row.proposal4, 4)}
					{this.formatProposal(row.proposal5, 5)}
					{this.formatProposal(row.proposal6, 6)}
					{this.formatProposal(row.proposal7, 7)}
				</div>
			),
			expanded: this.state.expanded,
			//expandColumnRenderer: ({ expanded }: any) => {
			//	if (expanded) {
			//		console.log(expanded);
			//		console.timeLog(expanded.expanded);
			//		return (
			//			<b>-</b>
			//		);
			//	}
			//	return (
			//		<b>+</b>
			//	);
			//},
			//expandHeaderColumnRenderer: ({ isAnyExpands }: any) => {
			//	if (isAnyExpands) {
			//		return <b>-</b>;
			//	}
			//	return <b>+</b>;
			//}
		};

		const selectRow: SelectRowProps<any> = {
			mode: 'checkbox',
			clickToSelect: true,
			hideSelectColumn: true,
			bgColor: 'lightgray' // '#00BFFF'
		};

		const formTypeOptions = {
			'DEF 14A': 'DEF 14A',
			'DEFA14A': 'DEFA14A',
			'DEFC14A': 'DEFC14A',
			'DEFM14A': 'DEFM14A',
			'DEFR14A': 'DEFR14A',
			'N-14': 'N-14',
			'N-14/A': 'N-14/A',
			'N-14 8C': 'N-14 8C',
			'N-14 8C/A': 'N-14 8C/A',
			'PRE 14A': 'PRE 14A',
			'PREC14A': 'PREC14A',
			'PREM14A': 'PREM14A',
			'PRER14A': 'PRER14A',
			'497': '497'
		};

		const defFormTypeOptions = {
			'DEF 14A': 'DEF 14A',
			'N-14': 'N-14',
			'N-14 8C': 'N-14 8C'
		};

		const meetingTypeOptions = {
			'Annual': 'Annual',
			'Special': 'Special'
		};

		const routineOptions = {
			//'': '<Blank>',
			'R': 'Routine',
			'N': 'Non-Routine'
		};

		const proposalCountOptions = {
			1: '1',
			2: '2',
			3: '3',
			4: '4',
			5: '5',
			6: '6',
			7: '7'
		};

		var columns = [
			{
				dataField: 'dateFiled',
				text: 'Date',
				sort: true,
				align: "right",
				headerStyle: () => { return { width: '100px' }; },
				headerTitle: () => 'Date Filed on SEC EDGAR',
				//title: () => 'Date Filed on SEC EDGAR',
				filter: dateFilter({
					withoutEmptyComparatorOption: true,
					getFilter: (filter: any) => {
						dateFiledFilter = filter;
					}
				}),
				filterValue: (cell: any, row: any): any => { // Time Zone is missing in Dates received from server
					return cell + 'Z';
				},
				formatter: (cell: any, row: any) => dateToString(cell),
				csvFormatter: (cell: any) => dateToString(cell)
			},
			{
				dataField: 'formType',
				text: 'Form Type',
				sort: true,
				headerStyle: () => { return { width: '100px' }; },
				headerTitle: () => "SEC Form Type",
				title: (cell: any) => `View this ${cell} Filing`,
				filter: selectFilter({
					options: formTypeOptions,
					getFilter: (filter: any) => {
						formTypeFilter = filter;
					}
				}),
				//filter: textFilter({
				//	getFilter: (filter: any) => {
				//		formTypeFilter = filter;
				//	}
				//}),
				formatter: (cell: any, row: any) => filingIndexLink(row.fileName, row.san, cell),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'description',
				text: 'Document',
				sort: true,
				headerTitle: () => "Document Description",
				filter: textFilter({
					getFilter: (filter: any) => {
						descriptionFilter = filter;
					}
				}),
				formatter: (cell: any, row: any) => filingDocumentLink(row.fileName, row.documentFileName, row.san, cell),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'funds',
				text: 'Company Names',
				sort: true,
				filter: textFilter({
					getFilter: (filter: any) => {
						companyNamesFilter = filter;
					}
				}),
				csvFormatter: (cell: any) => cell ? cell.replaceAll(', ', '\n').replaceAll('\nLLC', ', LLC').replaceAll('\nInc', ', Inc').replaceAll('\nINC', ', INC') : ''
			},
			//{
			//	dataField: 'reportDate',
			//	text: 'Report Date',
			//	sort: true,
			//	align: "right",
			//	headerStyle: () => { return { width: '100px' }; },
			//	headerTitle: () => "Confirmed Period of Report",
			//	formatter: (cell: any) => dateToString(cell)
			//},
			{
				dataField: 'series',
				text: 'Funds',
				sort: true,
				align: "right",
				headerStyle: () => { return { width: '100px' }; },
				headerTitle: () => '# Funds in Proxy Filing',
				//title: (cell: any, row: any) => `View List of Funds in this ${row.formType} Filing`,
				filter: numberFilter({
					withoutEmptyComparatorOption: true,
					getFilter: (filter: any) => {
						seriesFilter = filter;
					}
				}),
				formatter: (cell: any, row: any) => sanFundsLink(row.san, cell),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'classes',
				text: 'Classes',
				sort: true,
				align: "right",
				headerStyle: () => { return { width: '100px' }; },
				headerTitle: () => '# Fund-Classes in Proxy Filing',
				//title: (cell: any, row: any) => `View List of Fund-Classes in this ${row.formType} Filing`,
				filter: numberFilter({
					withoutEmptyComparatorOption: true,
					getFilter: (filter: any) => {
						classesFilter = filter;
					}
				}),
				formatter: (cell: any, row: any) => sanClassesLink(row.san, cell),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'recordDate',
				text: 'Record Date',
				sort: true,
				align: "right",
				headerStyle: () => { return { width: '100px' }; },
				filter: dateFilter({
					withoutEmptyComparatorOption: true,
					getFilter: (filter: any) => {
						recordDateFilter = filter;
					}
				}),
				filterValue: (cell: any, row: any): any => { // Default function fails for blank values
					return cell ? cell + 'Z' : new Date("1970-01-01");
				},
				formatter: (cell: any, row: any) => dateToString(cell),
				csvFormatter: (cell: any) => dateToString(cell)
			},
			{
				dataField: 'meetingDate',
				text: 'Meeting Date',
				sort: true,
				align: "right",
				headerStyle: () => { return { width: '100px' }; },
				filter: dateFilter({
					withoutEmptyComparatorOption: true,
					getFilter: (filter: any) => {
						meetingDateFilter = filter;
					}
				}),
				filterValue: (cell: any, row: any): any => { // Default function fails for blank values
					//console.log(meetingDateFilter);
					//debugger;
					return cell ? cell + 'Z' : new Date("1970-01-01");
				},
				formatter: (cell: any, row: any) => dateToString(cell),
				csvFormatter: (cell: any) => dateToString(cell)
			},
			{
				dataField: 'meetingType',
				text: 'Meeting Type',
				sort: true,
				align: "center",
				headerStyle: () => { return { width: '100px' }; },
				filter: selectFilter({
					options: meetingTypeOptions,
					getFilter: (filter: any) => {
						meetingTypeFilter = filter;
					}
				}),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'routine', // routine
				text: 'Routine?',
				sort: true,
				headerAlign: "center",
				align: "center",
				headerStyle: () => { return { width: '100px' }; },
				headerTitle: () => "Routine / Non-Routine Proxy Meeting",
				filter: selectFilter({
					options: routineOptions,
					getFilter: (filter: any) => {
						routineFilter = filter;
					}
				}),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'solicitor',
				text: 'Solicitor',
				sort: true,
				headerStyle: () => { return { width: '150px' }; },
				headerTitle: () => "Proxy Solicitor",
				title: (cell: any) => cell ? `Visit Website of ${cell}` : '',
				filter: textFilter({
					getFilter: (filter: any) => {
						solicitorFilter = filter;
					}
				}),
				formatter: (cell: any, row: any) => solicitorUrl(cell),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'cost',
				text: 'Cost',
				sort: true,
				align: "right",
				headerStyle: () => { return { width: '100px' }; },
				headerTitle: () => "Proxy Solicitation Cost",
				filter: textFilter({
					getFilter: (filter: any) => {
						costFilter = filter;
					}
				}),
				formatter: (cell: any, row: any) => formatMoney(cell),
				csvFormatter: (cell: any) => formatMoney(cell)
			},
			{
				dataField: 'costPaidBy',
				text: 'Payee',
				sort: true,
				headerStyle: () => { return { width: '100px' }; },
				headerTitle: () => "Proxy Solicitation Cost Paid By (Payee)",
				filter: textFilter({
					getFilter: (filter: any) => {
						costPaidByFilter = filter;
					}
				}),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'fundCounsel',
				text: 'Fund Counsel',
				sort: true,
				headerStyle: () => { return { width: '150px' }; },
				headerTitle: () => "Fund Counsel",
				filter: textFilter({
					getFilter: (filter: any) => {
						fundCounselFilter = filter;
					}
				}),
				formatter: (cell: any, row: any) => cell,
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				// TODO: Get Proposal Count from server
				dataField: 'proposals',
				isDummyField: true,
				text: 'Proposals',
				//sort: true,
				align: "center",
				headerStyle: () => { return { width: '80px' }; },
				headerTitle: () => "Proposals",
				filter: textFilter({
					//options: proposalCountOptions,
					//comparator: Comparator.LIKE,
					placeholder: 'Proposal',
					getFilter: (filter: any) => {
						proposalsFilter = filter;
					},
					onFilter: (filterValue) => {
						if (filterValue == null || filterValue == undefined || filterValue == "")
							return this.props.filings;
						return this.props.filings.filter(p => {
							let proposals = ['', p.proposal7, p.proposal6, p.proposal5, p.proposal4, p.proposal3, p.proposal2, p.proposal1].join(' ').toLowerCase().trim();
							return proposals.indexOf(filterValue.toLowerCase().trim()) >= 0;
						}
						);
					}
				}),
				formatter: (cell: any, row: any) => {
					if (row.proposal1)
						return (
							<Link to={'/proxies'} onClick={(e: any) => this.showProposal(e, row.san)}>{row.proposal7 ? 7 : row.proposal6 ? 6 : row.proposal5 ? 5 : row.proposal4 ? 4 : row.proposal3 ? 3 : row.proposal2 ? 2 : 1}</Link>
						);
				},
				csvFormatter: (cell: any, row: any) => row.proposal7 ? 7 : row.proposal6 ? 6 : row.proposal5 ? 5 : row.proposal4 ? 4 : row.proposal3 ? 3 : row.proposal2 ? 2 : row.proposal1 ? 1 : ''
			},
			{
				dataField: 'proposal1',
				text: 'Proposal 1',
				hidden: true,
				csvExport: true,
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proposal2',
				text: 'Proposal 2',
				hidden: true,
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proposal3',
				text: 'Proposal 3',
				hidden: true,
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proposal4',
				text: 'Proposal 4',
				hidden: true,
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proposal5',
				text: 'Proposal 5',
				hidden: true,
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proposal6',
				text: 'Proposal 6',
				hidden: true,
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proposal7',
				text: 'Proposal 7',
				hidden: true,
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'san',
				text: 'Actions',
				align: "center",
				//style: { width: '100px' },
				headerStyle: () => { return { width: '100px', 'text-align': 'center' }; },
				csvExport: false,
				searchable: false,
				formatter: (cell: any, row: any) => {
					let viewBtn = !this.context.hasUserRole('ProxyEdit') ? <Link className='btn btn-outline-primary btn-sm' to={`/view-data/${cell}`} title="View Proxy Data"><FaRegEye /></Link> : '';
					let editBtn = this.context.hasUserRole('ProxyEdit') ? <Link className='btn btn-outline-primary btn-sm' to={`/edit-data/${cell}`} title="Edit Proxy Data"><FaEdit /></Link> : '';
					let delBtn = this.context.hasUserRole('ProxyDelete') ? <Link to={'/proxies'} className='btn btn btn-outline-danger btn-sm' onClick={(e: any) => this.confirmDeleteProxy(e, row.san)} title="Delete Proxy Filing"><FaRegTrashAlt /></Link> : '';
					return (<>{viewBtn} {editBtn} {delBtn}</>);
				},
			}
		];

		return (
			<ToolkitProvider
				keyField="san"
				data={this.props.filings || []}
				columns={columns}
				search={{
					searchFormatted: true,
					onColumnMatch: this.onColumnMatch
				}}
				exportCSV={{
					// TODO: Handle Special Characters like Smart Quotes
					fileName: 'Proxies.csv',
					//separator: '|',
					//ignoreHeader: true,
					//noAutoBOM: true,
					blobType: 'text/csv;charset=utf-8'
				}}
			>{
					props => (
						<div>
							<p>{this.props.filings.length ? formatNumber(this.props.filings.length) + " " : ""}
								{this.renderFundDropdown()}
								{this.props.filings.length ? <this.MyExportCSV {...props.csvProps} /> : ""}
								{this.props.filings.length ? <this.MyEML {...{ data: this.props.filings, onEmail: this.onEmail }} /> : ""}
								{this.context.hasUserRole('ProxyDelete') ? <this.MyAddProxy /> : ""}
								{/*this.ShowHideSearchRowIcon()*/}
								<SearchBar
									{...props.searchProps}
									className="ml-4"
									style={{ width: "400px", height: "40px", background: "url(magnifying-glass.png) 370px no-repeat", backgroundSize: "20px 20px", paddingRight: "30px" }}
								/>
								{this.ShowHideTableColumnFiltersButton()}
								<ClearButton
									{...props.searchProps}
									clearAllFilters={this.clearAllFilters}
								/>
								<select onChange={this.onChangeFund} value={this.context.getProxyFilterFamily() || '%'} className="float-right mr-4 mt-1 form-control w-auto" style={{ maxWidth: "300px" }} title="Select Fund Family">
									<option value="%">Select Family ...</option>
									{this.getDefaultAndCustomFamilies()}
								</select>
							</p>
							{/*<ClearSearchButton {...props.searchProps} className='btn btn-outline-primary btn-sm ml-2 clear-button' onClear={() => this.clearAllFilters()} />*/}
							<BootstrapTable
								{...props.baseProps}
								//keyField="san"
								//data={this.props.filings}
								//columns={columns}
								expandRow={expandRow}
								selectRow={selectRow}
								bootstrap4={true}
								striped={true}
								bordered={false}
								headerClasses="table-header-class"
								classes={"unset-width"}
								noDataIndication={this.emptyDataMessage}
								hover
								//condensed
								defaultSorted={[{
									dataField: 'dateFiled', // if dataField is not match to any column you defined, it will be ignored.
									order: 'desc' // desc or asc
								}]}
								filter={filterFactory()}
							/>
						</div>
					)
				}
			</ToolkitProvider>
		);
	}

	private setProxyFilterYear(year: string = "") {
		this.context.setProxyFilter("", "", year, "", "", "");
	}

	//private renderPagination() {
	//  const prevStartDateIndex = (this.props.startDateIndex || 0) - 5;
	//  const nextStartDateIndex = (this.props.startDateIndex || 0) + 5;

	//  return (
	//    <div className="d-flex justify-content-between">
	//      <Link className='btn btn-outline-secondary btn-sm' to={`/proxies/${prevStartDateIndex}`}>Previous</Link>
	//      {this.props.isLoading && <span>Loading...</span>}
	//      <Link className='btn btn-outline-secondary btn-sm' to={`/proxies/${nextStartDateIndex}`}>Next</Link>
	//    </div>
	//  );
	//}
}

export default connect(
	(state: ApplicationState) => state.filings, // Selects which state properties are merged into the component's props
	FilingsStore.actionCreators // Selects which action creators are merged into the component's props
)(FetchData as any); // eslint-disable-line @typescript-eslint/no-explicit-any
