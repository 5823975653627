import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AppContext } from '../AppContext';
import authService from './api-authorization/AuthorizeService';
import NavMenu from './NavMenu';
import { UpdateLastActivityAppWS } from '../store/AppWS';
import { getUserProfile, ChartColors } from '../store/Util';
import _ from 'lodash';

export default class Layout extends React.PureComponent<{ children?: React.ReactNode }, {
	openAlertDialog?: boolean, openConfirmDialog?: boolean, isAuthenticated?: boolean, title?: string,
	classId?: string, seriesId?: string, cik?: string, family?: string, year?: string, solicitor?: string,
	meetingType?: string, meetingDate?: string, routine?: string, openOrClosedCIK?: string, customFamilies: any[],
	user: any, children?: React.ReactNode, isLoading?: boolean, userProfile: any, localState: any, showTables?: boolean,
	ocik?: string, oyear?: string
}> {
	constructor(props: any) {
		super(props);
		this.state = {
			customFamilies: [], user: {}, isLoading: true, isAuthenticated: false, userProfile: {}, localState: {}
		};
	}
	_subscription: any = null;
	componentDidMount() {
		this._subscription = authService.subscribe(() => this.populateState());
		this.populateState();
	}

	componentWillUnmount() {
		authService.unsubscribe(this._subscription);
	}

	async populateState() {
		const [isAuthenticated, user, userProfile] = await Promise.all([authService.isAuthenticated(), authService.getUser(), getUserProfile()])
		this.setState({
			isAuthenticated: isAuthenticated,
			user: userProfile || user,
			userProfile: userProfile,
			isLoading: false
		});
	}

	public render() {
		const { isAuthenticated, user, isLoading } = this.state;
		return (
			<React.Fragment>
				<AppContext.Provider value={{
					showAlert: this.showAlert,
					showConfirm: this.showConfirm,
					hasUserRole: this.hasUserRole, setOwnershipFilter: this.setOwnershipFilter,
					getOwnershipFilterCik: this.getOwnershipFilterCik, getOwnershipFilterYear: this.getOwnershipFilterYear,
					changeTitle: this.changeTitle, setProxyFilter: this.setProxyFilter,
					getProxyFilterClassId: this.getProxyFilterClassId, getProxyFilterSeriesId: this.getProxyFilterSeriesId,
					getProxyFilterCik: this.getProxyFilterCik, getProxyFilterFamily: this.getProxyFilterFamily,
					getProxyFilterYear: this.getProxyFilterYear, getProxyFilterSolicitor: this.getProxyFilterSolicitor,
					getProxyFilterMeetingType: this.getProxyFilterMeetingType, getProxyFilterMeetingDate: this.getProxyFilterMeetingDate,
					getProxyFilterRoutine: this.getProxyFilterRoutine,
					addFamily: this.addFamily, getFamilies: this.getFamilies, getProxyFilterOpenOrClosedCIK: this.getProxyFilterOpenOrClosedCIK,
					getSolicitorFilter: this.getSolicitorFilter, setSolicitorFilter: this.setSolicitorFilter,
					setShowTables: this.setShowTables,
					getShowTables: this.getShowTables,
					getSolicitorColors: this.getSolicitorColors,
					patchState: this.patchState
				}}>
					<div className="container-fluid">
						<div className="row min-vh-100 mh-100">
							<div className="col-12 d-flex flex-column mh-100">
								<NavMenu title={this.state.title} showNonDef={this.userClaimValue('Preference.NonDefinitive') === 'True'} />
								<div className="row flex-grow-1 flex-shrink-1 overflow-hidden pl-3 pr-3">
									{isLoading ? <></> : this.props.children}
								</div>
							</div>
						</div>
					</div>
				</AppContext.Provider>
				<Modal isOpen={this.state.openAlertDialog} returnFocusAfterClose={false}
					centered={true} keyboard={true} onHide={() => this.closeAlertModal()}>
					<ModalHeader toggle={() => this.closeAlertModal()}>
						{this.messageHeader}
					</ModalHeader>
					<ModalBody>
						{this.message}
					</ModalBody>
					<ModalFooter>
						<Button onClick={() => this.closeAlertModal()}>
							Ok
						</Button>
					</ModalFooter>
				</Modal>
				<Modal isOpen={this.state.openConfirmDialog} returnFocusAfterClose={false}
					centered={true} keyboard={true} onHide={() => this.closeConfirmModal(false)}>
					<ModalHeader toggle={() => this.closeConfirmModal(false)}>
						{this.messageHeader}
					</ModalHeader>
					<ModalBody>
						{this.message}
					</ModalBody>
					<ModalFooter>
						<Button
							color="danger"
							onClick={() => this.closeConfirmModal(true)}
						>
							Yes
						</Button>
						{' '}
						<Button onClick={() => this.closeConfirmModal(false)} color="primary">
							No
						</Button>
					</ModalFooter>
				</Modal>
			</React.Fragment>
		);
	}

	private closeAlertModal() {
		this.message = "";
		this.messageHeader = "";
		this.setState({ openAlertDialog: false });
	}

	private closeConfirmModal(userAction: boolean) {
		if (typeof this.confirmCallback === 'function') {
			this.confirmCallback(userAction, this.confirmCallbackData);
		}
		this.confirmCallback = null;
		this.message = "";
		this.messageHeader = "";
		this.setState({ openConfirmDialog: false });
	}

	message: string = "";
	messageHeader: string = "";
	confirmCallback: any;
	confirmCallbackData: any;

	// Get Input from User
	showAlert = (message: string, messageHeader: string) => {
		this.message = message;
		this.messageHeader = messageHeader;
		this.setState({ openAlertDialog: true });
	}

	// Get Confirmation from User
	showConfirm = (message: string, messageHeader: string, callback: any, callbackData?: any) => {
		this.message = message;
		this.messageHeader = messageHeader;
		this.confirmCallback = callback;
		this.confirmCallbackData = callbackData;
		this.setState({ openConfirmDialog: true });
	}

	// Does User have a role
	hasUserRole = (role: string): boolean => {
		if (this.state && this.state.user && this.state.user.role && this.state.user.role.length > 0) {
			return (this.state.user.role || []).includes(role);
		}
		return false;
	}

	userClaimValue = (claim: string): string | undefined => {
		if (this.state && this.state.user && this.state.user[claim]) {
			return this.state.user[claim];
		}
		return undefined;
	}

	// Page Header Title
	changeTitle = (title?: string) => {
		//debugger;
		this.setState({ title: title });
		document.title = title + ' - eProxy Hub';
		UpdateLastActivityAppWS(title).then(() => { });
	}

	setProxyFilter = (cik: string, family: string, year: string, solicitor: string, meetingType: string, meetingDate: string, routine?: string, openOrClosedCIK?: string, classId?: string, seriesId?: string) => {
		//debugger;
		this.setState({
			classId: classId, seriesId: seriesId, cik: cik, family: family, year: year, solicitor: solicitor,
			meetingType: meetingType, meetingDate: meetingDate, routine: routine, openOrClosedCIK: openOrClosedCIK
			//customFamilies: this.state.customFamilies
		});
		//console.log("setProxyFilter.cik: " + cik + family);
		//console.log("setProxyFilter.family: " + family);
	}

	setOwnershipFilter = (cik: string, year: string) => {
		//debugger;
		this.setState({
			ocik: cik, oyear: year
		});
	}
	getOwnershipFilterCik = (): string => {
		return this.state.ocik || "";
	}
	getOwnershipFilterYear = (): string => {
		return this.state.oyear || "";
	}

	getProxyFilterClassId = (): string => {
		return this.state.classId || "";
	}
	getProxyFilterSeriesId = (): string => {
		return this.state.seriesId || "";
	}
	getProxyFilterCik = (): string => {
		//console.log("getProxyFilterCik: " + this.state.cik);
		return this.state.cik || "";
	}
	getProxyFilterFamily = (): string => {
		//console.log("getProxyFilterFamily: " + this.state.family);
		return this.state.family || "";
	}
	getProxyFilterYear = (): string => {
		//console.log("getProxyFilterYear: " + this.state.year);
		return this.state.year || "";
	}
	getProxyFilterSolicitor = (): string => {
		//console.log("getProxyFilterSolicitor: " + this.state.solicitor);
		return this.state.solicitor || "";
	}
	getProxyFilterMeetingType = (): string => {
		//console.log("getProxyFilterMeetingType: " + this.state.meetingType);
		return this.state.meetingType || "";
	}
	getProxyFilterMeetingDate = (): string => {
		//console.log("getProxyFilterMeetingDate: " + this.state.meetingDate);
		return this.state.meetingDate || "";
	}
	getProxyFilterRoutine = (): string => {
		//console.log("getProxyFilterRoutine: " + this.state.routine);
		return this.state.routine || "";
	}
	getProxyFilterOpenOrClosedCIK = (): string => {
		//console.log("getProxyFilterOpenOrClosedCIK: " + this.state.openOrClosedCIK);
		return this.state.openOrClosedCIK || "";
	}
	getSolicitorFilter = (): string => {
		return this.state.localState?.solicitorFilter || "";
	}
	setSolicitorFilter = (filter: string): void => {
		this.state.localState.solicitorFilter = filter;
	}

	addFamily = (name: string, ciks: string) => {
		name = name.trim();
		var oldFamilyIndex = this.state.customFamilies.findIndex(f => f.name.toUpperCase() == name.toUpperCase());
		if (oldFamilyIndex > -1) {
			this.state.customFamilies.splice(oldFamilyIndex);
		}
		this.state.customFamilies.push({ name: name, ciks: `ciks:${ciks}` });
		//this.setState({ cik: this.state.cik, family: this.state.family, customFamilies: customFamilies });
	}

	setShowTables = (showTables: boolean) => {
		this.setState({ ...this.state, showTables });
	}

	getShowTables = (): boolean => {
		return this.state.showTables || false;
	}

	getSolicitorColors = (sData: any[], pieData: any[]): string[] => {
		let solicitorChartColorMap: any[] = this.state.localState.solicitorChartColorMap || [];
		let maxIndex = _.max(solicitorChartColorMap.map((d: any) => d.index)) || -1;

		sData.map(s => {
			let solicitor = solicitorChartColorMap.find(f => f.solicitor == s.solicitor);
			if (!solicitor) {
				solicitorChartColorMap.push({ solicitor: s.solicitor, index: ++maxIndex });
			}
		});
		this.state.localState.solicitorChartColorMap = solicitorChartColorMap; // Save Solicitor Index

		// Get Colors based on saved Solicitor Index
		let solicitorChartColors: string[] = [];
		pieData.map(s => {
			let solicitor = solicitorChartColorMap.find(f => f.solicitor == s.solicitor);
			if (solicitor) {
				solicitorChartColors.push(ChartColors[solicitor.index % ChartColors.length]);
			} else { // Use Last Color Other
				solicitorChartColors.push(ChartColors[ChartColors.length - 1]);
			}
		});

		return solicitorChartColors;
	}

	getFamilies = () => {
		return this.state.customFamilies;
	}

	patchState = (state: any) => {
		this.setState({ ...state });
	}
}