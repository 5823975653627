import _ from 'lodash';
import * as React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { ApplicationState } from '../store';
import * as FilingSeriesClassesStore from '../store/FilingSeriesClasses';
import { cikDefFilingsLink, cikFilingsLink, formatNumber, morningstarLink, seriesClassesLink, votingRecordsLink } from '../store/Util';

// At runtime, Redux will merge together...
type FilingSeriesClassProps =
	FilingSeriesClassesStore.FilingSeriesClassesState // ... state we've requested from the Redux store
	& typeof FilingSeriesClassesStore.actionCreators // ... plus action creators we've requested
	& RouteComponentProps<{ fundssan: string, fundscik: string, classessan: string, classescik: string, seriesid: string }>; // ... plus incoming routing parameters


class Funds extends React.PureComponent<FilingSeriesClassProps> {
	private showFunds: boolean = false;
	private san: string = "";
	private cik: string = "";
	private seriesid: string = "";
	private dataType: number = 0; // 0: CIK Classes, 1: CIK Funds, 2: SAN Classes, 3: SAN Funds, 4: Classes of a Series ID, 5: All Series, 6: All Classes

	static contextType = AppContext;
	context!: React.ContextType<typeof AppContext>;

	// This method is called when the component is first added to the document
	public componentDidMount() {
		this.props.resetState();
		this.ensureDataFetched();
		this.context.changeTitle('Proxy Filing Funds');
	}

	// This method is called when the route parameters change
	public componentDidUpdate() {
		this.ensureDataFetched();
	}

	MyExportCSV = (props: any) => {
		const handleClick = () => {
			props.onExport(props.data);
		};
		return (
			<img src={"microsoft-excel.svg"} height="30px" onClick={handleClick} className="float-right pl-2" role="button" title="Export Proxy Filing Funds to Excel (.csv)" />
		);
	}

	public render() {
		return (
			<React.Fragment>
				<div className="col-12 overflow-auto">
					<div className="position-absolute">
						{/*<h1 id="tabelLabel">Proxy Filing Funds</h1>*/}
						{this.renderFundsTable()}
					</div>
				</div>
			</React.Fragment>
		);
	}

	private ensureDataFetched() {
		this.seriesid = this.props.match.params.seriesid;
		this.san = this.props.match.params.fundssan || this.props.match.params.classessan;
		this.cik = this.props.match.params.fundscik || this.props.match.params.classescik;

		this.dataType = this.props.match.params.classescik ? 0
			: this.props.match.params.fundscik ? 1
				: this.props.match.params.classessan ? 2 // Classes of a Filing SAN
					: this.props.match.params.fundssan ? 3 // Funds of a Filing SAN
						: this.seriesid ? 4 // Classes of a Series ID
							: this.props.match.url.startsWith("/funds") ? 5 // All Funds
								: this.props.match.url.startsWith("/classes") ? 6 : -1; // All Classes

		this.showFunds = this.dataType == 1 || this.dataType == 3 || this.dataType == 5;

		const sancik = this.cik || this.san || this.seriesid || "-";
		this.props.requestFilingSeriesClasses(sancik, this.dataType);
	}

	private renderFundsTable() {
		const selectRow = {
			mode: 'checkbox',
			clickToSelect: true,
			hideSelectColumn: true,
			bgColor: 'lightgray' //'#00BFFF'
		};

		var columns = [
			{
				dataField: 'cik',
				text: 'CIK',
				sort: true,
				align: "center",
				headerStyle: () => { return { width: '80px' }; },
				headerTitle: () => 'SEC EDGAR Company Index Key',
				title: () => 'View All Filings of this Company',
				formatter: (cell: any) => cikFilingsLink(cell)
			},
			{
				dataField: 'ticker',
				text: 'Ticker',
				sort: true,
				align: "center",
				headerStyle: () => { return { width: '80px' }; },
				title: (cell: any, row: any) => cell ? `View Morningstar Quote for ${row.seriesName} ${row.className} (${cell})` : '',
				hidden: this.showFunds,
				csvExport: !this.showFunds,
				formatter: (cell: any) => morningstarLink(cell)
			},
			{
				dataField: 'seriesId',
				text: 'Fund Id',
				sort: true,
				align: "center",
				headerStyle: () => { return { width: '90px' }; },
				headerTitle: () => "SEC EDGAR Fund Series Id",
				title: (cell: any, row: any) => `View Proxy Filings of ${row.seriesName}`,
				formatter: (cell: any) => cikDefFilingsLink(cell)
			},
			{
				dataField: 'seriesName',
				text: 'Fund Series Name',
				sort: true,
			},
			{
				dataField: 'classes',
				text: 'Classes',
				sort: true,
				defaultSortDirection: 'desc',
				align: "right",
				headerStyle: () => { return { width: '75px' }; },
				headerTitle: () => "# Classes offered by Fund",
				title: (cell: any, row: any) => `View All Classes of ${row.seriesName}`,
				formatter: (cell: any, row: any) => seriesClassesLink(row.seriesId, cell),
				csvFormatter: (cell: any) => cell ? cell : '',
				hidden: !this.showFunds,
				csvExport: this.showFunds,
			},
			{
				dataField: 'classId',
				text: 'Class Id',
				sort: true,
				align: "center",
				hidden: this.showFunds,
				csvExport: !this.showFunds,
				headerStyle: () => { return { width: '90px' }; },
				headerTitle: () => 'SEC EDGAR Fund Class Id',
				title: (cell: any, row: any) => `View Proxy Filings of ${row.seriesName} ${row.className}`,
				formatter: (cell: any) => cikDefFilingsLink(cell)
			},
			{
				dataField: 'className',
				text: 'Class Name',
				hidden: this.showFunds,
				csvExport: !this.showFunds,
				headerTitle: () => "Fund Class",
				sort: true
			},
			{
				hidden: this.showFunds || this.dataType == 2, // Hide when showing Funds or Filing Classes
				dataField: 'proxyCount',
				text: 'Proxies',
				sort: true,
				align: "center",
				headerAlign: 'center',
				headerStyle: { width: '80px' },
				headerTitle: () => '# Proxies Filed by Series Class since 2019',
				title: (cell: any, row: any) => `View Proxies of Series Class ${row.classId}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"/proxies"} onClick={() => this.setProxyFilterClassId(row.classId)}>{cell}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'votingRecords',
				hidden: true,
				isDummyField: true,
				text: '',
				csvExport: false,
				//headerTitle: () => "Voting Records",
				title: (cell: any, row: any) => `View Proxy Voting Records of ${row.seriesName} ${!this.showFunds ? row.className : ''}`,
				formatter: (cell: any, row: any) => votingRecordsLink(this.showFunds ? row.seriesId : row.classId)
			},
			{
				dataField: 'votingRecords',
				isDummyField: true,
				text: '',
				csvExport: false,
				hidden: true
			}
		];

		return (
			<ToolkitProvider
				keyField={this.showFunds ? "seriesId" : "classId"}
				data={this.props.filingSeriesClasses || []}
				columns={columns}
				exportCSV={{
					fileName: 'ProxyFunds.csv',
					blobType: 'text/csv;charset=utf-8'
				}}
			>{
					props => (
						<div>
							<p>
								{this.props.filingSeriesClasses && this.props.filingSeriesClasses.length
									? formatNumber(this.props.filingSeriesClasses.length) + " " : ""}
								{
									[1, 3, 5].includes(this.dataType)
										? "Funds"
										: "Classes"} {[5, 6].includes(this.dataType) ? '' : 'in'} {[2, 3, 6].includes(this.dataType)
											? <span>Proxy Filing <Link to={"/edit-data/" + this.san} title="View/Edit Proxy Data">{this.san}</Link></span>
											: [0, 1].includes(this.dataType)
												? <span>CIK <Link to={"/proxies"} onClick={() => this.setProxyFilterCik(parseInt(this.cik))} title="View Company Proxies">{this.cik}</Link></span>
												: <span>{[5, 6].includes(this.dataType) ? '' : ' Fund '}<Link to={"#"} onClick={() => this.props.history.goBack()} title="View Funds">{_.trimStart(this.seriesid, "S0")}</Link></span>
								}
								{this.props.filingSeriesClasses && this.props.filingSeriesClasses.length ? <this.MyExportCSV {...props.csvProps} /> : ""}
								{this.fundsOrClassesButton()}
							</p>
							<BootstrapTable
								{...props.baseProps}
								//keyField="classId"
								//data={this.props.filingSeriesClasses}
								//columns={columns}
								//selectRow={selectRow}
								bootstrap4={true}
								striped={true}
								bordered={false}
								headerClasses="table-header-class"
								classes={"unset-width"}
								noDataIndication={this.emptyDataMessage}
								hover
								condensed
								defaultSorted={[{
									dataField: 'seriesName', // if dataField is not match to any column you defined, it will be ignored.
									order: 'asc' // desc or asc
								}]}
							/>
						</div>
					)
				}
			</ToolkitProvider>
		);
	}

	private emptyDataMessage = () => {
		if (this.props.isLoading)
			return 'Loading...';
		return 'No Data to Display';
	}

	// Show View Funds / View Classes button
	private fundsOrClassesButton(): any {
		if (this.showFunds) { // View Classes
			if (this.cik) {
				return (<Link to={`/classes/cik/${this.cik}`} className="btn btn-outline-primary btn-sm float-right" title="View Fund-Classes in Company">View Classes</Link>);
			} else if (this.san) {
				return (<Link to={`/classes/san/${this.san}`} className="btn btn-outline-primary btn-sm float-right" title="View Fund-Classes in Proxy Filing">View Classes</Link>);
			}
		} else { // View Funds
			if (this.cik) {
				return (<Link to={`/funds/cik/${this.cik}`} className="btn btn-outline-primary btn-sm float-right" title="View Funds in Company">View Funds</Link>);
			} else if (this.san) {
				return (<Link to={`/funds/san/${this.san}`} className="btn btn-outline-primary btn-sm float-right" title="View Funds in Proxy Filing">View Funds</Link>);
			}
		}
	}

	private setProxyFilterClassId(classId: string) {
		this.context.setProxyFilter("", "", "", "", "", "", "", "", classId, "");
	}

	private setProxyFilterSeriesId(seriesId: string) {
		this.context.setProxyFilter("", "", "", "", "", "", "", "", "", seriesId);
	}

	private setProxyFilterCik(cik: number) {
		this.context.setProxyFilter(cik.toString(), "", "", "", "", "");
	}
}

export default connect(
	(state: ApplicationState) => state.filingSeriesClasses, // Selects which state properties are merged into the component's props
	FilingSeriesClassesStore.actionCreators // Selects which action creators are merged into the component's props
)(Funds as any); // eslint-disable-line @typescript-eslint/no-explicit-any
