import { format, parseISO } from 'date-fns';
import * as React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { FaSignOutAlt, FaSync } from 'react-icons/fa';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { ApplicationState } from '../store';
import { InvokeClientCommandAppWS } from '../store/AppWS';
import * as ConnectedUsersStore from '../store/ConnectedUsers';
import { formatNumber } from '../store/Util';

// At runtime, Redux will merge together...
type ConnectedUsersProps =
	ConnectedUsersStore.ConnectedUsersState // ... state we've requested from the Redux store
	& typeof ConnectedUsersStore.actionCreators // ... plus action creators we've requested
	& RouteComponentProps<{ name: string }>; // ... plus incoming routing parameters


class ConnectedUsers extends React.PureComponent<ConnectedUsersProps> {

	static contextType = AppContext;
	context!: React.ContextType<typeof AppContext>;
	last_request_date: number = Date.now();

	// This method is called when the component is first added to the document
	public componentDidMount() {
		this.props.resetState();
		this.ensureDataFetched();
		this.context.changeTitle('Connected Users');
	}

	// This method is called when the route parameters change
	public componentDidUpdate() {
		this.ensureDataFetched();
	}

	MyExportCSV = (props: any) => {
		const handleClick = () => {
			props.onExport(props.data);
		};
		return (
			<img src={"microsoft-excel.svg"} height="30px" onClick={handleClick} className="float-right pl-2" role="button" title="Export Connected Users to Excel (.csv)" />
		);
	}

	public render() {
		return (
			<React.Fragment>
				<div className="col-12 overflow-auto">
					<div className="position-absolute">
						{this.renderConnectedUsersTable()}
					</div>
				</div>
			</React.Fragment>
		);
	}

	private ensureDataFetched() {
		this.props.requestConnectedUsers(this.last_request_date);
	}

	private renderConnectedUsersTable() {

		var columns = [
			{
				dataField: 'userName',
				text: 'User Name',
				sort: true
			},
			{
				dataField: 'browser',
				text: 'Browser',
				sort: true
			},
			{
				dataField: 'ipAddress',
				text: 'IP Address',
				align: 'center',
				sort: true
			},
			{
				dataField: 'appVersion',
				text: 'Application Version',
				align: 'center',
				sort: true
			},
			{
				dataField: 'appUserVersion',
				text: 'Application User Version',
				align: 'center',
				sort: true
			},
			{
				dataField: 'connectionDate',
				text: 'Connection Date',
				sort: true,
				formatter: (cell: Date) => cell ?format(parseISO(cell.toString()), 'M/d/yyyy h:mm:ss a') : '',
				csvFormatter: (cell: Date) => cell ? format(parseISO(cell.toString()), 'M/d/yyyy h:mm:ss a') : ''
			},
			{
				dataField: 'page',
				text: 'Last Page',
				sort: true
			},
			{
				dataField: 'lastActivityDate',
				text: 'Last Activity Date',
				sort: true,
				formatter: (cell: Date) => cell ? format(parseISO(cell.toString()), 'M/d/yyyy h:mm:ss a') : '',
				csvFormatter: (cell: Date) => cell ? format(parseISO(cell.toString()), 'M/d/yyyy h:mm:ss a') : ''
			},
			{
				dataField: 'dummy1',
				text: 'Action',
				headerAlign: 'center',
				sort: false,
				csvExport: false,
				searchable: false,
				formatter: (cell: any, row: any) => {
					let logOutBtn = <Link className='btn btn-outline-primary btn-sm' to={`#`} title="Force Logout" onClick={(e: any) => this.takeUserAction(e, 'Logout', row.connectionId)} ><FaSignOutAlt /></Link>;
					let reloadBtn = <Link className='btn btn-outline-primary btn-sm' to='#' title="Force Reload" onClick={(e: any) => this.takeUserAction(e, 'Reload', row.connectionId)}><FaSync /></Link>;
					return (<>{logOutBtn} {reloadBtn}</>);
				}
			}
		];

		return (
			<ToolkitProvider
				keyField={"connectionId"}
				data={this.props.connectedUsers || []}
				columns={columns}
				exportCSV={{
					fileName: 'ConnectedUsers.csv',
					blobType: 'text/csv;charset=utf-8'
				}}
			>{
					props => (
						<div>
							<p>
								{this.props.connectedUsers && this.props.connectedUsers.length
									? formatNumber(this.props.connectedUsers.length) + " Users" : ""}
								{this.props.connectedUsers && this.props.connectedUsers.length ? <this.MyExportCSV {...props.csvProps} /> : ""}
								{/*{this.refreshButton()}*/}
							</p>
							<BootstrapTable
								{...props.baseProps}
								bootstrap4={true}
								striped={true}
								bordered={false}
								headerClasses="table-header-class"
								classes={"unset-width"}
								noDataIndication={this.emptyDataMessage}
								hover
								condensed
								defaultSorted={[{
									dataField: 'userName', // if dataField is not match to any column you defined, it will be ignored.
									order: 'asc' // desc or asc
								}]}
							/>
						</div>
					)
				}
			</ToolkitProvider>
		);
	}

	takeUserAction = async (e: any, action: string, connectionId: string) => {
		e.preventDefault();
		await InvokeClientCommandAppWS(action, connectionId);
		//this.last_request_date = Date.now();
		//setTimeout(() => {
		//	this.props.requestConnectedUsers(this.last_request_date);
		//}, 2000);

	}

	// Show View Funds / View Classes button
	private refreshButton(): any {
		return (<Link to={`#`} className="btn btn-outline-primary btn-sm float-right" title="Refresh" onClick={(e) => {
			e.preventDefault();
			e.stopPropagation();
			this.last_request_date = Date.now();
			this.props.requestConnectedUsers(this.last_request_date);
		}}  >Refresh</Link>);
	}


	private emptyDataMessage = () => {
		if (this.props.isLoading)
			return 'Loading...';
		return 'No Data to Display';
	}

}

export default connect(
	(state: ApplicationState) => state.connectedUsers, // Selects which state properties are merged into the component's props
	ConnectedUsersStore.actionCreators // Selects which action creators are merged into the component's props
)(ConnectedUsers as any); // eslint-disable-line @typescript-eslint/no-explicit-any
