import React, { Component, PureComponent } from 'react';
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ComposedChart, Line, Area } from 'recharts';
import { ChartColors, formatNumber, solicitorShortNames } from '../../store/Util';

// Customize Axis Tick Labels used for Solicitor Chart in Dashboard
class CustomizedAxisTick extends PureComponent<any> {
	render() {
		const { x, y, stroke, payload, chartInfo, index } = this.props;

		// Reduce Axis Tick Labels Font Size
		return (
			<g transform={`translate(${x},${y})`}>
				<text x={0} y={0} dy={16} textAnchor="middle" className="small" fill="#666" transform="rotate(0)">
					{solicitorShortNames.get(payload.value) || payload.value}
				</text>
			</g>
		);
	}
}

// Customize Bar Label used in Horizontal Bar Chart (Top 10 Companies in Dashboard)
class CustomizedLabel extends PureComponent<any> {
	render() {
		const { x, y, stroke, name, value } = this.props;
		// Set Bar Label Color to Dark Blue, Align Left: Show Name and Value (Ex: Company Name and Proxy Count)
		return (
			<text x={x} y={y} dx={15} dy={14} fill="darkblue" fontSize={10} textAnchor="start" className="strong text-capitalize" fontWeight="bold" style={{ pointerEvents: 'none' }}>
				{name + ': ' + value}
			</text>
		);
	}
}

// Not Used
const renderCustomizedLabel = (props: any) => {
	const { x, y, width, height, value } = props;
	const radius = 10;

	return (
		<g>
			<circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" />
			<text x={x + width / 2} y={y - radius} fill="#fff" textAnchor="middle" dominantBaseline="middle">
				{value.split(' ')[1]}
			</text>
		</g>
	);
};

// Composed Chart with Bar, Line, and Area
export class RCStackedChart extends Component<{ chartInfo?: any }> {
	toPercent = (decimal: number, fixed: number = 0) => `${(decimal * 100).toFixed(0)}%`; // fixed

	tickFormatter = (tickItem: any, index: any): any => {
		console.log("tickItem:" + tickItem);
		return tickItem; // Limit number of characters .toString().substring(0, 13)
	}

	onClick = (payload: any) => {
		let x = this.props;
	}

	onBarClick = (payload: any, barIndex: number, barInfo: any) => {
		let key = this.props.chartInfo.key; // Solicitor, Meeting Type, ...
		let fieldName = barInfo.fieldName; // proxyCount19, proxyCount20, ...
		let data = this.props.chartInfo.data[barIndex]; // Data of Computershare, Broadridge, ... or Annual, Special
		let value = data[fieldName]; // Number of Proxies of Solicitor Computershare in 2019, ...
		//alert(`${key} ${data[key]} ${name}: ${value}`); // solicitor Computershare 2019: 257

		if (this.props.chartInfo.onBarClick && typeof this.props.chartInfo.onBarClick === 'function') {
			this.props.chartInfo.onBarClick.call(this, barInfo, data, value, key, this);
		}
	}

	onActiveDotClick = (payload: any, e: any, lineInfo: any) => {
		let key = this.props.chartInfo.key; // Solicitor, Meeting Type, ...
		let fieldName = lineInfo.fieldName; // proxyCount19, proxyCount20, ...
		let data = e.payload; // Data of Computershare, Broadridge, ... or Annual, Special
		let value = data[fieldName]; // Number of Proxies of Solicitor Computershare in 2019, ...
		//alert(`${key} ${data[key]} ${name}: ${value}`); // solicitor Computershare 2019: 257

		if (this.props.chartInfo.onActiveDotClick && typeof this.props.chartInfo.onActiveDotClick === 'function') {
			this.props.chartInfo.onActiveDotClick.call(this, lineInfo, data, value, key, this);
		}
	}

	public render() {
		let chartInfo = {
			barInfo: [],
			lineInfo: [],
			areaInfo: [],
			data: {},
			key: "",
			layout: "horizontal",
			showLegend: true,
			customizedLabel: false,
			width: 500,
			height: 300,
			margin: {
				top: 20,
				right: 30,
				left: 20,
				bottom: 5
			},
			stackOffset: "none",
			...this.props?.chartInfo
		};

		return (
			<React.Fragment>
				<div>
					{this.showTitle()}
					{/*<ResponsiveContainer width="100%" height="300" minHeight="300px" minWidth="500px">*/}
					<ComposedChart
						layout={chartInfo.layout}
						width={chartInfo.width}
						height={chartInfo.height}
						data={chartInfo.data}
						margin={chartInfo.margin}
						stackOffset={chartInfo.stackOffset}
					>
						{/*Chart Title*/}
						{/*<text x={chartInfo.width / 2} y={15} fill="black" textAnchor="middle" dominantBaseline="central">*/}
						{/*	<tspan fontSize="14">{chartInfo.title}</tspan>*/}
						{/*</text>*/}
						<CartesianGrid strokeDasharray="3 3" />
						{chartInfo.layout != 'vertical'
							? <><XAxis dataKey={chartInfo.key} interval={0} height={30}
								tick={<CustomizedAxisTick chartInfo={chartInfo } />}
								//tickFormatter={(value, index) => { return this.tickFormatter(value, index); }}
								onClick={(e) => { this.onClick(e) }} />
								<YAxis tickFormatter={chartInfo.stackOffset == 'expand' ? this.toPercent : undefined} /></>
							: <><XAxis type="number" />
								<YAxis dataKey={chartInfo.key} type="category" scale="band" onClick={(e) => { this.onClick(e) }} tick={false} /></>
						}
						<Tooltip />
						{chartInfo.showLegend ? <Legend /> : <></>}
						{chartInfo.barInfo.map((object: any, i: number) =>
							<Bar name={object.name} dataKey={object.fieldName} stackId={object.stackId || "BarStackId"} fill={object.fill || ChartColors[i]} style={{ cursor: 'pointer' }}
								onClick={(payload, barIndex) => { this.onBarClick(payload, barIndex, object) }}>
								<LabelList dataKey={object.fieldName} position="center" fill="#EBE8E2"
									style={{ pointerEvents: 'none' }}
									content={chartInfo.customizedLabel ? <CustomizedLabel /> : undefined} />
							</Bar>
						)}
						{chartInfo.lineInfo.map((object: any, i: number) =>
							<Line type="monotone" dataKey={object.fieldName} name={object.name} stroke={object.fill || ChartColors[i]} strokeWidth={2}
								activeDot={{ style: { cursor: 'pointer' }, onClick: (payload, e) => { this.onActiveDotClick(payload, e, object) } }}
							/>
						)}
						{chartInfo.areaInfo.map((object: any, i: number) =>
							<Area type="monotone" connectNulls dataKey={object.fieldName} stackId={object.stackId || "AreaStackId"} name={object.name} fill={object.fill || ChartColors[i]} strokeWidth={1}
								activeDot={{ style: { cursor: 'pointer' }, onClick: (payload, e) => { this.onActiveDotClick(payload, e, object) } }} stroke={object.fill || ChartColors[i]}
							/>
						)}
					</ComposedChart>
					{/*</ResponsiveContainer>*/}
				</div>
			</React.Fragment>
		);
	}

	private showTitle() {
		return (this.props?.chartInfo && this.props?.chartInfo.title ? <div className="d-block mt-3">{this.props?.chartInfo.title}</div> : <></>); // text-center
	}
};
