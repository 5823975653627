import { BasicTooltip, TooltipWrapper } from '@nivo/tooltip';
import { format, parseISO, getDay } from 'date-fns';
import _ from 'lodash';
import * as React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { ApplicationState } from '../store';
import * as DashboardStore from '../store/Dashboard';
import { CalendarChartColors, ChartColors, cikFilingsLink, DateToJSON, dateToString, filingIndexLink, formatNumber, ProxyFilterParameters, renderCalendarChart, renderFunnelChart, renderPieChart, renderProxyCountsChart, sanClassesLink, sanFundsLink, solicitorUrl, viewEditProxyLink } from '../store/Util';
import { NivoCalendarChart } from './charts/nivo-calendar';
import { RCStackedChart } from './charts/rc-stacked-bar-chart';
import ReactSwitch from 'react-switch';
import { SolicitorMarketSharePieChart } from './SolicitorMarketSharePieChart';

// At runtime, Redux will merge together...
type DashboardProps =
	DashboardStore.DashboardState // ... state we've requested from the Redux store
	& typeof DashboardStore.actionCreators // ... plus action creators we've requested
	& RouteComponentProps<{ name: string }>;

class Dashboard extends React.PureComponent<DashboardProps> {
	static contextType = AppContext;
	context!: React.ContextType<typeof AppContext>;

	// This method is called when the component is first added to the document
	constructor(props: any) {
		super(props);
	}

	public componentDidMount() {
		this.props.resetState();
		this.ensureDataFetched();
		this.context.changeTitle('Proxy Dashboard');
	}

	// This method is called when the route parameters change
	public componentDidUpdate() {
		this.ensureDataFetched();
	}

	MyExportCSV = (props: any) => {
		const handleClick = () => {
			props.onExport(props.data);
		};
		return (
			<img src={"microsoft-excel.svg"} height="30px" onClick={handleClick} className="float-right pl-2" role="button" title="Export Proxy Filing Fund Companies to Excel (.csv)" />
		);
	}

	toggleView = (value: boolean) => {
		this.context.setShowTables(value);
	}

	public render() {
		//if (this.props.isLoading)
		//	return (<span>Loading ...</span>);
		return (
			<React.Fragment>
				<div className="col-12 overflow-auto">
					<div className="position-absolute screen-right row" style={{ zIndex: 1000 }} >
						<div className="mr-1" style={{ marginTop: "-4px" }}>Show Tables</div>
						<ReactSwitch height={20} width={40} className="mr-2" name="switchtables" checked={this.context.getShowTables()}
							onChange={(value) => this.toggleView(value)}></ReactSwitch>
					</div>
					<div className="position-absolute">
						{!this.context.getShowTables() ?
							<div className="d-flex flex-row justify-content-between no-wrap">
								<div className="mr-4">
									{this.renderMeetingDatesChart({ height: 350, width: 250 })}
									{this.renderYearChart()}
								</div>
								<div>
									{this.renderSolicitorProxyCountsChart()}
									{this.renderCompaniesChart()}
								</div>
								<div className="ml-4">
									{/*{this.renderSolicitorProxyCountsFunnelChart()}*/}
									{/*{this.renderSolicitorProxyCountsDist()}*/}
									<SolicitorMarketSharePieChart data={this.props.dashboardData?.dashboardData4} isLoading={this.props.isLoading} onActiveDotClick={this.onActiveDotClick}></SolicitorMarketSharePieChart>
									{this.renderMeetingTypeChart()}
								</div>
							</div>
							:
							<div className="d-flex flex-row justify-content-between no-wrap">
								<div className="mr-4">
									{this.renderMeetingDateProxyCountsTable()}
									{this.renderYearlyProxyCountsTable()}
									{this.renderMeetingTypeProxyCountsTable()}
									{this.renderYearlyOwnershipFilingCountsTable()}
								</div>
								<div>
									{this.renderSolicitorProxyCountsTable()}
								</div>
								<div className="ml-4">
									{this.renderProxyFilingsTable()}
									{this.renderCompaniesTable()}
								</div>
							</div>
						}
					</div>
				</div>
			</React.Fragment>
		);
	}

	private ensureDataFetched() {
		this.props.requestDashboard();
	}

	paginationTotalRenderer = (from: any, to: any, size: any) => (
		size ?
			<span className="react-bootstrap-table-pagination-total pl-2">
				Showing {from} to {to} of {formatNumber(size)} Results
			</span> : <span></span>
	);

	private emptyDataMessage = () => {
		if (this.props.isLoading)
			return 'Loading...';
		return 'No Data to Display';
	}

	private renderCompaniesTable() {
		var columns = [
			{
				dataField: 'cik',
				text: 'CIK',
				sort: true,
				align: "right",
				//headerStyle: { width: '80px' },
				headerTitle: () => 'SEC EDGAR Company Index Key',
				title: (cell: any, row: any) => `View All Filings of Company ${row.name}`,
				hidden: true,
				formatter: (cell: any, row: any) => cikFilingsLink(cell)
			},
			{
				dataField: 'name',
				text: 'Fund Family',
				sort: true,
				headerTitle: () => "Fund Family Name",
				title: (cell: any) => `View All Compinies of Family ${cell}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"companies"} onClick={() => this.setProxyFilterFamily(row.name)} className="mr-4">{cell}</Link>
					);
				}
			},
			{
				dataField: 'proxyCount',
				text: 'Proxies',
				sort: true,
				align: "right",
				//headerStyle: { width: '80px' },
				headerTitle: () => '# Proxies Filed by Company since 2019',
				title: (cell: any, row: any) => `View Proxies of Company ${row.name}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"proxies"} onClick={() => this.setProxyFilterFamily(row.name)} className="mr-4">{cell}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'dateFiled',
				text: 'Last Filed',
				sort: true,
				defaultSortDirection: 'desc',
				align: "right",
				//headerStyle: { width: '100px' },
				headerTitle: () => 'Date of Last Proxy Filing Filed on SEC EDGAR',
				formatter: (cell: any, row: any) => dateToString(cell),
				csvFormatter: (cell: any) => dateToString(cell)
			}
		];

		return (
			<ToolkitProvider
				keyField="cik"
				data={this.props.dashboardData && this.props.dashboardData.dashboardData1 ? this.props.dashboardData.dashboardData1 : []}
				columns={columns}
				exportCSV={{
					fileName: 'ProxyCompanies.csv',
					blobType: 'text/csv;charset=utf-8'
				}}
			>{
					props => (
						<div>
							<Link to="/report/families">Fund Families that filed most Proxy Filings since 2019</Link>
							<BootstrapTable
								{...props.baseProps}
								bootstrap4={true}
								striped={true}
								bordered={false}
								headerClasses="table-header-class"
								classes={"unset-width"}
								noDataIndication={this.emptyDataMessage}
								hover
								condensed
								defaultSorted={[{
									dataField: 'proxyCount', // if dataField is not match to any column you defined, it will be ignored.
									order: 'desc' // desc or asc
								}]}
							/>
						</div>
					)
				}
			</ToolkitProvider>
		);
	}

	// Top Companies Proxy Counts Chart
	private renderCompaniesChart() {
		if (this.props.isLoading)
			return (<></>);

		let barInfo: any = [
			{ fieldName: "proxyCount", name: "Proxies", fill: "#82ca9d" }
		];

		const key: string = 'name';
		let chartInfo: any = {
			key,
			barInfo,
			data: this.props.dashboardData?.dashboardData1 || [],
			width: 500,
			height: 350,
			margin: { top: 5, left: -55 },
			layout: 'vertical',
			customizedLabel: true,
			showLegend: false,
			onBarClick: (barInfo: any, data: any, value: any, key: string, chart: RCStackedChart) => { this.onBarClick(barInfo, data, value, key, chart) }
		};

		return (<React.Fragment>
			<>
				<Link to="/report/families">Fund Companies that filed most Proxy Filings since 2019</Link>
				{renderProxyCountsChart(chartInfo)}
			</>
		</React.Fragment>);
	}

	private renderProxyFilingsTable() {
		var columns = [
			{
				dataField: 'dateFiled',
				text: 'Date',
				sort: true,
				align: "right",
				headerAlign: 'right',
				//headerStyle: { width: '80px' },
				headerTitle: () => 'Date Filed on SEC EDGAR',
				formatter: (cell: any, row: any) => dateToString(cell),
				csvFormatter: (cell: any) => dateToString(cell)
			},
			{
				dataField: 'formType',
				text: 'Type',
				sort: true,
				//headerStyle: { width: '90px' },
				headerTitle: () => "SEC Form Type",
				title: (cell: any) => `View this ${cell} Filing`,
				formatter: (cell: any, row: any) => filingIndexLink(row.fileName, row.san, cell),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'funds',
				text: 'Company Names',
				sort: true,
				formatter: (cell: any) => {
					return (
						<span className="company-name-truncate text-truncate">{cell}</span>
					);
				},
				csvFormatter: (cell: any) => cell ? cell.replaceAll(', ', '\n').replaceAll('\nLLC', ', LLC').replaceAll('\nInc', ', Inc').replaceAll('\nINC', ', INC') : ''
			},
			{
				dataField: 'san',
				text: '',
				formatter: (cell: any, row: any) => viewEditProxyLink(cell, this.context.hasUserRole('ProxyEdit')),
				csvExport: false
			},
			{
				dataField: 'series',
				text: 'Funds',
				sort: true,
				defaultSortDirection: 'desc',
				align: "right",
				//headerStyle: { width: '75px' },
				headerTitle: () => '# Funds in Proxy Filing',
				//title: (cell: any, row: any) => `View List of Funds in this ${row.formType} Filing`,
				formatter: (cell: any, row: any) => sanFundsLink(row.san, cell),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'classes',
				text: 'Classes',
				sort: true,
				align: "right",
				//headerStyle: { width: '85px' },
				headerTitle: () => '# Fund-Classes in Proxy Filing',
				//title: (cell: any, row: any) => `View List of Fund-Classes in this ${row.formType} Filing`,
				formatter: (cell: any, row: any) => sanClassesLink(row.san, cell),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
		];

		return (
			<ToolkitProvider
				keyField="san"
				data={this.props.dashboardData && this.props.dashboardData.dashboardData2 ? this.props.dashboardData.dashboardData2 : []}
				columns={columns}
				search={{
					searchFormatted: true
				}}
				exportCSV={{
					// TODO: Handle Special Characters like Smart Quotes
					fileName: 'Proxies.csv',
					//separator: '|',
					//ignoreHeader: true,
					//noAutoBOM: true,
					blobType: 'text/csv;charset=utf-8'
				}}
			>{
					props => (
						<div>
							<div className="display-flex-space-between">
								<Link to={"proxies"} onClick={() => this.setProxyFilterYear(new Date().getFullYear())}>Recent Proxy Filings</Link>
								<Link to={"proxies"} onClick={() => this.setProxyFilterYear(-1)}>Show All</Link>
							</div>
							<BootstrapTable
								{...props.baseProps}
								bootstrap4={true}
								striped={true}
								bordered={false}
								headerClasses="table-header-class"
								classes={"unset-width"}
								noDataIndication={this.emptyDataMessage}
								hover
								condensed
								defaultSorted={[{
									dataField: 'dateFiled', // if dataField is not match to any column you defined, it will be ignored.
									order: 'desc' // desc or asc
								}]}
							/>
						</div>
					)
				}
			</ToolkitProvider>
		);
	}

	private renderYearlyProxyCountsTable() {
		var columns = [
			{
				dataField: 'year',
				text: 'Year',
				sort: true,
				align: "center",
				headerAlign: 'center',
				//headerClasses: "text-center",
				headerStyle: { width: '90px' },
				headerTitle: () => 'Year Filed on SEC EDGAR',
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount',
				text: 'Proxies',
				sort: true,
				align: "right",
				headerStyle: { width: '80px' },
				headerTitle: () => '# Proxies Filed in Year',
				title: (cell: any, row: any) => `View Proxies Filed in the year ${row.year}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"proxies"} onClick={() => this.setProxyFilterYear(row.year)} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			}
		];

		return (
			<ToolkitProvider
				keyField="year"
				data={this.props.dashboardData && this.props.dashboardData.dashboardData3 ? this.props.dashboardData.dashboardData3 : []}
				columns={columns}
			>{
					props => (
						<div>
							<Link to="/report/years">Proxy Filings by Year</Link>
							<BootstrapTable
								{...props.baseProps}
								bootstrap4={true}
								striped={true}
								bordered={false}
								headerClasses="table-header-class"
								classes={"unset-width"}
								noDataIndication={this.emptyDataMessage}
								hover
								condensed
								defaultSorted={[{
									dataField: 'year', // if dataField is not match to any column you defined, it will be ignored.
									order: 'asc' // desc or asc
								}]}
							/>
						</div>
					)
				}
			</ToolkitProvider>
		);
	}

	private renderYearlyOwnershipFilingCountsTable() {
		var columns = [
			{
				dataField: 'year',
				text: 'Year',
				sort: true,
				align: "center",
				headerAlign: 'center',
				//headerClasses: "text-center",
				headerStyle: { width: '90px' },
				headerTitle: () => 'Year Filed on SEC EDGAR',
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'ownershipFilingCount',
				text: 'Filings',
				sort: true,
				align: "right",
				headerStyle: { width: '80px' },
				headerTitle: () => '# Ownership Filings Filed in Year',
				title: (cell: any, row: any) => `View Ownership Filings Filed in the year ${row.year}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"ownership-filings"} onClick={() => this.setOwnershipFilingFilterYear(row.year)} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			}
		];

		return (
			<ToolkitProvider
				keyField="year"
				data={this.props.dashboardData && this.props.dashboardData.dashboardData7 ? this.props.dashboardData.dashboardData7 : []}
				columns={columns}
			>{
					props => (
						<div>
							<Link to={"ownership-filings"} onClick={() => this.setOwnershipFilingFilterYear(-1)}>Ownership Filings by Year</Link>
							<BootstrapTable
								{...props.baseProps}
								bootstrap4={true}
								striped={true}
								bordered={false}
								headerClasses="table-header-class"
								classes={"unset-width"}
								noDataIndication={this.emptyDataMessage}
								hover
								condensed
								defaultSorted={[{
									dataField: 'year', // if dataField is not match to any column you defined, it will be ignored.
									order: 'asc' // desc or asc
								}]}
							/>
						</div>
					)
				}
			</ToolkitProvider>
		);
	}

	// Yearly Proxy Counts Chart
	private renderYearChart() {
		if (this.props.isLoading)
			return (<></>);

		let barInfo: any = [
			{ fieldName: "proxyCount", name: "Proxies" }
		];

		const key: string = 'year';
		let chartInfo: any = {
			key,
			barInfo,
			data: this.props.dashboardData?.dashboardData3 || [],
			showLegend: false,
			width: 350,
			height: 350,
			margin: { top: 5, left: -15 },
			//title: 'Yearly Proxy Filings',
			onBarClick: (barInfo: any, data: any, value: any, key: string, chart: RCStackedChart) => { this.onBarClick(barInfo, data, value, key, chart) }
		};

		return (<React.Fragment>
			<>
				<div className="display-flex-space-between"><Link to="/report/years">Proxy Filings by Year</Link><Link to={"proxies"} onClick={() => this.setProxyFilterYear(-1)}>Show All</Link></div>
				{renderProxyCountsChart(chartInfo)}
			</>
		</React.Fragment>);
	}

	onActiveDotClick = (lineInfo: any, data: any, value: any, key: string, chart: RCStackedChart, stateToSet: any = {}) => {
		//let stateToSet: any = {};
		stateToSet[key] = data[key];

		switch (lineInfo.fieldName) {
			case "annual":
				stateToSet["meetingType"] = "Annual";
				break;
			case "special":
				stateToSet["meetingType"] = "Special";
				break;
			case "routine":
				stateToSet["routine"] = "R";
				break;
			case "nonRoutine":
				stateToSet["routine"] = "N";
				break;
		}
		//alert(`${key} ${data[key]} ${lineInfo.fieldName}: ${value}`);
		this.setProxyFilter(stateToSet);
		this.props.history.push('/proxies');
	}

	onBarClick = (barInfo: any, data: any, value: any, key: string, chart?: RCStackedChart) => {
		let stateToSet: any = {};
		stateToSet[key] = data[key];

		switch (barInfo.fieldName) {
			case "proxyCount19":
				stateToSet["year"] = "2019";
				break;
			case "proxyCount20":
				stateToSet["year"] = "2020";
				break;
			case "proxyCount21":
				stateToSet["year"] = "2021";
				break;
			case "proxyCount22":
				stateToSet["year"] = "2022";
				break;
			case "proxyCount23":
				stateToSet["year"] = "2023";
				break;
			case "proxyCount24":
				stateToSet["year"] = "2024";
				break;
			case "openEnd":
				stateToSet["openOrClosedCIK"] = "0";
				break;
			case "closedEnd":
				stateToSet["openOrClosedCIK"] = "1";
				break;
		}
		if (key == 'name' && data && data.name) { // data.cik
			//stateToSet["cik"] = data.cik;
			stateToSet["family"] = data.name;
		}
		//alert(`${key} ${data[key]} ${barInfo.fieldName}: ${value}`);
		this.setProxyFilter(stateToSet);
		this.props.history.push('/proxies');
	}

	private renderSolicitorProxyCountsChart() {
		if (this.props.isLoading)
			return (<></>);

		let barInfo: any = [
			{ fieldName: "proxyCount19", name: "2019" },
			{ fieldName: "proxyCount20", name: "2020" },
			{ fieldName: "proxyCount21", name: "2021" },
			{ fieldName: "proxyCount22", name: "2022" },
			{ fieldName: "proxyCount23", name: "2023" },
			{ fieldName: "proxyCount24", name: "2024" },
			{ fieldName: "openEnd", name: "Open-end", stackId: "OpenCloseStackId" },
			{ fieldName: "closedEnd", name: "Closed-end", stackId: "OpenCloseStackId" }
		];
		let chartInfo = this.props.dashboardData && this.props.dashboardData.dashboardData4 ?
			{
				key: "solicitor",
				barInfo: barInfo,
				data: _.take(this.props.dashboardData.dashboardData4, 5),
				height: 350,
				margin: {
					top: 5,
					left: -10, //-20
					bottom: 20
				},
				//stackOffset: 'expand',
				onActiveDotClick: (lineInfo: any, data: any, value: any, key: string, chart: RCStackedChart) => { this.onActiveDotClick(lineInfo, data, value, key, chart) },
				onBarClick: (barInfo: any, data: any, value: any, key: string, chart: RCStackedChart) => { this.onBarClick(barInfo, data, value, key, chart) }
			}
			: {};

		return (
			<React.Fragment>
				<Link to="/solicitors">Proxy Filings by Solicitor</Link>
				{renderProxyCountsChart(chartInfo)}
			</React.Fragment>
		);
	}

	private renderSolicitorProxyCountsFunnelChart() {
		if (this.props.isLoading)
			return (<></>);


		let chartInfo = this.props.dashboardData && this.props.dashboardData.dashboardData4 ?
			{
				key: "proxyCount",
				label: "solicitor",
				data: _.take(this.props.dashboardData.dashboardData4, 5).map((d, i) => { return { fill: ChartColors[i], ...d }; }),
				margin: {
					top: 5,
					left: 5
				},
				onFunnelClick: (barInfo: any, data: any, value: any, key: string) => { this.onBarClick(barInfo, data, value, key) },
				tooltip: (props: any) => {
					let d: any = props.payload[0].payload;
					let color: any = d.fill;
					let row: any = d;
					return <React.Fragment>
						{/*<TooltipWrapper anchor="bottom" position={[0, 0]}>*/}
						<div className='nivoCalendarDiv' style={{ border: '1px solid ' + color }}><table style={{ color }}><tbody>
							<tr><th colSpan={2} style={{ borderBottom: '2px solid ' + color }}>{row.solicitor}</th></tr>
							<tr><td>Proxies:</td><td>{row.proxyCount}</td></tr>
							<tr><td>Proxies 2019:</td><td>{row.proxyCount19 || '-'}</td></tr>
							<tr><td>Proxies 2020:</td><td>{row.proxyCount20 || '-'}</td></tr>
							<tr><td>Proxies 2021:</td><td>{row.proxyCount21 || '-'}</td></tr>
							<tr><td>Proxies 2022:</td><td>{row.proxyCount22 || '-'}</td></tr>
							<tr><td>Proxies 2023:</td><td>{row.proxyCount23 || '-'}</td></tr>
							<tr><td>Proxies 2024:</td><td>{row.proxyCount24 || '-'}</td></tr>
							<tr><td>Annual:</td><td>{row.annual || '-'}</td></tr>
							<tr><td>Special:</td><td>{row.special || '-'}</td></tr>
							<tr><td>Routine:</td><td>{row.routine || '-'}</td></tr>
							<tr><td>Non-Routine:</td><td>{row.nonRoutine || '-'}</td></tr>
						</tbody></table></div>
						{/*</TooltipWrapper>*/}
					</React.Fragment>;
				}
			}
			: {};


		return (
			<React.Fragment>
				<Link to="/solicitors">Proxy Filings by Solicitor</Link>
				{renderFunnelChart(chartInfo)}
			</React.Fragment>
		);

	}

	private renderSolicitorProxyCountsTable() {
		var columns = [
			{
				dataField: 'solicitor',
				text: 'Solicitor',
				sort: true,
				headerStyle: { width: '100px' },
				headerTitle: () => 'Solicitor of Proxy',
				title: (cell: any) => `Visit Website of ${cell}`,
				formatter: (cell: any, row: any) => solicitorUrl(cell),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount',
				text: 'Proxies',
				sort: true,
				align: "right",
				headerStyle: { width: '80px' },
				headerTitle: () => '# Proxies by Solicitor',
				title: (cell: any, row: any) => `View List of Proxies Solicited By ${row.solicitor}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"proxies"} onClick={() => this.setProxyFilterSolicitor(row.solicitor)} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			}
		];

		return (
			<ToolkitProvider
				keyField="solicitor"
				data={this.props.dashboardData && this.props.dashboardData.dashboardData4 ? this.props.dashboardData.dashboardData4 : []}
				columns={columns}
			>{
					props => (
						<div>
							<Link to="/solicitors">Proxy Filings by Solicitor</Link>
							<BootstrapTable
								{...props.baseProps}
								bootstrap4={true}
								striped={true}
								bordered={false}
								headerClasses="table-header-class"
								classes={"unset-width"}
								noDataIndication={this.emptyDataMessage}
								hover
								condensed
								defaultSorted={[{
									dataField: 'proxyCount', // if dataField is not match to any column you defined, it will be ignored.
									order: 'desc' // desc or asc
								}]}
							/>
						</div>
					)
				}
			</ToolkitProvider>
		);
	}

	// Show 10 Upcoming Meeting Dates
	private renderMeetingDateProxyCountsTable() {
		var columns = [
			{
				dataField: 'meetingDate',
				text: 'Date',
				sort: true,
				align: "right",
				headerAlign: 'right',
				//headerClasses: "text-right",
				headerStyle: { width: '90px' },
				headerTitle: () => 'Proxy Meeting Date',
				formatter: (cell: any, row: any) => dateToString(cell),
				csvFormatter: (cell: any) => dateToString(cell)
			},
			{
				dataField: 'proxyCount',
				text: 'Proxies',
				sort: true,
				align: "right",
				headerStyle: { width: '80px' },
				headerTitle: () => '# Proxies with Meeting Date',
				title: (cell: any, row: any) => `View List of Proxies with meeting date ${dateToString(row.meetingDate)}`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"proxies"} onClick={() => this.setProxyFilterMeetingDate(row.meetingDate)} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			}
		];

		return (
			<ToolkitProvider
				keyField="meetingDate"
				data={this.props.dashboardData && this.props.dashboardData.dashboardData5 ? this.props.dashboardData.dashboardData5.slice(0, 10) : []}
				columns={columns}
			>{
					props => (
						<div>
							<Link to="/report/upcomingmeetings">Upcoming Meetings</Link>
							<BootstrapTable
								{...props.baseProps}
								bootstrap4={true}
								striped={true}
								bordered={false}
								headerClasses="table-header-class"
								classes={"unset-width"}
								noDataIndication={this.emptyDataMessage}
								hover
								condensed
								defaultSorted={[{
									dataField: 'meetingDate', // if dataField is not match to any column you defined, it will be ignored.
									order: 'asc' // desc or asc
								}]}
							/>
						</div>
					)
				}
			</ToolkitProvider>
		);
	}

	// Meeting Dates Chart
	private renderMeetingDatesChart(chartInfo: any = {}, allMeetings: boolean = false) {
		if (this.props.isLoading)
			return (<></>);
		// TODO: Hide Saturday, Sunday?
		chartInfo = {
			direction: 'vertical',
			height: 1024,
			width: 300,
			key: 'meetingDate',
			chartType: 'TimeRange',
			margin: { top: 75, right: 40, bottom: 40, left: 20 },
			onCalendarClick: (data: any, key: string, chart: NivoCalendarChart) => { this.onCalendarClick(data, key, chart) },
			...chartInfo
		};

		chartInfo.data = (this.props.dashboardData && this.props.dashboardData.dashboardData5 ? this.props.dashboardData.dashboardData5 : [])
			.map((d: any, index: number) => {
				let data: any = {};
				data.day = d.meetingDate.substr(0, 10);
				data.value = d.proxyCount;
				data.row = d;
				return data;
			});
		chartInfo.tooltip = (props: any) => {
			const dayStr = format(parseISO(props.row.meetingDate), 'M/d/yyyy');
			return (
				<BasicTooltip
					id={dayStr}
					value={props.value}
					color={props.color}
					enableChip
				/>
			);
		}
		let fromStr: string = _.min(chartInfo.data.map((d: any) => d.day)) || '2024-01-01'; // TODO: Today
		let toStr: string = _.max(chartInfo.data.map((d: any) => d.day)) || '2024-12-31'; // TODO: Always From + 8 weeks - weekDay?
		let from: Date = parseISO(fromStr);
		let to: Date = parseISO(toStr);
		let fromWeekDay = from.getDay();
		const weeksToShow = 8; // Number of Weeks to show in Time Range Chart
		from.setDate(from.getDate() + (weeksToShow * 7) - fromWeekDay);

		chartInfo.from = parseISO(fromStr);
		chartInfo.to = _.min([to, from]);

		let renderCalendarChartColors = () => {
			return (<>{CalendarChartColors.map((item) => (
				<><span style={{ backgroundColor: item }} className='calendarChartColor'>{item}</span><br /></>
			))}</>);
		};

		return (<React.Fragment>
			<>
				{/*{renderCalendarChartColors()}*/}
				<Link to="/report/upcomingmeetings">Upcoming Meetings</Link>
				{renderCalendarChart(chartInfo)}
			</>
		</React.Fragment>);
	}

	onCalendarClick = (data: any, key: any, chart: NivoCalendarChart) => {
		let stateToSet: any = {};
		stateToSet[key] = data.day;

		this.setProxyFilter(stateToSet);
		this.props.history.push('/proxies');
	}

	private renderMeetingTypeProxyCountsTable() {
		var columns = [
			{
				dataField: 'meetingType',
				text: 'Type',
				sort: true,
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: '90px' },
				headerTitle: () => 'Proxy Meeting Type',
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'proxyCount',
				text: 'Proxies',
				sort: true,
				align: "right",
				headerStyle: { width: '80px' },
				headerTitle: () => '# Proxies with Meeting Type',
				title: (cell: any, row: any) => `View List of Proxies with ${row.meetingType} Meeting`,
				formatter: (cell: any, row: any) => {
					return (
						<Link to={"proxies"} onClick={() => this.setProxyFilterMeetingType(row.meetingType)} className="mr-4">{formatNumber(cell)}</Link>
					);
				},
				csvFormatter: (cell: any) => cell ? cell : ''
			}
		];

		return (
			<ToolkitProvider
				keyField="meetingType"
				data={this.props.dashboardData && this.props.dashboardData.dashboardData6 ? this.props.dashboardData.dashboardData6 : []}
				columns={columns}
			>{
					props => (
						<div>
							<Link to="/report/meetingtypes">Meeting Types</Link>
							<BootstrapTable
								{...props.baseProps}
								bootstrap4={true}
								striped={true}
								bordered={false}
								headerClasses="table-header-class"
								classes={"unset-width"}
								noDataIndication={this.emptyDataMessage}
								hover
								condensed
								defaultSorted={[{
									dataField: 'meetingType', // if dataField is not match to any column you defined, it will be ignored.
									order: 'asc' // desc or asc
								}]}
							/>
						</div>
					)
				}
			</ToolkitProvider>
		);
	}

	// Solicitor Market Share Pie Chart
	private renderSolicitorProxyCountsDist() {
		if (this.props.isLoading)
			return (<></>);

		let pieInfo: any = [
			{ fieldName: "percentage", hideTickLabel: true, label: "value", name: "percentageTxt" }
		];

		let sData = (this.props.dashboardData?.dashboardData4 || []);
		let total = _.sumBy(sData, 'proxyCount');
		sData = sData.map(d => {
			let percentage = ((d.proxyCount || 0) * 100) / total;
			let solicitor = percentage > 4 ? d.solicitor : 'Other';
			return { solicitor, percentage, d, proxyCount: d.proxyCount };
		});

		sData = _(sData).groupBy('solicitor').map((value, key) => {
			return {
				solicitor: key,
				percentage: _.sumBy(value, 'percentage'),
				value
			};
		}).value();

		const key: string = 'solicitor';
		let chartInfo: any = {
			key,
			pieInfo,
			data: sData || [],
			width: 350,
			height: 350,
			outerRadius: 150,
			pieSpacing: 25,
			margin: { top: 5, left: 0 },
			//title: 'Yearly Proxy Filings by Meeting Types',
			onPieClick: (lineInfo: any, data: any, value: any, key: string, chart: RCStackedChart) => { this.onActiveDotClick(lineInfo, data, value, key, chart) },
			//label: "solicitor",
			customLabel: (props: any) => {
				const RADIAN = Math.PI / 180;
				let midAngle = (props.viewBox.startAngle + props.viewBox.endAngle) / 2;
				const radius = props.viewBox.innerRadius + (props.viewBox.outerRadius - props.viewBox.innerRadius) * 0.5;
				const x = props.cx + radius * Math.cos(-midAngle * RADIAN);
				const y = props.cy + radius * Math.sin(-midAngle * RADIAN);
				return (
					<text x={x} y={y} fill="white" textAnchor={x > props.cx ? 'start' : 'end'} dominantBaseline="central" style={{ pointerEvents: 'none' }}>
						<tspan x={x} text-anchor="middle">{`${props.value[0].solicitor}`}</tspan>
						<tspan x={x} text-anchor="middle" dy="18">{`${formatNumber(_.sumBy(props.value, 'proxyCount'))} (${(_.sumBy(props.value, 'percentage')).toFixed(2)}%)`}</tspan>
					</text>
				);
			},
			tooltip: (props: any) => {
				let d: any = props.payload[0].payload;
				let color: any = d.fill;
				let rows: any = d.value;
				return <React.Fragment>
					<div className='nivoCalendarDiv' style={{ border: '1px solid ' + color }}>
						<table style={{ color }}>
							<tbody>
								{rows.map((r: any) => {
									return <tr><td>{r.d.solicitor} : {formatNumber(r.d.proxyCount)} ({r.percentage.toFixed(2)}%)</td></tr>
								})}
							</tbody>
						</table></div>
				</React.Fragment>;
			}
		};

		return (<React.Fragment>
			<>
				<Link to="/report/solicitors">Market Share of Proxy Filings by Solicitor</Link>
				{renderPieChart(chartInfo)}
			</>
		</React.Fragment>);

	}

	// Meeting Type Chart
	private renderMeetingTypeChart() {
		if (this.props.isLoading)
			return (<></>);

		let pieInfo: any = [
			//{ fieldName: "annual", name: "Annual" },
			//{ fieldName: "special", name: "Special" }
			{ fieldName: "proxyCount", hideTickLabel: true, name: "meetingType", label: "proxyCount" },
			//{ fieldName: "special", name: "Special" }
		];

		const key: string = 'meetingType';
		let chartInfo: any = {
			key,
			pieInfo,
			data: this.props.dashboardData?.dashboardData6 || [],
			width: 350,
			height: 350,
			outerRadius: 150,
			pieSpacing: 25,
			margin: { top: 5, left: 0 },
			//title: 'Yearly Proxy Filings by Meeting Types',
			onPieClick: (lineInfo: any, data: any, value: any, key: string, chart: RCStackedChart) => { this.onActiveDotClick(lineInfo, data, value, key, chart) },
			label: "meetingType",
			customLabel: (props: any) => {
				const RADIAN = Math.PI / 180;
				let midAngle = (props.viewBox.startAngle + props.viewBox.endAngle) / 2;
				const radius = props.viewBox.innerRadius + (props.viewBox.outerRadius - props.viewBox.innerRadius) * 0.5;
				const x = props.cx + radius * Math.cos(-midAngle * RADIAN);
				const y = props.cy + radius * Math.sin(-midAngle * RADIAN);
				return (
					<text x={x} y={y} fill="white" textAnchor={x > props.cx ? 'start' : 'end'} dominantBaseline="central" style={{ pointerEvents: 'none' }} >
						<tspan x={x} text-anchor="middle">{`${props.name}`}</tspan>
						<tspan x={x} text-anchor="middle" dy="17">{`${formatNumber(props.value)}`}</tspan>
					</text>
				);
			},
			tooltip: (props: any) => {
				let d: any = props.payload[0].payload;
				let color: any = d.fill;
				return <React.Fragment>
					<div className='nivoCalendarDiv' style={{ border: '1px solid ' + color }}>
						<table style={{ color }}>
							<tbody>
								<tr><td className='text-right'>{d.name} :</td><td className='text-right'>{formatNumber(d.proxyCount)}</td></tr>
								<tr><td className='text-right'>2024 :</td><td className='text-right'>{formatNumber(d.proxyCount24)}</td></tr>
								<tr><td className='text-right'>2023 :</td><td className='text-right'>{formatNumber(d.proxyCount23)}</td></tr>
								<tr><td className='text-right'>2022 :</td><td className='text-right'>{formatNumber(d.proxyCount22)}</td></tr>
								<tr><td className='text-right'>2021 :</td><td className='text-right'>{formatNumber(d.proxyCount21)}</td></tr>
								<tr><td className='text-right'>2020 :</td><td className='text-right'>{formatNumber(d.proxyCount20)}</td></tr>
								<tr><td className='text-right'>2019 :</td><td className='text-right'>{formatNumber(d.proxyCount19)}</td></tr>
								<tr><td className='text-right'>Routine :</td><td className='text-right'>{formatNumber(d.routine)}</td></tr>
								<tr><td className='text-right'>Non-Routine :</td><td className='text-right'>{formatNumber(d.nonRoutine)}</td></tr>
								<tr><td className='text-right'>Open-End :</td><td className='text-right'>{formatNumber(d.openEnd)}</td></tr>
								<tr><td className='text-right'>Closed-End :</td><td className='text-right'>{formatNumber(d.closedEnd)}</td></tr>
							</tbody>
						</table></div>
				</React.Fragment>;
			}
		};

		return (<React.Fragment>
			<>
				<Link to="/report/meetingtypes">Meeting Types</Link>
				{renderPieChart(chartInfo)}
			</>
		</React.Fragment>);
	}

	private setProxyFilterFamily(family: string) {
		this.context.setProxyFilter("", family, "", "", "", "");
	}

	private setProxyFilterCik(cik: number) {
		this.context.setProxyFilter(cik.toString(), "", "", "", "", "");
		//this.props.history.push('/proxies');
		//e.preventDefault();
	}

	private setOwnershipFilingFilterYear(year: number) {
		this.context.setOwnershipFilter("", year.toString());
	}

	private setProxyFilterYear(year: number) {
		this.context.setProxyFilter("", "", year.toString(), "", "", "");
	}

	private setProxyFilterSolicitor(solicitor: string) {
		this.context.setProxyFilter("", "", "", solicitor, "", "");
	}

	private setProxyFilterMeetingDate(meetingDate: Date) {
		this.context.setProxyFilter("", "", "", "", "", meetingDate.toString());
	}

	private setProxyFilterMeetingType(meetingType: string) {
		this.context.setProxyFilter("", "", "", "", meetingType, "");
	}

	public setProxyFilter({ cik = '', family = '', year = '', solicitor = '', meetingType = '', meetingDate = '', routine = '', openOrClosedCIK = '' }: ProxyFilterParameters) {
		if (meetingDate) {
			meetingDate = (typeof meetingDate === "string") ? DateToJSON(parseISO(meetingDate)) : DateToJSON(meetingDate);
		}
		this.context.setProxyFilter(cik, family, year, solicitor, meetingType, meetingDate, routine, openOrClosedCIK);
	}
}

export default connect(
	(state: ApplicationState) => state.dashboardData, // Selects which state properties are merged into the component's props
	DashboardStore.actionCreators // Selects which action creators are merged into the component's props
)(Dashboard as any); // eslint-disable-line @typescript-eslint/no-explicit-any
