import $ from 'jquery';
import * as React from 'react';
import 'react-contexify/ReactContexify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AppContext } from '../AppContext';
import { ApplicationState } from '../store';
import * as OwnershipFilingStore from '../store/OwnershipData';
import { dateToString, fetchWithAuth, filingIndexLink, filingLink } from '../store/Util';
import BootstrapTable, { SelectRowProps } from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

const { SearchBar } = Search;

// At runtime, Redux will merge together...
type OwnershipFilingDataProps =
	OwnershipFilingStore.OwnershipDataState // ... state we've requested from the Redux store
	& typeof OwnershipFilingStore.ownershipFilingDataActionCreators // ... plus action creators we've requested
	& RouteComponentProps<{ san: string }>; // ... plus incoming routing parameters

class OwnershipFilingData extends React.PureComponent<OwnershipFilingDataProps,
	{ groupMemberName?: string, name?: string, datapoint?: string, san?: string, openFilingDialog?: boolean, filingText?: string, filingData?: any }> {
	static contextType = AppContext;
	context!: React.ContextType<typeof AppContext>;
	scrollToGroupMemberDataRequited: boolean = false;

	constructor(props: any) {
		super(props);
		this.state = { openFilingDialog: false };
	}

	// This method is called when the component is first added to the document
	public componentDidMount() {
		this.props.resetState();
		this.ensureDataFetched();
		this.context.changeTitle('Ownership Filing Data');
		this.bindClickOnNamedAnchor();
	}

	// This method is called when the route parameters change
	public componentDidUpdate() {
		this.ensureDataFetched();
		this.scrollToGroupMemberData();
	}

	bindClickOnNamedAnchor = () => {
		$(document).on('click', ".filing-text a[href^='#']", function (e) {
			let href = $(this).attr('href') || '';
			if (href > '#') {
				let element = document.getElementById(href.replace('#', ''));
				if (!element) {
					let elements = document.getElementsByName(href.replace('#', ''));
					if (elements)
						element = elements[0];
				}
				if (element) {
					element.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
				}
				e.preventDefault();
				e.stopPropagation();
			}
		});
	}

	public render() {
		return (
			<React.Fragment>
				<div className="col-8 overflow-auto sticky-footer-wrapper">
					<div className="position-absolute pl-2 pr-4 pb-2">
						{this.renderOwnershipFilingData()}
						{this.renderGroupMembersData()}
					</div>
				</div>
				<div className="col-4 overflow-auto border-left">
					<div className="position-absolute filing-text" dangerouslySetInnerHTML={{ __html: this.props.filingText || '' }}>
					</div>
				</div>
				{this.renderModal()}
			</React.Fragment>
		);
	}

	// Show Filing Dialog
	public renderModal() {
		return (<Modal isOpen={this.state.openFilingDialog} returnFocusAfterClose={false}
			centered={true} backdrop={true} keyboard={true} size="lg"
			toggle={() => this.closeFilingModal(false)} onHide={() => this.closeFilingModal(false)}>
			<ModalHeader toggle={() => this.closeFilingModal(false)} className="d-contents">
				<div className="d-flex justify-content-between">
					<div>{this.state.groupMemberName}</div>
					<div>{this.dateToString(this.state.filingData?.dateFiled)}</div>
					<div>{this.state.filingData?.formType}</div>
				</div>
			</ModalHeader>
			<ModalBody>
				<div className="filing-modal" id="filing-modal">
					<div className="filing-text" dangerouslySetInnerHTML={{ __html: this.state.filingText || '' }}>
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button
					color="primary"
					onClick={(e) => this.closeFilingModal(false, e)}
				>
					Close
				</Button>
			</ModalFooter>
		</Modal>);
	}

	// Close Filing Dialog
	private closeFilingModal(userAction: boolean, e?: any) {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		this.setState({ openFilingDialog: false });
	}

	showFilingModal = (e: any, san: string, groupMemberName: string, name: string, datapoint: string) => {
		e.preventDefault();
		e.stopPropagation();
		if (san != this.state.san) {
			fetchWithAuth({ url: `api/edgar/ownership-file`, queryParams: { san: san } }) // api/Edgar/Fmaster
				.then(response => {
					return response.json() as Promise<any>;
				})
				.then(data => {
					this.setState({ openFilingDialog: true, san, name, datapoint, filingText: data.filingText, groupMemberName, filingData: data });
					setTimeout(() => {
						this.scrollToModalFilingAnchor(e, `offset_${name}_${datapoint}_Start`, `offset_${name}_${datapoint}_End`)
					}, 500);
				})
				.catch(error => {
					console.error('There was an error!', error);
				});
		}
		else { // Filing not Changed
			this.setState({ openFilingDialog: true, groupMemberName, name, datapoint });
			setTimeout(() => {
				this.scrollToModalFilingAnchor(e, `offset_${name}_${datapoint}_Start`, `offset_${name}_${datapoint}_End`)
			}, 500);
		}
	}

	scrollToAnchor = (e: any, id: string, idEnd: string) => {
		e.preventDefault();
		var el = document.getElementById(id);
		var elEnd = document.getElementById(idEnd);
		if (el) {
			el.scrollIntoView({ behavior: 'smooth' });

			let range = new Range();
			range.setStart(el, 0);
			range.setEnd(elEnd || el, 0);
			document.getSelection()?.removeAllRanges();
			document.getSelection()?.addRange(range);
		}
	}

	scrollToModalFilingAnchor = (e: any, id: string, idEnd: string) => {
		e.preventDefault();
		var el = $(`.filing-modal #${id}`).get(0);
		var elEnd = $(`.filing-modal #${idEnd}`).get(0);
		if (el) {
			el.scrollIntoView({ behavior: 'smooth' });

			let range = new Range();
			range.setStart(el, 0);
			range.setEnd(elEnd || el, 0);
			document.getSelection()?.removeAllRanges();
			document.getSelection()?.addRange(range);
		}
	}

	scrollToGroupMemberData = () => {
		if (this.scrollToGroupMemberDataRequited) {
			setTimeout(() => {
				var element = document.getElementById("group-member-data");
				if (element) {
					element.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
					this.scrollToGroupMemberDataRequited = false;
				}
			}, 500);
		}
	}

	renderAuditAnchor = (data: any, datapoint: string) => {
		let name: string = data["groupMemberName"]?.replace(/[^0-9a-zA-Z]/gi, "")?.toLowerCase();
		let label: string = data[datapoint];
		if (datapoint == "soleVotingPower" || datapoint == "sharedVotingPower" || datapoint == "soleDispositivePower"
			|| datapoint == "sharedDispositivePower" || datapoint == "aggregateAmount") {
			label = label?.toLocaleString();
		} else if (datapoint == "percentOfClass") {
			label = label || `${label}` === "0" ? `${label}%` : "";
		}
		return (<React.Fragment>
			<Link id={`LINK_${name}_${datapoint}`} className={data[`${datapoint}Start`] ? '' : 'd-none'} to="#auditanchor" onClick={(e) => this.scrollToAnchor(e, `offset_${name}_${datapoint}_Start`, `offset_${name}_${datapoint}_End`)}>{label}</Link>
			<span id={`SPAN_${name}_${datapoint}`} className={data[`${datapoint}Start`] ? 'd-none' : ''} >{label}</span>
		</React.Fragment>);
	}

	renderModalFilingAuditAnchor = (data: any, datapoint: string) => {
		let san: string = data["san"];
		let groupMemberName: string = data["groupMemberName"];
		let name: string = groupMemberName?.replace(/[^0-9a-zA-Z]/gi, "")?.toLowerCase();
		let label: string = data[datapoint];
		if (datapoint == "soleVotingPower" || datapoint == "sharedVotingPower" || datapoint == "soleDispositivePower"
			|| datapoint == "sharedDispositivePower" || datapoint == "aggregateAmount") {
			label = label?.toLocaleString();
		} else if (datapoint == "percentOfClass") {
			label = label || `${label}` === "0" ? `${label}%` : "";
		}
		return (<React.Fragment>
			<Link id={`LINK_${name}_${datapoint}`} className={data[`${datapoint}Start`] ? '' : 'd-none'} to="#auditanchor" onClick={(e) => this.showFilingModal(e, san, groupMemberName, name, datapoint)}>{label}</Link>
			<span id={`SPAN_${name}_${datapoint}`} className={data[`${datapoint}Start`] ? 'd-none' : ''} >{label}</span>
		</React.Fragment>);
	}

	renderOwnershipFilingData = () => {
		return (
			<div className="flex-grow-1">
				<div className="row">
					<div className="col">
						<label>SEC Accession Number: {filingLink(this.props.data.fileName, this.props.data.san)}<br /></label>
					</div>
				</div>
				<div className="row">
					<div className="col">
						Form Type: <span className="text-nowrap">{filingIndexLink(this.props.data.fileName, this.props.data.san, this.props.data.formType)}</span><br />
					</div>
					<div className="col">
						<label>Date Filed: {this.dateToString(this.props.data.dateFiled)}<br /></label>
					</div>
					<div className="col">
						File Size: <span className="text-nowrap">{this.props.data.fileSize ? this.props.data.fileSize.toLocaleString() : ""}</span><br />
					</div>
				</div>
				<div className="row">
					<div className="col">
						<label>CUSIPs: {this.props.data.data && this.props.data.data[0] && this.props.data.data[0]["cusips"] ? this.props.data.data[0]["cusips"] : ""}<br /></label>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<label>Subject: <Link to={"/ownership-filings"} onClick={() => this.setOwnershipFilter(this.props.data.subjectCompanyCik, "")}>{this.props.data.subjectCompany}</Link><br /></label>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<label>Filed By: <Link to={"/ownership-filings"} onClick={() => this.setOwnershipFilter(this.props.data.filedByCik, "")}>{this.props.data.filedBy}</Link><br /></label>
					</div>
				</div>
				{this.groupMembersData(this.props?.data?.data)}
				<div className="row">
					<div className="col">
						<label>Group Members: {this.groupMembers(this.props.data.filingGroupMembers)}<br /></label>
					</div>
				</div>
			</div>
		);
	};

	public groupMembersData(groupMembersData?: any[] | undefined) {
		if (groupMembersData && groupMembersData.length) {
			// Hide Source of Funds Column for 13G and 13G/A
			return <div>
				<table className="table table-striped table-hover unset-width">
					<thead>
						<tr className="table-header-class">
							<th>Name</th>
							{(this.props.data.formType == 'SC 13D' || this.props.data.formType == 'SC 13D/A') ? <th>Source of Funds</th> : <></>}
							<th>Citizenship</th>
							<th align="right">Sole Voting Power</th>
							<th align="right">Shared Voting Power</th>
							<th align="right">Sole Dispositive Power</th>
							<th align="right">Shared Dispositive Power</th>
							<th align="right">Aggregate Amount</th>
							<th align="right">Percent of Class</th>
							<th>Type of Reporting Person</th>
						</tr>
					</thead>
					<tbody>
						{
							groupMembersData.map(g => {
								return <tr>
									<td>
										<Link to={'#'} onClick={(e) => this.showGroupMemberData(e, g.groupMemberName)}>{g.groupMemberName}</Link>
									</td>
									{(this.props.data.formType == 'SC 13D' || this.props.data.formType == 'SC 13D/A') ? <td>{this.renderAuditAnchor(g, 'sourceOfFunds')}</td> : <></>}
									<td>{this.renderAuditAnchor(g, 'citizenship')}</td>
									<td align="right">{this.renderAuditAnchor(g, 'soleVotingPower')}</td>
									<td align="right">{this.renderAuditAnchor(g, 'sharedVotingPower')}</td>
									<td align="right">{this.renderAuditAnchor(g, 'soleDispositivePower')}</td>
									<td align="right">{this.renderAuditAnchor(g, 'sharedDispositivePower')}</td>
									<td align="right">{this.renderAuditAnchor(g, 'aggregateAmount')}</td>
									<td align="right">{this.renderAuditAnchor(g, 'percentOfClass')}</td>
									<td>{this.renderAuditAnchor(g, 'typeOfReportingPerson')}</td>
								</tr>;
							})
						}
					</tbody>
				</table>
			</div>
		}
		return <></>;
	}

	public renderGroupMembersData() {
		if (this.props.groupMemberName && this.props.groupMemberData?.length) {
			return <div id="group-member-data">
				{this.renderGroupMembersDataTable()}
			</div>
		}
		return <></>;
	}

	MyExportCSV = (props: any) => {
		const handleClick = () => {
			props.onExport(props.data);
		};
		return (
			<img src={"microsoft-excel.svg"} height="30px" onClick={handleClick} className="float-right pr-4 mt-1" role="button" title="Export Group Member Data to Excel (.csv)" />
		);
	}

	ClearButton = (props: any) => {
		const handleClick = () => {
			props.onSearch("");
			//props.clearAllFilters();
		};
		return (
			<button
				className="btn btn-outline-primary btn-sm ml-4 clear-button"
				onClick={handleClick}
			>
				Clear
			</button>
		);
	};

	// Custom match logic on every cell value
	private onColumnMatch(opts: any) {
		const getNodeText: any = (node: any) => {
			if (['string', 'number'].includes(typeof node)) return node;
			if (node instanceof Array) return node.map(getNodeText).join('');
			if (typeof node === 'object' && node) return getNodeText(node.props.children);
		}
		var targetValue = getNodeText(opts.value);
		if (targetValue !== null && typeof targetValue !== 'undefined') {
			targetValue = targetValue.toString().toLowerCase();
			if (targetValue.indexOf(opts.searchText) > -1) {
				return true;
			}
		}
	}

	private renderGroupMembersDataTable() {
		const selectRow: SelectRowProps<any> = {
			mode: 'checkbox',
			clickToSelect: true,
			hideSelectColumn: true,
			bgColor: 'lightgray' // '#00BFFF'
		};

		var columns = [
			{
				dataField: 'dateFiled',
				text: 'Date Filed',
				sort: true,
				align: "right",
				headerStyle: () => { return { width: '100px' }; },
				headerTitle: () => 'Date Filed on SEC EDGAR',
				//title: () => 'Date Filed on SEC EDGAR',
				formatter: (cell: any, row: any) => dateToString(cell),
				csvFormatter: (cell: any) => dateToString(cell)
			},
			{
				dataField: 'cusips',
				text: 'CUSIPs',
				sort: true,
				headerStyle: () => { return { width: '100px' }; },
				headerTitle: () => "CUSIPs",
				title: (cell: any) => `CUSIPs`,
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'sourceOfFunds',
				text: 'Source of Funds',
				//hidden: (this.props.data.formType == 'SC 13D' || this.props.data.formType == 'SC 13D/A'),
				sort: true,
				headerTitle: () => "Source of Funds",
				formatter: (cell: any, row: any) => this.renderModalFilingAuditAnchor(row, 'sourceOfFunds'),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'citizenship',
				text: 'Citizenship',
				sort: true,
				headerTitle: () => "Citizenship",
				formatter: (cell: any, row: any) => this.renderModalFilingAuditAnchor(row, 'citizenship'),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'soleVotingPower',
				text: 'Sole Voting Power',
				sort: true,
				align: "right",
				headerTitle: () => "Sole Voting Power",
				formatter: (cell: any, row: any) => this.renderModalFilingAuditAnchor(row, 'soleVotingPower'),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'sharedVotingPower',
				text: 'Shared Voting Power',
				sort: true,
				align: "right",
				headerTitle: () => "Shared Voting Power",
				formatter: (cell: any, row: any) => this.renderModalFilingAuditAnchor(row, 'sharedVotingPower'),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'soleDispositivePower',
				text: 'Sole Dispositive Power',
				sort: true,
				align: "right",
				headerTitle: () => "Sole Dispositive Power",
				formatter: (cell: any, row: any) => this.renderModalFilingAuditAnchor(row, 'soleDispositivePower'),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'sharedDispositivePower',
				text: 'Shared Dispositive Power',
				sort: true,
				align: "right",
				headerTitle: () => "Shared Dispositive Power",
				formatter: (cell: any, row: any) => this.renderModalFilingAuditAnchor(row, 'sharedDispositivePower'),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'aggregateAmount',
				text: 'Aggregate Amount',
				sort: true,
				align: "right",
				headerTitle: () => "Aggregate Amount",
				formatter: (cell: any, row: any) => this.renderModalFilingAuditAnchor(row, 'aggregateAmount'),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'percentOfClass',
				text: 'Percent of Class',
				sort: true,
				align: "right",
				headerTitle: () => "Percent of Class",
				formatter: (cell: any, row: any) => this.renderModalFilingAuditAnchor(row, 'percentOfClass'),
				csvFormatter: (cell: any) => cell ? cell : ''
			},
			{
				dataField: 'typeOfReportingPerson',
				text: 'Type of Reporting Person',
				sort: true,
				headerTitle: () => "Type of Reporting Person",
				formatter: (cell: any, row: any) => this.renderModalFilingAuditAnchor(row, 'typeOfReportingPerson'),
				csvFormatter: (cell: any) => cell ? cell : ''
			}
		];

		return (
			<ToolkitProvider
				keyField="san"
				data={this.props.groupMemberData || []}
				columns={columns}
				search={{
					searchFormatted: true,
					onColumnMatch: this.onColumnMatch
				}}
				exportCSV={{
					// TODO: Handle Special Characters like Smart Quotes
					fileName: 'GroupMemberData.csv',
					blobType: 'text/csv;charset=utf-8'
				}}
			>{
					props => (
						<div>
							<p><h3>{this.props.groupMemberName}</h3>
								{this.props.groupMemberData?.length ? <this.MyExportCSV {...props.csvProps} /> : ""}
								<SearchBar
									{...props.searchProps}
									//className="ml-4"
									style={{ width: "400px", height: "40px", background: "url(magnifying-glass.png) 370px no-repeat", backgroundSize: "20px 20px", paddingRight: "30px" }}
								/>
								<this.ClearButton
									{...props.searchProps}
								/>
							</p>
							<BootstrapTable
								{...props.baseProps}
								selectRow={selectRow}
								bootstrap4={true}
								striped={true}
								bordered={false}
								headerClasses="table-header-class"
								classes={"unset-width"}
								//noDataIndication={this.emptyDataMessage}
								hover
								//condensed
								defaultSorted={[{
									dataField: 'dateFiled', // if dataField is not match to any column you defined, it will be ignored.
									order: 'desc' // desc or asc
								}]}
								filter={filterFactory()}
							/>
						</div>
					)
				}
			</ToolkitProvider>
		);
	}


	private showGroupMemberData(e: any, groupMemberName: string) {
		e.preventDefault();
		e.stopPropagation();
		this.scrollToGroupMemberDataRequited = true;
		this.props.requestGroupMemberOwnershipData(groupMemberName);
	}

	public groupMembers(groupMembers?: any[] | undefined) {
		if (!groupMembers) return "-";
		var groupMembersStr: string = "";
		groupMembers.forEach(function (member) {
			groupMembersStr += "<li>" + member.groupMember + "</li>";
		});
		if (groupMembersStr) groupMembersStr = "<ol>" + groupMembersStr + "</ol>";
		return (<span dangerouslySetInnerHTML={
			{ __html: groupMembersStr }
		}></span>);
	}

	public filingUrl(fileName?: string) {
		if (!fileName) return "";
		return "https://www.sec.gov/Archives/" + fileName;
	}

	public indexUrl(san?: string, fileName?: string) {
		if (!san || !fileName) return "";
		return "https://www.sec.gov/Archives/" + fileName.replace("-", "").replace(".txt", "/") + san + "-index.htm";
	}

	private dateToString(date?: Date | string) {
		if (!date) return "";
		return (new Date(date).getMonth() + 1) + "/" + (new Date(date).getDate() + 0) + "/" + new Date(date).getFullYear()
	}

	private ensureDataFetched() {
		const san = this.props.match.params.san;
		this.props.requestOwnershipData(san);
	}

	private setOwnershipFilter(cik?: number, year: string = "") {
		this.context.setOwnershipFilter(cik ? cik.toString() : "", year);
	}
}

export default connect(
	(state: ApplicationState) => state.ownershipFilingData, // Selects which state properties are merged into the component's props
	OwnershipFilingStore.ownershipFilingDataActionCreators // Selects which action creators are merged into the component's props
)(OwnershipFilingData as any); // eslint-disable-line @typescript-eslint/no-explicit-any
