import * as React from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';

export default class NavMenu extends React.PureComponent<{ title?: string, showNonDef?: boolean }, { isOpen: boolean }> {
	public state = {
		isOpen: false
	};

	public render() {
		return (
			<header>
				<Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3 pl-0 pr-0 ml-0" light>
					{/*<Container>*/}
					<NavbarBrand tag={Link} to="/" title="eProxy Hub"><img src="logosm.jpg" className="pr-3" alt="CAE Logo" />{/*eProxy Hub*/}</NavbarBrand>
					<NavbarBrand tag={Link} to="/" className='navbar-center mt-2'>{this.props.title}<sup className={'text-secondary'}>{(this.props.showNonDef) ? '*' : ''}</sup></NavbarBrand>
					<NavbarToggler onClick={this.toggle} className="mr-2" />
					<Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
						<ul className="navbar-nav flex-grow bg-primary">
							<LoginMenu>
							</LoginMenu>
						</ul>
					</Collapse>
					{/*</Container>*/}
				</Navbar>
			</header>
		);
	}

	private toggle = () => {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}
}
