import { HubConnectionBuilder, LogLevel, HubConnection, IRetryPolicy, HubConnectionState } from '@microsoft/signalr';

import { ApplicationPaths, QueryParameterNames } from '../components/api-authorization/ApiAuthorizationConstants';
import { buildUrl } from './Util';

var _appConnectionWS_: HubConnection;
var _appVersion_: string = "";

const retryTimes = [0, 2000, 10000, 30000, 60000];

export const ConnectAppWS = async (user: any) => {

	try {
		console.log(user);
		if (!_appConnectionWS_) {
			_appConnectionWS_ = new HubConnectionBuilder()
				.withUrl("/app-ws")
				.withAutomaticReconnect({
					nextRetryDelayInMilliseconds: context => {
						const index = context.previousRetryCount < retryTimes.length ? context.previousRetryCount : retryTimes.length - 1;
						return retryTimes[index];
					}
					})
				.configureLogging(LogLevel.Information)
				.build();

			_appConnectionWS_.on("Connected", (message, appVersion) => {
				console.log(message, " Old Application Version: ", _appVersion_, " New Application Version: ", appVersion);
				_appVersion_ = appVersion;
				switch (message) {
					case "Logout":
						let urlParams = { url: ApplicationPaths.LogOut, queryParams: { returnUrl: window.location.href } };
						//urlParams.queryParams[`${QueryParameterNames.ReturnUrl}`] = window.location.href;
						let logoutUrl: string = buildUrl(urlParams);
						window.history.pushState({ state: { local: true } }, "", logoutUrl);
						window.history.go();
						break;
					case "Reload":
						caches.keys().then((keyList) => {
							console.log(keyList);
							return Promise.all(
								keyList.map((key) => {
									console.log(key);
									return caches.delete(key);
								})
							);
						});
						setTimeout(() => {
							window.location.reload();
						}, 1000);
						break;
				}
			});
			_appConnectionWS_.onreconnected(async  (connectionId?: string) => {
				await _appConnectionWS_.invoke("Connect", user.name, _appVersion_, user.appUserVersion);
			});
			await _appConnectionWS_.start();
			await _appConnectionWS_.invoke("Connect", user.name, _appVersion_, user.appUserVersion);

		}
	}
	catch (e) {
	}
}

export const InvokeClientCommandAppWS = async (command: string, connectionId: string) => {

	try {
		if (_appConnectionWS_ && _appConnectionWS_.state == HubConnectionState.Connected) {
			console.log(`Calling Client (${connectionId}) Cmd (${command})`);
			await _appConnectionWS_.invoke("InvokeClientCommand", command, connectionId);
		}
	}
	catch (e) {
	}
}

export const UpdateLastActivityAppWS = (page?: string) => {

	try {
		if (_appConnectionWS_ && _appConnectionWS_.state == HubConnectionState.Connected) {
			return _appConnectionWS_.invoke("UpdateLastActivity", (document.title || "").split(" - ")[0], _appConnectionWS_.connectionId)
				.catch((error) => Promise.resolve());
		}
	}
	catch (e) {
	}
	return Promise.resolve();
}


export const DisconnectAppWS = async () => {

	try {
		if (_appConnectionWS_ && _appConnectionWS_.state == HubConnectionState.Connected) {
			console.log("Disconnecting");
			await _appConnectionWS_.invoke("DisConnect");
			await _appConnectionWS_.stop();
		}
	}
	catch (e) {
	}
}