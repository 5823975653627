import * as React from 'react';
import { Route } from 'react-router';
import Companies from './components/Companies';
import Dashboard from './components/Dashboard';
import EditData from './components/EditData';
import FetchData from './components/FetchData';
import Funds from './components/Funds';
import Home from './components/Home';
import Report from './components/Report';
import Layout from './components/Layout';
import './custom.css';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import ConnectedUsers from './components/ConnectedUsers';
import FetchOwnershipData from './components/FetchOwnershipData';
import OwnershipFilingData from './components/OwnershipFilingData';

export default () => (
	<Layout>
		<AuthorizeRoute exact path='/' component={Dashboard} />
		<Route exact path='/Home' component={Home} />
		<AuthorizeRoute exact path='/dashboard' component={Dashboard} />
		<AuthorizeRoute path='/companies' component={Companies} />
		<AuthorizeRoute path='/ownership-companies' component={Companies} />
		<AuthorizeRoute path='/proxies/:cik?' component={FetchData} />
		<AuthorizeRoute path='/ownership-filings/:cik?' component={FetchOwnershipData} />
		<AuthorizeRoute path='/ownership-filing/:san?' component={OwnershipFilingData} />
		<AuthorizeRoute path='/view-data/:fund?' component={EditData} />
		<AuthorizeRoute path='/edit-data/:fund?' component={EditData} />
		<AuthorizeRoute exact path='/funds/san/:fundssan?' component={Funds} />
		<AuthorizeRoute exact path='/funds/cik/:fundscik?' component={Funds} />
		<AuthorizeRoute exact path='/funds' component={Funds}/>
		<AuthorizeRoute exact path='/classes/san/:classessan?' component={Funds} />
		<AuthorizeRoute exact path='/classes/cik/:classescik?' component={Funds} />
		<AuthorizeRoute exact path='/classes/seriesid/:seriesid?' component={Funds} />
		<AuthorizeRoute exact path='/classes' component={Funds} />
		<AuthorizeRoute path='/solicitors/:name?' component={Report} />
		<AuthorizeRoute path='/report/:name?' component={Report} />
		<AuthorizeRoute path='/admin/connected-users' component={ConnectedUsers} />
		<Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
	</Layout>
);
