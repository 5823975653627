import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { fetchWithAuth } from './Util';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface OwnershipDataState {
	isLoading: boolean;
	san?: string;
	data: OwnershipFilingData;
	filingText?: string;
	groupMemberName?: string;
	groupMemberData?: any[];
}

export interface OwnershipFilingData {
	san?: string;
	dateFiled?: Date;
	formType?: string;
	fileName?: string;
	fileSize?: number;
	subjectCompany?: string;
	subjectCompanyCik?: number;
	filedBy?: string;
	filedByCik?: number;
	groupMembers?: string;
	description?: string;
	documentFileName?: string;
	filingText?: string;
	filingGroupMembers?: any[];
	data?: any[];
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestOwnershipDataAction {
	type: 'REQUEST_OWNERSHIP_DATA';
	san?: string;
}

interface ReceiveOwnershipDataAction {
	type: 'RECEIVE_OWNERSHIP_DATA';
	san?: string;
	data: OwnershipFilingData;
	filingText?: string;
}

interface RequestGroupMemberOwnershipDataAction {
	type: 'REQUEST_GROUPMEMBER_OWNERSHIP_DATA';
	groupMemberName?: string;
}

interface ReceiveGroupMemberOwnershipDataAction {
	type: 'RECEIVE_GROUPMEMBER_OWNERSHIP_DATA';
	groupMemberName?: string;
	data: any[];
}

interface ResetStateAction {
	type: 'RESET_STATE';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestOwnershipDataAction | ReceiveOwnershipDataAction | ResetStateAction | RequestGroupMemberOwnershipDataAction | ReceiveGroupMemberOwnershipDataAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const ownershipFilingDataActionCreators = {
	requestOwnershipData: (san?: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Only load data if it's something we don't already have (and are not already loading)
		const appState = getState();
		if (appState && appState.ownershipFilingData && san !== appState.ownershipFilingData.san) {
			fetchWithAuth({ url: `api/edgar/ownership-file`, queryParams: { san: san } }) // api/Edgar/Fmaster
				.then(response => {
					return response.json() as Promise<OwnershipFilingData>;
				})
				.then(data => {
					dispatch({ type: 'RECEIVE_OWNERSHIP_DATA', san: san, data: data, filingText: data.filingText });
				})
				.catch(error => {
					console.error('There was an error!', error);
				});

			dispatch({ type: 'REQUEST_OWNERSHIP_DATA', san: san });
		}
	},
	requestGroupMemberOwnershipData: (groupMemberName?: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Only load data if it's something we don't already have (and are not already loading)
		const appState = getState();
		if (appState && appState.ownershipFilingData && groupMemberName !== appState.ownershipFilingData.groupMemberName) {
			fetchWithAuth({ url: `api/edgar/group-member-ownership-data`, queryParams: { groupMemberName: groupMemberName } }) // api/Edgar/Fmaster
				.then(response => {
					return response.json() as Promise<any[]>;
				})
				.then(data => {
					dispatch({ type: 'RECEIVE_GROUPMEMBER_OWNERSHIP_DATA', groupMemberName: groupMemberName, data: data });
				})
				.catch(error => {
					console.error('There was an error!', error);
				});

			dispatch({ type: 'REQUEST_GROUPMEMBER_OWNERSHIP_DATA', groupMemberName: groupMemberName });
		}
	},
	resetState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Reset State
		dispatch({ type: 'RESET_STATE' });
	}
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const ownershipFilingDataUnloadedState: OwnershipDataState = { data: {}, isLoading: false };

export const reducer: Reducer<OwnershipDataState> = (state: OwnershipDataState | undefined, incomingAction: Action): OwnershipDataState => {
	if (state === undefined) {
		return ownershipFilingDataUnloadedState;
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'RESET_STATE':
			return {
				san: '',
				data: {},
				isLoading: false
			};
		case 'REQUEST_OWNERSHIP_DATA':
			return {
				san: action.san,
				data: state.data,
				isLoading: true
			};
		case 'RECEIVE_OWNERSHIP_DATA':
			// Only accept the incoming data if it matches the most recent request. This ensures we correctly
			// handle out-of-order responses.
			if (action.san === state.san) {
				return {
					san: action.san,
					data: action.data,
					filingText: action.filingText,
					isLoading: false
				};
			}
			break;
		case 'REQUEST_GROUPMEMBER_OWNERSHIP_DATA':
			return {
				san: state.san,
				data: state.data,
				filingText: state.filingText,
				isLoading: true,
				groupMemberName: action.groupMemberName,
				groupMemberData: []
			};
		case 'RECEIVE_GROUPMEMBER_OWNERSHIP_DATA':
			// Only accept the incoming data if it matches the most recent request. This ensures we correctly
			// handle out-of-order responses.
			if (action.groupMemberName === state.groupMemberName) {
				return {
					san: state.san,
					data: state.data,
					filingText: state.filingText,
					groupMemberName: action.groupMemberName,
					groupMemberData: action.data,
					isLoading: false
				};
			}
			break;
	}

	return state;
};
