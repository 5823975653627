//import { useNavigate } from "react-router-dom";
//import { useHistory } from 'react-router-dom';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { fetchWithAuth } from './Util';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface DashboardState {
	isLoading: boolean;
	sort?: string;
	direction?: string;
	initialized?: boolean;
	dashboardData?: DashboardData;
}

export interface DashboardData {
	dashboardData1?: DashboardData1[];
	dashboardData2?: DashboardData2[];
	dashboardData3?: DashboardData3[];
	dashboardData4?: DashboardData4[];
	dashboardData5?: DashboardData5[];
	dashboardData6?: DashboardData6[];
	dashboardData7?: DashboardData7[];
}

export interface DashboardData1 {
	cik?: number;
	name?: string;
	dateFiled?: Date;
	series?: number;
	classes?: number;
}

export interface DashboardData2 {
	cik?: number;
	companyName: string;
	formType: string;
	san: string;
	dateFiled: Date;
	date: string;
	funds: string;
	series?: number;
	classes?: number;
	reportDate: Date;
	fileName: string;
	recordDate?: Date;
	meetingDate?: Date;
	meetingType?: string;
	cost?: string;
	solicitor?: string;
	fundCounsel?: string;
	proposal1?: string;
	proposal2?: string;
	proposal3?: string;
	proposal4?: string;
	proposal5?: string;
	proposal6?: string;
	proposal7?: string;
	note?: string;
}

// Yearly Proxy Filing Counts
export interface DashboardData3 {
	year?: number;
	proxyCount?: number;
}

// Proxy Filing Counts by Solicitor
export interface DashboardData4 {
	solicitor?: string;
	proxyCount?: number;
}

// Proxy Filing Counts by Meeting Date
export interface DashboardData5 {
	meetingDate?: Date;
	proxyCount?: number;
}

// Proxy Filing Counts by Meeting Type
export interface DashboardData6 {
	meetingType?: string;
	proxyCount?: number;
}

// Ownership Filing Counts by Year
export interface DashboardData7 {
	year?: number;
	OwnershipFilingCount?: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestDashboardAction {
	type: 'REQUEST_DASHBOARD_DATA';
}

interface ReceiveDashboardAction {
	type: 'RECEIVE_DASHBOARD_DATA';
	dashboardData?: DashboardData;
}

interface ResetStateAction {
	type: 'RESET_STATE';
}
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestDashboardAction | ReceiveDashboardAction | ResetStateAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
	requestDashboard: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Only load data if it's something we don't already have (and are not already loading)
		const appState = getState();
		//const navigate = useNavigate();
		//const history = useHistory();
		if (appState && appState.dashboardData && !appState.dashboardData.initialized) {
			fetchWithAuth({ url: `api/edgar/dashboard` })
				.then(response => {
					if (response.ok) {
						return response.json() as Promise<DashboardData>;
					} else {
						return Promise.reject(response);
					}
				})
				.then(dashboardData => {
					dispatch({ type: 'RECEIVE_DASHBOARD_DATA', dashboardData: dashboardData });
				})
				.catch(error => {
					console.error('There was an error!', error);
					if (error.status === 401) { // Unauthorized
						// Redirect to Login? Regenerate Session and Resubmit?
						//this.props.history.push("/authentication/logout");
						//navigate("/authentication/logout");
						//history.push("/authentication/logout");
					}
				});

			dispatch({ type: 'REQUEST_DASHBOARD_DATA' });
		}
	},
	resetState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Reset State
		dispatch({ type: 'RESET_STATE' });
	}
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedStateDashboard: DashboardState = { dashboardData: {}, isLoading: false, initialized: false };

export const reducer: Reducer<DashboardState> = (state: DashboardState | undefined, incomingAction: Action): DashboardState => {
	if (state === undefined) {
		return unloadedStateDashboard;
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'RESET_STATE':
			return {
				dashboardData: {},
				isLoading: false,
				initialized: false
			};
		case 'REQUEST_DASHBOARD_DATA':
			return {
				dashboardData: state.dashboardData,
				isLoading: true,
				initialized: true
			};
		case 'RECEIVE_DASHBOARD_DATA':
			// Only accept the incoming data if it matches the most recent request. This ensures we correctly
			// handle out-of-order responses.
			return {
				dashboardData: action.dashboardData,
				isLoading: false,
				initialized: true
			};
			break;
	}

	return state;
};
