import addYears from 'date-fns/addYears';
import { Field, Formik } from 'formik';
import $ from 'jquery';
import * as React from 'react';
import { Item, Menu, Separator, Submenu, useContextMenu } from 'react-contexify';
import 'react-contexify/ReactContexify.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { AppContext } from '../AppContext';
import { ApplicationState } from '../store';
import * as ProxyFilingStore from '../store/ProxyData';
import { formatMoney, fetchWithAuth, filingIndexLink, filingLink, fixDateToStringify, sanClassesLink, sanFundsLink } from '../store/Util';
import { FaEdit } from 'react-icons/fa';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const MENU_ID = "menu-id";
const { show } = useContextMenu({
	id: MENU_ID
});

// At runtime, Redux will merge together...
type ProxyDataProps =
	ProxyFilingStore.ProxyDataState // ... state we've requested from the Redux store
	& typeof ProxyFilingStore.proxyFilingDataActionCreators // ... plus action creators we've requested
	& RouteComponentProps<{ fund: string }>; // ... plus incoming routing parameters

class EditData extends React.PureComponent<ProxyDataProps, { openCostValueDialog?: boolean }> {
	static contextType = AppContext;
	context!: React.ContextType<typeof AppContext>;
	solicitorLength: number = 0;
	fundCounselLength: number = 0;
	costLength: number = 0;
	costPaidByLength: number = 0;
	descriptionLength: number = 0;
	proposal1Length: number = 0;
	proposal2Length: number = 0;
	proposal3Length: number = 0;
	proposal4Length: number = 0;
	proposal5Length: number = 0;
	proposal6Length: number = 0;
	proposal7Length: number = 0;
	noteLength: number = 0;
	setFormikFieldValue: any;
	rawContent: string = "";

	constructor(props: any) {
		super(props);
		this.state = { openCostValueDialog: false };
	}

	// This method is called when the component is first added to the document
	public componentDidMount() {
		this.props.resetState();
		this.ensureDataFetched();
		this.context.changeTitle('Proxy Filing Data');
		this.bindClickOnNamedAnchor();
	}

	// This method is called when the route parameters change
	public componentDidUpdate() {
		this.ensureDataFetched();
	}

	bindClickOnNamedAnchor = () => {
		$(document).on('click', ".filing-text a[href^='#']", function (e) {
			let href = $(this).attr('href') || '';
			if (href > '#') {
				let element = document.getElementById(href.replace('#', ''));
				if (!element) {
					let elements = document.getElementsByName(href.replace('#', ''));
					if (elements)
						element = elements[0];
				}
				if (element) {
					element.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
				}
				e.preventDefault();
				e.stopPropagation();
			}
		});
	}

	private getSelectionForOffset(field: string) {
		if (!this.rawContent)
			this.rawContent = $(".filing-text").text();

		var selection = window.getSelection();
		var range = selection?.getRangeAt(0);
		var selectionString = range?.toString() || '';

		//var indexofStartContainer = this.rawContent?.indexOf(selectionString?.trimEnd() || ''); //var indexofStartContainer = this.rawContent?.indexOf(range?.startContainer?.textContent?.trimEnd() || '');

		var startIndex = this.getstartIndexOfSelection(range, selectionString); // indexofStartContainer; //indexofStartContainer + (range?.startOffset || 0);
		var selectionStringPrefix = this.rawContent.slice(Math.max(startIndex - 100, 0), startIndex); //.split('').reverse();
		var selectionStringSuffix = this.rawContent.slice(startIndex + selectionString.length, Math.min(startIndex + selectionString.length + 100, this.rawContent.length)); //.split('');
		this.setFormikFieldValue(`${field}Val`, selectionString);
		this.setFormikFieldValue(`${field}ValSuffix`, selectionStringSuffix);
		this.setFormikFieldValue(`${field}ValPrefix`, selectionStringPrefix);

		this.addOffsetTag(range, this.props.data.san, field);
	}

	private removeOffsetTag(datapoint?: string) {
		let san = this.props.data.san;
		$(`#offset_${san}_${datapoint}_Start`).remove();
		$(`#offset_${san}_${datapoint}_End`).remove();
		$(`#LINK_${san}_${datapoint}`).addClass('d-none');
		$(`#SPAN_${san}_${datapoint}`).removeClass('d-none');
	}

	private addOffsetTag(range: any, san?: string, datapoint?: string) {
		range?.insertNode($(`<a id='offset_${san}_${datapoint}_Start'></a>`)[0]);
		let range2 = document.createRange();

		range2.setStart(range.endContainer, range?.endOffset);
		range2.setEnd(range.endContainer, range?.endOffset);
		range2?.insertNode($(`<a id='offset_${san}_${datapoint}_End'></a>`)[0]);
		$(`#LINK_${san}_${datapoint}`).removeClass('d-none');
		$(`#SPAN_${san}_${datapoint}`).addClass('d-none');
	}

	private getstartIndexOfSelection(range?: Range, selectionString?: string) {
		var previousParentIndex = range?.startOffset || 0;
		var previousParentText = range?.startContainer?.textContent || '';
		if (range?.startOffset || 0 < 500) {
			var parent = range?.startContainer?.parentNode;
			while (parent && !$(parent).hasClass('filing-text')) {
				var parentTxt = $(parent).text();
				previousParentIndex += parentTxt?.indexOf(previousParentText?.trimEnd() || '');
				var currentParentIndex = this.rawContent?.indexOf(parentTxt?.trimEnd() || '');
				var selIndex = this.rawContent?.indexOf(selectionString?.trimEnd() || '', (previousParentIndex + currentParentIndex));
				if (selIndex > 500) {
					return selIndex;
				}
				//previousParentIndex += currentParentIndex;
				previousParentText = parentTxt;
				parent = parent?.parentNode;
			}
		}
		return this.rawContent?.indexOf(selectionString || '', previousParentIndex);
	}

	// Set Data Value from selected text
	private handleItemClick(props: any) {
		let selTxt: string = window.getSelection()?.toString().trim() || '';
		let dataPoint: string = props.data?.datapoint;
		
		this.removeOffsetTag(dataPoint);
		if (selTxt) {
			this.getSelectionForOffset(dataPoint);
		}
		switch (dataPoint) {
			case "description":
			case "cost":
			case "costPaidBy":
			case "solicitor":
			case "fundCounsel":
			case "proposal1":
			case "proposal2":
			case "proposal3":
			case "proposal4":
			case "proposal5":
			case "proposal6":
			case "proposal7":
			case "note":
				(this as any)[`${dataPoint}Length`] = selTxt?.length;
				this.setFormikFieldValue(dataPoint, selTxt);
				break;
			case "recordDate":
			case "meetingDate":
				try {
					let d = new Date((selTxt + ' ' + (new Date(this.props?.data?.dateFiled?.toString()||'').getFullYear())).replace(/(\d+)(th|st|rd),/, '$1,').replace(/\[|\]/g, ''));
					if (d.toString() != "Invalid Date") {
						this.setFormikFieldValue(dataPoint, d);
					}
				}
				catch { }
				break;
			case "meetingType":
			case "routine":
				this.setFormikFieldValue(dataPoint, props.data?.value);
				break;
		}
	}

	private displayMenu(e: any) {
		// put whatever custom logic you need
		// you can even decide to not display the Menu
		show({
			event: e,
		});
	}

	private closeCostValueModal(userAction: boolean, e?: any) {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		if (userAction) {
			let value = (document.getElementById('customCostVal') as HTMLInputElement)?.value;
			if (!isNaN(+(value || ''))) {
				this.setFormikFieldValue('costValue', +(value || '') && +(value || '') > 0 ? +(value || '') : null);
			}
		}
		this.setState({ openCostValueDialog: false });
	}

	public render() {
		return (
			<React.Fragment>
				<div className="col-8 overflow-auto border-right">
					<div onContextMenu={this.displayMenu} className="position-absolute filing-text" dangerouslySetInnerHTML={{ __html: this.props.filingText || '' }}>
					</div>
				</div>
				<div className="col-4 overflow-auto sticky-footer-wrapper">
					<div className="position-absolute pl-2 pr-4 pb-2">
						{this.renderForm()}
					</div>
				</div>
				{this.renderContextMenu()}
				<Modal isOpen={this.state.openCostValueDialog} returnFocusAfterClose={false}
					centered={true} keyboard={true} onHide={() => this.closeCostValueModal(false)}>
					<form onSubmit={(value: any) => { this.closeCostValueModal(true, value); } }>
					<ModalHeader toggle={() => this.closeCostValueModal(false)}>
						Cost value
					</ModalHeader>
					<ModalBody>
							<div className="row">
								<div className="col">
									<div className="form-group">
										<label htmlFor="customCostVal">Please enter Cost value:</label>
										<input className="form-control"
											name="customCostVal"
											id="customCostVal"
											type="number"
											defaultValue={this.props.data.costValue}
										/>
									</div>
								</div>
							</div>
					</ModalBody>
					<ModalFooter>
						<Button
							color="danger"
							onClick={(e) => this.closeCostValueModal(false, e)}
						>
							Cancel
						</Button>
						{' '}
							<Button type="submit" color="primary">
							Ok
						</Button>
						</ModalFooter>
					</form>
				</Modal>
			</React.Fragment>
		);
	}

	renderContextMenu = () => {
		return (<React.Fragment>
			<Menu id={MENU_ID} theme="dark">
				<Item onClick={(props: any) => this.handleItemClick(props)} data={{ datapoint: 'description' }} >
					Description
				</Item>
				<Separator />
				<Item onClick={(props: any) => this.handleItemClick(props)} data={{ datapoint: 'meetingDate' }} >
					Meeting Date
				</Item>
				<Item onClick={(props: any) => this.handleItemClick(props)} data={{ datapoint: 'recordDate' }} >
					Record Date
				</Item>
				<Separator />
				<Submenu label="Meeting Type">
					<Item onClick={(props: any) => this.handleItemClick(props)} data={{ datapoint: 'meetingType', value: 'Annual' }}>
						Annual
					</Item>
					<Item onClick={(props: any) => this.handleItemClick(props)} data={{ datapoint: 'meetingType', value: 'Special' }}>
						Special
					</Item>
				</Submenu>
				<Submenu label="Routine">
					<Item onClick={(props: any) => this.handleItemClick(props)} data={{ datapoint: 'routine', value: 'R' }}>
						Routine
					</Item>
					<Item onClick={(props: any) => this.handleItemClick(props)} data={{ datapoint: 'routine', value: 'N' }}>
						Non-Routine
					</Item>
				</Submenu>
				<Separator />
				<Item onClick={(props: any) => this.handleItemClick(props)} data={{ datapoint: 'cost' }} >
					Cost
				</Item>
				<Item onClick={(props: any) => this.handleItemClick(props)} data={{ datapoint: 'costPaidBy' }} >
					Cost Paid By
				</Item>
				<Separator />
				<Item onClick={(props: any) => this.handleItemClick(props)} data={{ datapoint: 'solicitor' }} >
					Solicitor
				</Item>
				<Item onClick={(props: any) => this.handleItemClick(props)} data={{ datapoint: 'fundCounsel' }} >
					Fund Counsel
				</Item>
				<Separator />
				<Submenu label="Proposals">
					<Item onClick={(props: any) => this.handleItemClick(props)} data={{ datapoint: 'proposal1' }}>
						Proposal 1
					</Item>
					<Item onClick={(props: any) => this.handleItemClick(props)} data={{ datapoint: 'proposal2' }}>
						Proposal 2
					</Item>
					<Item onClick={(props: any) => this.handleItemClick(props)} data={{ datapoint: 'proposal3' }}>
						Proposal 3
					</Item>
					<Item onClick={(props: any) => this.handleItemClick(props)} data={{ datapoint: 'proposal4' }}>
						Proposal 4
					</Item>
					<Item onClick={(props: any) => this.handleItemClick(props)} data={{ datapoint: 'proposal5' }}>
						Proposal 5
					</Item>
					<Item onClick={(props: any) => this.handleItemClick(props)} data={{ datapoint: 'proposal6' }}>
						Proposal 6
					</Item>
					<Item onClick={(props: any) => this.handleItemClick(props)} data={{ datapoint: 'proposal7' }}>
						Proposal 7
					</Item>
				</Submenu>
				<Separator />
				<Item onClick={(props: any) => this.handleItemClick(props)} data={{ datapoint: 'note' }} >
					Note
				</Item>
			</Menu>
		</React.Fragment>);
	}

	scrollToAnchor = (e: any, id: string, idEnd: string) => {
		e.preventDefault();
		var el = document.getElementById(id);
		var elEnd = document.getElementById(idEnd);
		if (el) {
			el.scrollIntoView({ behavior: 'smooth' });

			let range = new Range();
			range.setStart(el, 0);
			range.setEnd(elEnd || el, 0);
			document.getSelection()?.removeAllRanges();
			document.getSelection()?.addRange(range);
		}
	}

	renderAuditAnchor = (label: string, datapoint: string, data: any) => {
		return (<React.Fragment>
			<Link id={`LINK_${data.san}_${datapoint}`} className={data[`${datapoint}StartOffset`] ? '' : 'd-none'} to="#auditanchor" onClick={(e) => this.scrollToAnchor(e, `offset_${data.san}_${datapoint}_Start`, `offset_${data.san}_${datapoint}_End`)}>{label}</Link>
			<span id={`SPAN_${data.san}_${datapoint}`} className={data[`${datapoint}StartOffset`] ?'d-none':''} >{label}</span>
		</React.Fragment>);
	}

	editCostValue = (e: MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({ openCostValueDialog: true });
	}

	tryExtractCostValue = (txt: string) => {
		let valRegEx = /.*\s*(-|to|and)?\s*\$\s*((?<CostValue>[\d,\.]+)\s*(?<Unit>(k|million|thousand)s?)?)/i;
		let m = txt?.match(valRegEx);
		if (m) {
			let val = +(m.groups || {})['CostValue'].replace(/,/g, "");
			let valm = (m.groups || {})['Unit'];
			switch (valm?.toLowerCase()) {
				case "million":
				case "millions":
					val = val * 1000000;
					break;
				case "thousand":
				case "thousands":
				case "k":
					val = val * 1000;
					break;
			}
			this.setFormikFieldValue('costValue', val&&val>0?val:null);
		}
	}

	renderForm = () => {
		//debugger;
		var data = {
			san: '',
			formType: '',
			dateFiled: '',
			filename: '',
			fileSize: null,
			description: null,
			reportDate: '',
			series: null,
			classes: null,
			funds: '',
			url: '',
			recordDate: '',
			meetingDate: '',
			meetingType: '',
			routine: '',
			cost: null,
			costPaidBy: null,
			solicitor: '',
			fundCounsel: '',
			proposal1: '',
			proposal2: '',
			proposal3: '',
			proposal4: '',
			proposal5: '',
			proposal6: '',
			proposal7: '',
			note: '',
			...this.props.data
		}

		this.solicitorLength = data.solicitor ? data.solicitor.length : 0;
		this.fundCounselLength = data.fundCounsel ? data.fundCounsel.length : 0;
		this.costLength = data.cost ? data.cost.length : 0;
		this.costPaidByLength = data.costPaidBy ? data.costPaidBy.length : 0;
		this.descriptionLength = data.description ? data.description.length : 0;
		this.proposal1Length = data.proposal1 ? data.proposal1.length : 0;
		this.proposal2Length = data.proposal2 ? data.proposal2.length : 0;
		this.proposal3Length = data.proposal3 ? data.proposal3.length : 0;
		this.proposal4Length = data.proposal4 ? data.proposal4.length : 0;
		this.proposal5Length = data.proposal5 ? data.proposal5.length : 0;
		this.proposal6Length = data.proposal6 ? data.proposal6.length : 0;
		this.proposal7Length = data.proposal7 ? data.proposal7.length : 0;
		this.noteLength = data.note ? data.note.length : 0;
		return (
			<Formik
				enableReinitialize
				initialValues={{ ...data }}
				onSubmit={(values: any, actions: any) => {
					//e.preventDefault();
					console.log("Enter in submit function", values);
					//debugger;
					const requestOptions = {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(fixDateToStringify(values))
					};

					fetchWithAuth({ url: `api/edgar/proxy?san=${values.san}` }, requestOptions)
						.then(response => {
							//alert('done');
							//return <Redirect to="/proxies" />;
							actions.setSubmitting(false);
							this.props.history.push('/proxies');
							//alert('done1');
						});
				}}
			>
				{props => {
					const {
						values,
						touched,
						errors,
						dirty,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit,
						handleReset,
						setFieldValue
					} = props;
					//alert(values);
					//debugger;
					this.setFormikFieldValue = (field: string, value: any) => {
						if (value && typeof value === "string") {
							value = value.normalize("NFKD"); // Replace &nbsp; with space
						}
						// Set the value of the number field here:
						(this.props.data as any)[field] = value;
						if (field === 'cost') {
							this.tryExtractCostValue(value);
						}
						setFieldValue(field, value)
					};
					let submitHeader = this.context.hasUserRole('ProxyEdit')
						? <nav className="navbar sticky-top navbar-light bg-light border-bottom pl-0">
							<button type="submit" className="btn-primary">Submit</button></nav> : '';
					let submitFooter = this.context.hasUserRole('ProxyEdit')
						? <footer className="page-footer font-small bg-light border-top pt-3 navbar-fixed-bottom">
							<button type="submit" className="btn-primary">Submit</button></footer> : '';
					return (
						<form onSubmit={handleSubmit}>
							{submitHeader}
							{/*<Link to="#auditanchor" onClick={(e) => this.scrollToAnchor(e, "auditanchor")}>Audit Anchor</Link>*/}
							<div className="flex-grow-1">
								SEC Accession Number: {filingLink(this.props.data.fileName, this.props.data.san)}<br />
								<div className="row">
									<div className="col">
										Form Type: <span className="text-nowrap">{filingIndexLink(this.props.data.fileName, this.props.data.san, this.props.data.formType)}</span><br />
									</div>
									<div className="col">
										<label>Date Filed: {this.dateToString(this.props.data.dateFiled)}<br /></label>
									</div>
									<div className="col">
										<label>Report Date: {this.dateToString(this.props.data.reportDate)}<br /></label>
									</div>
								</div>

								<div className="row">
									<div className="col">
										Series Count: {sanFundsLink(this.props.data.san, this.props.data.series)}<br />
									</div>
									<div className="col">
										Class Count: {sanClassesLink(this.props.data.san, this.props.data.classes)}<br />
									</div>
									<div className="col">
										File Size: {this.props.data.fileSize ? this.props.data.fileSize.toLocaleString() : ""}
									</div>
								</div>

								<div className="form-group border-top">
									<label htmlFor="funds">Funds</label>
									<TextareaAutosize
										//cacheMeasurements
										minRows={1}
										maxRows={9}
										id="funds"
										name="funds"
										className="form-control"
										value={this.props.data.funds}
										onChange={(e: any) => {
											this.props.data.funds = e.target.value;
											setFieldValue("funds", e.target.value?.normalize("NFKD"));
										}}
									//readOnly={true}
									/>
								</div>

								<div className="row">
									<div className="col">
										<div className="form-group">
											<Field type="hidden" id="recordDateVal" name="recordDateVal"></Field>
											<Field type="hidden" id="recordDateValPrefix" name="recordDateValPrefix"></Field>
											<Field type="hidden" id="recordDateValSuffix" name="recordDateValSuffix"></Field>
											<label htmlFor="recordDate">{this.renderAuditAnchor('Record Date', 'recordDate', data)}</label>
											<DatePicker
												selected={values.recordDate ? new Date(values.recordDate) : null}
												className="form-control"
												name="recordDate"
												isClearable
												autoComplete="off"
												showMonthDropdown
												showYearDropdown
												dropdownMode="select"
												minDate={new Date("2018-01-01T12:00")}
												maxDate={addYears(new Date(), 1)}
												placeholderText="Enter Record Date"
												openToDate={new Date(values.recordDate ? values.recordDate : values.dateFiled)}
												onChange={val => {
													if (!val) this.removeOffsetTag("recordDate");
													setFieldValue("recordDate", val);
												}}
											/>
										</div>
									</div>
									<div className="col">
										<div className="form-group">
											<Field type="hidden" id="meetingDateVal" name="meetingDateVal"></Field>
											<Field type="hidden" id="meetingDateValPrefix" name="meetingDateValPrefix"></Field>
											<Field type="hidden" id="meetingDateValSuffix" name="meetingDateValSuffix"></Field>
											<label htmlFor="meetingDate">{this.renderAuditAnchor('Meeting Date', 'meetingDate', data)}</label>
											<DatePicker
												selected={values.meetingDate ? new Date(values.meetingDate) : null}
												className="form-control"
												name="meetingDate"
												isClearable
												autoComplete="off"
												showMonthDropdown
												showYearDropdown
												dropdownMode="select"
												//yearDropdownItemNumber={15}
												minDate={new Date("2018-01-01T12:00")}
												maxDate={addYears(new Date(), 1)}
												placeholderText="Enter Meeting Date"
												openToDate={new Date(values.meetingDate ? values.meetingDate : values.dateFiled)}
												onChange={val => {
													if (!val) this.removeOffsetTag("meetingDate");
													setFieldValue("meetingDate", val);
												}}
											/>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col">
										<div className="form-group">
											<small className="text-muted float-right">{`${this.fundCounselLength}/100`}</small>
											<Field type="hidden" id="fundCounselVal" name="fundCounselVal"></Field>
											<Field type="hidden" id="fundCounselValPrefix" name="fundCounselValPrefix"></Field>
											<Field type="hidden" id="fundCounselValSuffix" name="fundCounselValSuffix"></Field>
											<label htmlFor="fundCounsel" title="Fund Counsel">{this.renderAuditAnchor('Fund Counsel', 'fundCounsel', data)}</label>
											<Field
												id="fundCounsel"
												name="fundCounsel"
												className="form-control"
												maxLength="100"
												placeholder="Enter Fund Counsel Name"
												onChange={(e: any) => {
													if (!e.target.value) this.removeOffsetTag("fundCounsel");
													this.fundCounselLength = e.target.value.length;
													setFieldValue("fundCounsel", e.target.value?.normalize("NFKD"));
												}}
											/>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col">
										<div className="form-group">
											<Field type="hidden" id="meetingTypeVal" name="meetingTypeVal"></Field>
											<Field type="hidden" id="meetingTypeValPrefix" name="meetingTypeValPrefix"></Field>
											<Field type="hidden" id="meetingTypeValSuffix" name="meetingTypeValSuffix"></Field>
											<label htmlFor="meetingType">{this.renderAuditAnchor('Meeting Type', 'meetingType', data)}</label>
											<Field as="select" name="meetingType" defaultValue={this.props.data.meetingType} className="form-control"
												onChange={(e: any) => {
													if (!e.target.value) this.removeOffsetTag("meetingType");
													setFieldValue("meetingType", e.target.value?.normalize("NFKD"));
												}}>
												<option value="">Select ...</option>
												<option value="Annual">Annual</option>
												<option value="Special">Special</option>
											</Field>
										</div>
									</div>
									<div className="col">
										<div className="form-group">
											<small className="text-muted float-right">{`${this.solicitorLength}/100`}</small>
											<Field type="hidden" id="solicitorVal" name="solicitorVal"></Field>
											<Field type="hidden" id="solicitorValPrefix" name="solicitorValPrefix"></Field>
											<Field type="hidden" id="solicitorValSuffix" name="solicitorValSuffix"></Field>
											<label htmlFor="solicitor" title="Proxy Solicitor">{this.renderAuditAnchor('Solicitor', 'solicitor', data)}</label>
											<Field
												id="solicitor"
												name="solicitor"
												className="form-control"
												maxLength="100"
												placeholder="Enter Solicitor Name"
												onChange={(e: any) => {
													if (!e.target.value) this.removeOffsetTag("solicitor");
													this.solicitorLength = e.target.value.length;
													setFieldValue("solicitor", e.target.value?.normalize("NFKD"));
												}}
											/>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col">
										<div className="form-group">
											<small className="text-muted float-right">{`${this.costLength}/100`}</small>
											<Field type="hidden" id="costVal" name="costVal"></Field>
											<Field type="hidden" id="costValPrefix" name="costValPrefix"></Field>
											<Field type="hidden" id="costValSuffix" name="costValSuffix"></Field>
											<label htmlFor="cost" title="Proxy Solicitation Cost">{this.renderAuditAnchor('Cost', 'cost', data)}</label>
											<Field
												id="cost"
												name="cost"
												className="form-control"
												maxLength="100"
												placeholder="Enter Solicitation Cost"
												onChange={(e: any) => {
													if(!e.target.value) this.removeOffsetTag("cost");
													this.costLength = e.target.value.length;
													this.tryExtractCostValue(e.target.value);
													setFieldValue("cost", e.target.value?.normalize("NFKD"));
												}}
											/>
										</div>
										<div className="form-group">
											<Link to={'#'} className='float-right' onClick={(e: any) => this.editCostValue(e)} title="Edit Cost Value"><FaEdit /></Link>
											<label className="text-muted float-right pr-1" title="Proxy Solicitation Cost Value">{formatMoney(`${this.props.data.costValue ? this.props.data.costValue: ''}`)}</label>
											<label title="Proxy Solicitation Cost Value">Cost Value</label>
										</div>
									</div>
									<div className="col">
										<div className="form-group">
											<small className="text-muted float-right">{`${this.costPaidByLength}/100`}</small>
											<Field type="hidden" id="costPaidByVal" name="costPaidByVal"></Field>
											<Field type="hidden" id="costPaidByValPrefix" name="costPaidByValPrefix"></Field>
											<Field type="hidden" id="costPaidByValSuffix" name="costPaidByValSuffix"></Field>
											<label htmlFor="costPaidBy" title="Proxy Solicitation Cost Paid By (Payee)">{this.renderAuditAnchor('Payee', 'costPaidBy', data)}</label>
											<Field
												id="costPaidBy"
												name="costPaidBy"
												className="form-control"
												maxLength="100"
												placeholder="Enter Payee"
												onChange={(e: any) => {
													if (!e.target.value) this.removeOffsetTag("costPaidBy");
													this.costPaidByLength = e.target.value.length;
													setFieldValue("costPaidBy", e.target.value?.normalize("NFKD"));
												}}
											/>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col">
										<div className="form-group">
											<small className="text-muted float-right">{`${this.descriptionLength}/100`}</small>
											<Field type="hidden" id="descriptionVal" name="descriptionVal"></Field>
											<Field type="hidden" id="descriptionValPrefix" name="descriptionValPrefix"></Field>
											<Field type="hidden" id="descriptionValSuffix" name="descriptionValSuffix"></Field>
											<label htmlFor="description" title="Document Description">{this.renderAuditAnchor('Document Description', 'description', data)}</label>
											<Field
												id="description"
												name="description"
												className="form-control"
												maxLength="100"
												placeholder="Enter Document Description"
												onChange={(e: any) => {
													if (!e.target.value) this.removeOffsetTag("description");
													this.descriptionLength = e.target.value.length;
													setFieldValue("description", e.target.value?.normalize("NFKD"));
												}}
											/>
										</div>
									</div>
									<div className="col">
										<div className="form-group">
											<Field type="hidden" id="routineVal" name="routineVal"></Field>
											<Field type="hidden" id="routineValPrefix" name="routineValPrefix"></Field>
											<Field type="hidden" id="routineValSuffix" name="routineValSuffix"></Field>
											<label htmlFor="routine">{this.renderAuditAnchor('Routine/Non-Routine', 'routine', data)}</label>
											<Field as="select" name="routine" defaultValue={this.props.data.routine} className="form-control"
												onChange={(e: any) => {
													if (!e.target.value) this.removeOffsetTag("routine");
													setFieldValue("routine", e.target.value);
												}}>
												<option value="">Select ...</option>
												<option value="R">Routine</option>
												<option value="N">Non-Routine</option>
											</Field>
										</div>
									</div>
								</div>

								<div className="form-group border-top">
									<Field type="hidden" id="proposal1Val" name="proposal1Val"></Field>
									<Field type="hidden" id="proposal1ValPrefix" name="proposal1ValPrefix"></Field>
									<Field type="hidden" id="proposal1ValSuffix" name="proposal1ValSuffix"></Field>
									<small className="text-muted float-right">{`${this.proposal1Length}/1,024`}</small>
									<label htmlFor="proposal1">{this.renderAuditAnchor('Proposal 1', 'proposal1', data)}</label>
									<TextareaAutosize
										//cacheMeasurements
										minRows={1}
										maxRows={19}
										id="proposal1"
										name="proposal1"
										className="form-control"
										placeholder="Enter Proposal 1"
										maxLength={1024}
										value={this.props.data.proposal1}
										onChange={(e: any) => {
											if (!e.target.value) this.removeOffsetTag("proposal1");
											this.proposal1Length = e.target.value.length;
											this.props.data.proposal1 = e.target.value;
											setFieldValue("proposal1", e.target.value?.normalize("NFKD"));
										}}
										onBlur={(e: any) => {
											this.proposal1Length = e.target.value.length;
											setFieldValue("proposal1", e.target.value?.normalize("NFKD"));
										}}
									/>
								</div>

								<div className="form-group">
									<small className="text-muted float-right">{`${this.proposal2Length}/1,024`}</small>
									<Field type="hidden" id="proposal2Val" name="proposal2Val"></Field>
									<Field type="hidden" id="proposal2ValPrefix" name="proposal2ValPrefix"></Field>
									<Field type="hidden" id="proposal2ValSuffix" name="proposal2ValSuffix"></Field>
									<label htmlFor="proposal2">{this.renderAuditAnchor('Proposal 2', 'proposal2', data)}</label>
									<TextareaAutosize
										//cacheMeasurements
										minRows={1}
										maxRows={19}
										id="proposal2"
										name="proposal2"
										className="form-control"
										placeholder="Enter Proposal 2"
										maxLength={1024}
										value={this.props.data.proposal2}
										onChange={(e: any) => {
											if (!e.target.value) this.removeOffsetTag("proposal2");
											this.proposal2Length = e.target.value.length;
											this.props.data.proposal2 = e.target.value;
											setFieldValue("proposal2", e.target.value?.normalize("NFKD"));
										}}
										onBlur={(e: any) => {
											this.proposal2Length = e.target.value.length;
											setFieldValue("proposal2", e.target.value?.normalize("NFKD"));
										}}
									/>
								</div>

								<div className="form-group">
									<small className="text-muted float-right">{`${this.proposal3Length}/1,024`}</small>
									<Field type="hidden" id="proposal3Val" name="proposal3Val"></Field>
									<Field type="hidden" id="proposal3ValPrefix" name="proposal3ValPrefix"></Field>
									<Field type="hidden" id="proposal3ValSuffix" name="proposal3ValSuffix"></Field>
									<label htmlFor="proposal3">{this.renderAuditAnchor('Proposal 3', 'proposal3', data)}</label>
									<TextareaAutosize
										//cacheMeasurements
										minRows={1}
										maxRows={19}
										id="proposal3"
										name="proposal3"
										className="form-control"
										placeholder="Enter Proposal 3"
										maxLength={1024}
										value={this.props.data.proposal3}
										onChange={(e: any) => {
											if (!e.target.value) this.removeOffsetTag("proposal3");
											this.proposal3Length = e.target.value.length;
											this.props.data.proposal3 = e.target.value;
											setFieldValue("proposal3", e.target.value?.normalize("NFKD"));
										}}
										onBlur={(e: any) => {
											this.proposal3Length = e.target.value.length;
											setFieldValue("proposal3", e.target.value?.normalize("NFKD"));
										}}
									/>
								</div>

								<div className="form-group">
									<small className="text-muted float-right">{`${this.proposal4Length}/1,024`}</small>
									<Field type="hidden" id="proposal4Val" name="proposal4Val"></Field>
									<Field type="hidden" id="proposal4ValPrefix" name="proposal4ValPrefix"></Field>
									<Field type="hidden" id="proposal4ValSuffix" name="proposal4ValSuffix"></Field>
									<label htmlFor="proposal4">{this.renderAuditAnchor('Proposal 4', 'proposal4', data)}</label>
									<TextareaAutosize
										//cacheMeasurements
										minRows={1}
										maxRows={19}
										id="proposal4"
										name="proposal4"
										className="form-control"
										placeholder="Enter Proposal 4"
										maxLength={1024}
										value={this.props.data.proposal4}
										onChange={(e: any) => {
											if (!e.target.value) this.removeOffsetTag("proposal4");
											this.proposal4Length = e.target.value.length;
											this.props.data.proposal4 = e.target.value;
											setFieldValue("proposal4", e.target.value?.normalize("NFKD"));
										}}
										onBlur={(e: any) => {
											this.proposal4Length = e.target.value.length;
											setFieldValue("proposal4", e.target.value?.normalize("NFKD"));
										}}
									/>
								</div>

								<div className="form-group">
									<small className="text-muted float-right">{`${this.proposal5Length}/1,024`}</small>
									<Field type="hidden" id="proposal5Val" name="proposal5Val"></Field>
									<Field type="hidden" id="proposal5ValPrefix" name="proposal5ValPrefix"></Field>
									<Field type="hidden" id="proposal5ValSuffix" name="proposal5ValSuffix"></Field>
									<label htmlFor="proposal5">{this.renderAuditAnchor('Proposal 5', 'proposal5', data)}</label>
									<TextareaAutosize
										//cacheMeasurements
										minRows={1}
										maxRows={19}
										id="proposal5"
										name="proposal5"
										className="form-control"
										placeholder="Enter Proposal 5"
										maxLength={1024}
										value={this.props.data.proposal5}
										onChange={(e: any) => {
											if (!e.target.value) this.removeOffsetTag("proposal5");
											this.proposal5Length = e.target.value.length;
											this.props.data.proposal5 = e.target.value;
											setFieldValue("proposal5", e.target.value?.normalize("NFKD"));
										}}
										onBlur={(e: any) => {
											this.proposal5Length = e.target.value.length;
											setFieldValue("proposal5", e.target.value?.normalize("NFKD"));
										}}
									/>
								</div>

								<div className="form-group">
									<small className="text-muted float-right">{`${this.proposal6Length}/1,024`}</small>
									<Field type="hidden" id="proposal6Val" name="proposal6Val"></Field>
									<Field type="hidden" id="proposal6ValPrefix" name="proposal6ValPrefix"></Field>
									<Field type="hidden" id="proposal6ValSuffix" name="proposal6ValSuffix"></Field>
									<label htmlFor="proposal6">{this.renderAuditAnchor('Proposal 6', 'proposal6', data)}</label>
									<TextareaAutosize
										//cacheMeasurements
										minRows={1}
										maxRows={19}
										id="proposal6"
										name="proposal6"
										className="form-control"
										placeholder="Enter Proposal 6"
										maxLength={1024}
										value={this.props.data.proposal6}
										onChange={(e: any) => {
											if (!e.target.value) this.removeOffsetTag("proposal6");
											this.proposal6Length = e.target.value.length;
											this.props.data.proposal6 = e.target.value;
											setFieldValue("proposal6", e.target.value?.normalize("NFKD"));
										}}
										onBlur={(e: any) => {
											this.proposal6Length = e.target.value.length;
											setFieldValue("proposal6", e.target.value?.normalize("NFKD"));
										}}
									/>
								</div>

								<div className="form-group">
									<small className="text-muted float-right">{`${this.proposal7Length}/1,024`}</small>
									<Field type="hidden" id="proposal7Val" name="proposal7Val"></Field>
									<Field type="hidden" id="proposal7ValPrefix" name="proposal7ValPrefix"></Field>
									<Field type="hidden" id="proposal7ValSuffix" name="proposal7ValSuffix"></Field>
									<label htmlFor="proposal7">{this.renderAuditAnchor('Proposal 7', 'proposal7', data)}</label>
									<TextareaAutosize
										//cacheMeasurements
										minRows={1}
										maxRows={19}
										id="proposal7"
										name="proposal7"
										className="form-control"
										placeholder="Enter Proposal 7"
										maxLength={1024}
										value={this.props.data.proposal7}
										onChange={(e: any) => {
											if (!e.target.value) this.removeOffsetTag("proposal7");
											this.proposal7Length = e.target.value.length;
											this.props.data.proposal7 = e.target.value;
											setFieldValue("proposal7", e.target.value?.normalize("NFKD"));
										}}
										onBlur={(e: any) => {
											this.proposal7Length = e.target.value.length;
											setFieldValue("proposal7", e.target.value?.normalize("NFKD"));
										}}
									/>
								</div>

								<div className="row">
									<div className="col">
										<div className="form-group">
											<small className="text-muted float-right">{`${this.noteLength}/200`}</small>
											<Field type="hidden" id="noteVal" name="noteVal"></Field>
											<Field type="hidden" id="noteValPrefix" name="noteValPrefix"></Field>
											<Field type="hidden" id="noteValSuffix" name="noteValSuffix"></Field>
											<label htmlFor="note" title="Fund Counsel">{this.renderAuditAnchor('Note', 'note', data)}</label>
											<TextareaAutosize
												//cacheMeasurements
												minRows={1}
												maxRows={19}
												id="note"
												name="note"
												className="form-control"
												placeholder="Enter Notes"
												maxLength={200}
												value={this.props.data.note}
												onChange={(e: any) => {
													if (!e.target.value) this.removeOffsetTag("note");
													this.noteLength = e.target.value.length;
													this.props.data.note = e.target.value;
													setFieldValue("note", e.target.value?.normalize("NFKD"));
												}}
												onBlur={(e: any) => {
													this.noteLength = e.target.value.length;
													setFieldValue("note", e.target.value?.normalize("NFKD"));
												}}
											/>
										</div>
									</div>
								</div>
							</div>
							{submitFooter}
						</form>
					)
				}
				}
			</Formik>
		);
	};

	public setMeetingDate(date: any) {
		// Update Meeting Date
	}

	public filingUrl(fileName?: string) {
		if (!fileName) return "";
		return "https://www.sec.gov/Archives/" + fileName;
	}

	public indexUrl(san?: string, fileName?: string) {
		if (!san || !fileName) return "";
		return "https://www.sec.gov/Archives/" + fileName.replace("-", "").replace(".txt", "/") + san + "-index.htm";
	}

	//public fundsUrl(san?: string, count?: number) {
	//	if (!san || !count) return ("");
	//	return (<Link to={"/funds/san/" + san} onClick={(e) => e.stopPropagation()}>{count}</Link>);
	//}

	//public classesUrl(san?: string, count?: number) {
	//	if (!san || !count) return ("");
	//	return (<Link to={"/classes/san/" + san} onClick={(e) => e.stopPropagation()}>{count}</Link>);
	//}

	private dateToString(date?: Date | string) {
		if (!date) return "";
		return (new Date(date).getMonth() + 1) + "/" + (new Date(date).getDate() + 0) + "/" + new Date(date).getFullYear()
	}

	private ensureDataFetched() {
		const san = this.props.match.params.fund;
		this.props.requestProxyData(san);
	}

	//private renderPagination() {
	//  const prevStartDateIndex = (this.props.startDateIndex || 0) - 5;
	//  const nextStartDateIndex = (this.props.startDateIndex || 0) + 5;

	//  return (
	//    <div className="d-flex justify-content-between">
	//      <Link className='btn btn-outline-secondary btn-sm' to={`/proxies/${prevStartDateIndex}`}>Previous</Link>
	//      {this.props.isLoading && <span>Loading...</span>}
	//      <Link className='btn btn-outline-secondary btn-sm' to={`/proxies/${nextStartDateIndex}`}>Next</Link>
	//    </div>
	//  );
	//}
}

export default connect(
	(state: ApplicationState) => state.proxyFilingData, // Selects which state properties are merged into the component's props
	ProxyFilingStore.proxyFilingDataActionCreators // Selects which action creators are merged into the component's props
)(EditData as any); // eslint-disable-line @typescript-eslint/no-explicit-any
